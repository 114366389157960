import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  FunctionField,
  TextInput,
  Edit,
  NumberField,
  NumberInput,
  ShowButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import { ListActions } from "../templates/ListActions";
export const BrandsList = (props) => {
  return (
    <List
      debounce={1500}
      actions={<ListActions hasCreate="productbrand.list" />}
      pagination={<MyCustomPagination />}
      filters={<SearchFilter />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="productbrand.list" />
        }
      >
        <TextField source="name" label="resources.root.name" />
        <TextField source="description" label="resources.root.description" />
        <NumberField source="sort" label="resources.root.sort" />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const BrandsCreate = () => {
  return (
    <Create>
      <SimpleForm defaultValues={{ description: null, sort: 0 }}>
        <TextInput required source="name" label="resources.root.name" />
        <TextInput
          source="description"
          label="resources.root.description"
          options={{ multiLine: true }}
        />
        <NumberInput required source="sort" label="resources.root.sort" />
      </SimpleForm>
    </Create>
  );
};

export const BrandsEdit = () => (
  <Edit>
    <SimpleForm defaultValues={{ description: null, sort: 0 }}>
      <TextInput required source="name" label="resources.root.name" />
      <TextInput
        source="description"
        label="resources.root.description"
        options={{ multiLine: true }}
      />
      <NumberInput required source="sort" label="resources.root.sort" />
    </SimpleForm>
  </Edit>
);
