import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  useTranslate,
  downloadCSV,
  useNotify,
  useRedirect,
  ShowButton,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { create } from "jss";
import { jssPreset, makeStyles, StylesProvider } from "@mui/styles";
import rtl from "jss-rtl";
import { HTTP } from "../../axios";
import { url, handleRequestResponse } from "../../request";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MaterialTextField from "@mui/material/TextField";
import { Col, Form, Row } from "react-bootstrap";
import Datetime from "react-datetime";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import AsyncSelect from "react-select/async";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import CardActions from "@mui/material/CardActions";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { useParams } from "react-router-dom";
import Page from "../CustomPages/SellReceipt/Page";

export const ProductDamagesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let { id, mcTotalPrice, scTotalPrice, reason, damagedBy, note, date } =
        data;
      return { id, mcTotalPrice, scTotalPrice, reason, damagedBy, note, date };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "mcTotalPrice",
          "scTotalPrice",
          "reason",
          "damagedBy",
          "note",
          "date",
        ],
        rename: [
          translate("resources.root.id"),
          translate("resources.root.mcPrice") +
            "(" +
            translate("resources.root.usd") +
            ")",
          translate("resources.root.scPrice") +
            "(" +
            translate("resources.root.iqd") +
            ")",
          translate("resources.root.reason"),
          translate("resources.root.damagedBy"),
          translate("resources.root.note"),
          translate("resources.root.date"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "product.damages." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };
  return (
    <List
      debounce={1500}
      exporter={exporter}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="damageinvoice.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction
            {...props}
            hasPrintOrders={"DamageInvoice"}
            hasDelete="damageinvoice.list"
          />
        }
      >
        {currency === "default" && (
          <FunctionField
            sortBy={"mcTotalPrice"}
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? `$${record?.mcTotalPrice?.toLocaleString()}`
                : `${record?.scTotalPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcTotalPrice"}
            label="Price"
            render={(record) =>
              currency === "dollar"
                ? `$${record?.mcTotalPrice?.toLocaleString()}`
                : `${record?.scTotalPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField source="reason" label="resources.root.reason" />
        <TextField source="damagedBy" label="resources.root.damageBy" />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const ProductDamagesShow = (props) => {
  const translate = useTranslate();
  const { id } = useParams();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  const classes = useStyles();
  React.useEffect(() => {
    if (id !== 0) {
      HTTP.get(`${url}/DamageInvoices/${id}`).then((res) => {
        setOrders(res.data.sellOrders);
        setInvoice(res.data);
      });
    }
  }, [id]);
  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              order={invoice.damageOrders}
              title={translate("resources.root.damageInvoice")}
              human={invoice.customer}
              showPrice={false}
              showCustomer={false}
            />
            {/*<NewInvoice*/}
            {/*  ref={ref}*/}
            {/*  orders={orders}*/}
            {/*  invoice={invoice}*/}
            {/*  title={translate('resources.root.sellInvoice')}*/}
            {/*  human={invoice.customer}*/}
            {/*/>*/}
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <SimpleShowLayout>
              <NumberField
                source="invoiceNumber"
                label="resources.root.invoiceNumber"
              />
              <NumberField source="totalQte" label="resources.root.totalQty" />
              <FunctionField
                label="resources.root.price"
                render={(record) =>
                  record.byMain
                    ? `$${record.mcTotalPrice?.toLocaleString()}`
                    : `${record.scTotalPrice?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`
                }
              />
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
                showTime={true}
              />
              <TextField source="reason" label="resources.root.reason" />
              <TextField source="damagedBy" label="resources.root.damageBy" />
              <TextField source="note" label="resources.root.note" />
              <ArrayField source="damageOrders">
                <Datagrid>
                  <FunctionField
                    label="resources.root.product"
                    render={(record) => record.product.name}
                  />
                  <FunctionField
                    label="resources.root.barcode"
                    render={(record) => record.product.barcode}
                  />
                  <TextField source="qte" label="resources.root.quantity" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Show>
          <Button
            variant="contained"
            onClick={() => {
              // let sidebar = document.getElementsByClassName('MuiDrawer-root');
              // let appMenu = document.getElementsByClassName('MuiPaper-root');
              // let appBar = document.getElementsByClassName('theRoot');
              // sidebar[0].style.display = 'none';
              // appMenu[0].style.display = 'none';
              // appBar[0].style.marginTop = '-100px';
              // window.print();
              // sidebar[0].style.display = 'block';
              // appMenu[0].style.display = 'block';
              // appBar[0].style.marginTop = '40px';
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const ProductDamagesCreate = () => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectDamageType, setSelectedDamageType] = React.useState(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [orderSmallQtt, setOrderSmallQtt] = React.useState(0);
  const [mcPurchasePrice, setMcPurchasePrice] = React.useState(0);
  const [scPurchasePrice, setScPurchasePrice] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("DamageInvoiceAddOrders")
      ? JSON.parse(localStorage.getItem("DamageInvoiceAddOrders"))
      : []
  );
  const [damagedBy, setDamagedBy] = React.useState(null);
  const [reason, setReason] = React.useState("");
  const [productNote, setProductNote] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  const redirect = useRedirect();

  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    localStorage.setItem("DamageInvoiceAddOrders", JSON.stringify(orders));
  }, [orders]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=${
          inputValue.length * 10 > 30 ? 30 : inputValue.length * 10
        }&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  const loadDamageType = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(`${url}/ProductDamageTypes`);
      let newArray = [];
      response.data.map((damageType) => {
        newArray.push({
          value: damageType.id,
          label: damageType.title,
          damageType: damageType,
        });
      });
      callback(newArray);
    }
  };

  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <MaterialTextField
                label={translate("resources.root.invoiceNumber")}
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className={classes.invoiceNumberInput}
              />
              <Form.Group className={`m-4`}>
                <Datetime
                  initialValue={selectedDate}
                  defaultShow={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.qty")}: ${
                          selectedProduct.qteInStock
                        } + ${selectedProduct.smallMeasureQte}`}
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      />
                    )}
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.futureQty")}: 
                              ${
                                parseFloat(selectedProduct.qteInStock) -
                                parseFloat(orderQtt)
                              } + 
                              ${
                                parseFloat(selectedProduct.smallMeasureQte) -
                                parseFloat(orderSmallQtt)
                              }`}
                        className={`${classes.bottomLeftMargin} ${classes.successChips}`}
                      />
                    )}
                  </div>
                  <div className={classes.orderCols}>
                    <div style={{ width: "350px" }}>
                      <AsyncSelect
                        defaultOptions
                        menuPortalTarget={document.body}
                        loadOptions={loadOptions}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        onChange={(e) => {
                          setSelectedProduct(e.product);
                          setMcPurchasePrice(e.product.mcPurchasePrice);
                          setScPurchasePrice(e.product.scPurchasePrice);
                        }}
                      />
                    </div>
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.smallMeasure")}
                      disabled={!selectedProduct}
                      value={orderSmallQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderSmallQtt(e.target.value);
                      }}
                      onBlur={(e) => {
                        let currentValue = e.target.value;
                        if (currentValue >= selectedProduct.oneContains) {
                          setOrderSmallQtt(
                            currentValue % selectedProduct.oneContains
                          );
                          setOrderQtt(
                            parseInt(orderQtt) +
                              parseInt(
                                currentValue / selectedProduct.oneContains
                              )
                          );
                        } else {
                          setOrderSmallQtt(e.target.value);
                        }
                      }}
                      className={classes.orderNumberInput}
                    />
                    {selectedProduct && selectedProduct.purchaseByMain ? (
                      <MaterialTextField
                        type="number"
                        label={translate("resources.root.purchasePrice")}
                        disabled={!selectedProduct}
                        value={mcPurchasePrice}
                        onFocus={(event) => event.target.select()}
                        onChange={(e) => {
                          setMcPurchasePrice(e.target.value);
                        }}
                        className={classes.orderNumberInput}
                      />
                    ) : (
                      <MaterialTextField
                        type="number"
                        label={translate("resources.root.purchasePrice")}
                        disabled={!selectedProduct}
                        value={scPurchasePrice}
                        onFocus={(event) => event.target.select()}
                        onChange={(e) => {
                          setScPurchasePrice(e.target.value);
                        }}
                        className={classes.orderNumberInput}
                      />
                    )}
                    <Button
                      variant="contained"
                      className={classes.botton}
                      disabled={
                        (parseFloat(orderQtt) <= 0 &&
                          parseFloat(orderSmallQtt) <= 0) ||
                        !selectedProduct
                      }
                      onClick={() => {
                        (parseFloat(orderQtt) > 0 ||
                          parseFloat(orderSmallQtt) > 0) &&
                          setOrders((prev) => {
                            const found = prev.find(
                              (el) =>
                                el.productId === parseInt(selectedProduct.id)
                            );
                            if (found) {
                              const newOrder = prev.map((el) => {
                                return (
                                  el.productId === selectedProduct.id && {
                                    productId: selectedProduct.id,
                                    name: selectedProduct.name,
                                    barcode: selectedProduct.barcode,
                                    payByMain: selectedProduct.purchaseByMain,
                                    mcPurchasePrice: mcPurchasePrice,
                                    scPurchasePrice: scPurchasePrice,
                                    mcSmallMeasurePurchasePrice:
                                      selectedProduct.mcSmallMeasurePurchasePrice,
                                    scSmallMeasurePurchasePrice:
                                      selectedProduct.scSmallMeasurePurchasePrice,
                                    qte: parseFloat(orderQtt),
                                    smallMeasureQte: parseFloat(orderSmallQtt),
                                  }
                                );
                              });

                              return [
                                ...prev.filter(
                                  (el) => el.productId !== selectedProduct.id
                                ),
                                ...newOrder.filter((el) => el !== false),
                              ];
                            } else {
                              return [
                                ...prev,
                                {
                                  productId: selectedProduct.id,
                                  name: selectedProduct.name,
                                  barcode: selectedProduct.barcode,
                                  purchaseByMain:
                                    selectedProduct.purchaseByMain,
                                  mcPurchasePrice,
                                  scPurchasePrice,
                                  mcSmallMeasurePurchasePrice:
                                    selectedProduct.mcSmallMeasurePurchasePrice,
                                  scSmallMeasurePurchasePrice:
                                    selectedProduct.scSmallMeasurePurchasePrice,
                                  qte: parseFloat(orderQtt),
                                  smallMeasureQte: parseFloat(orderSmallQtt),
                                },
                              ];
                            }
                          });
                        setOrderQtt(0);
                        setOrderSmallQtt(0);
                        setOrderToEdit(-1);
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      style={{ marginLeft: 10 }}
                      disabled={true}
                    >
                      {translate("resources.root.noOfOrders")}: {orders.length}
                    </Button>
                  </div>

                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.smallMeasureQty")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.purchasePrice")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders &&
                            orders.map((order) => (
                              <TableRow key={order.barcode}>
                                <TableCell component="th" scope="row">
                                  {order.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.name}
                                </TableCell>
                                <TableCell align="right">{order.qte}</TableCell>
                                <TableCell align="right">
                                  {order.smallMeasureQte}
                                </TableCell>
                                <TableCell align="right">
                                  {order.purchaseByMain
                                    ? order.mcPurchasePrice
                                    : order.scPurchasePrice}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          setOrders((prev) => {
                                            return prev.filter(
                                              (el) =>
                                                el.productId !== order.productId
                                            );
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className={"m-2"}>
                    <Row>
                      <Col>
                        <AsyncSelect
                          defaultOptions
                          menuPortalTarget={document.body}
                          loadOptions={loadDamageType}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            setSelectedDamageType(e.value);
                          }}
                        />
                      </Col>
                      <Col>
                        <MaterialTextField
                          label={translate("resources.root.reason")}
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <MaterialTextField
                          label={translate("resources.root.damageBy")}
                          value={damagedBy}
                          onChange={(e) => setDamagedBy(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <MaterialTextField
                          label={translate("resources.root.note")}
                          value={productNote}
                          onChange={(e) => setProductNote(e.target.value)}
                          className={classes.noteInput}
                        />
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="secondary"
            variant="contained"
            onClick={() => {
              if (!selectDamageType) {
                notify(translate("resources.root.selectDamageType"), "error");
                return;
              }
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setIsLoading(true);
                  HTTP.post(`${url}/DamageInvoices`, {
                    voucherNumber: invoiceNumber,
                    date: selectedDate,
                    damageTypeId: selectDamageType,
                    reason,
                    damagedBy,
                    payByMain: true,
                    note: productNote,
                    damageOrders: orders,
                  })
                    .then(() => {
                      localStorage.removeItem("DamageInvoiceAddOrders");
                      redirect("/DamageInvoices");
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      });
                    });
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LoadingScreen />}
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  successChips: {
    backgroundColor: "#72ffa7",
    color: "#000",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
