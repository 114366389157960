import * as React from "react";
import {
  List,
  Datagrid,
  TextField as RATextField,
  Create,
  SimpleForm,
  TextInput,
  FunctionField,
  Edit,
  DateField,
  required,
  FormDataConsumer,
  DateTimeInput,
  ReferenceInput,
  NumberInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
  useNotify,
  Show,
  SimpleShowLayout,
  useTranslate,
  DeleteButton,
  ShowButton,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import Button from "@mui/material/Button";
import { url, handleRequestResponse } from "../../request";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { VoucherInvoice } from "../CustomPages/VoucherInvoice";
import { HTTP } from "../../axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { FormControlLabel, Switch } from "@mui/material";

export const CashierWithdrawsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();
  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="cashierwithdraw.create"
        />
      }
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="cashierwithdraw.delete" />
        }
      >
        <RATextField source="id" label="resources.root.id" />
        <RATextField
          source="userStoreBankAccountName"
          label="resources.root.name"
        />
        <RATextField source="title" />
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <RATextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        {currency === "default" && (
          <FunctionField
            label={translate("resources.root.price")}
            sortBy={"mcPrice"}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice && record.mcPrice?.toLocaleString()}`
                : `${
                    record.scPrice && record.scPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.price")}
            sortBy={"mcPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice && record.mcPrice?.toLocaleString()}`
                : `${
                    record.scPrice && record.scPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        <RATextField
          source="withdrawer"
          label={translate("resources.root.withdrawer")}
        />
        <DateField
          source="date"
          showTime
          label={translate("resources.root.date")}
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <RATextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <RATextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const CashierWithdrawsCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [byMain, setByMain] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [cashiers, setCahsiers] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const dollarPrice = JSON.parse(localStorage.getItem("dollarPrice") || 1450);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify(translate("resources.root.pleaseCheckYourSettings"), "warning");
      window.open("#/Settings/create", "_self");
    } else {
      notify(error.message, "error");
    }
  };

  React.useEffect(() => {
    HTTP.get(`${url}/UserStoreBankAccounts?_end=10000`).then((res) => {
      setCahsiers(res.data);
    });
  }, []);

  return (
    <Create
      transform={(data) => ({
        ...data,
        withdrawer: localStorage.getItem("name"),
        byMain,
        mcPrice: byMain ? parseFloat(separatedNumber) : 0,
        scPrice: byMain ? 0 : parseFloat(separatedNumber),
      })}
      {...props}
      onFailure={onFailure}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              label={translate("resources.root.cashiers")}
              source="casherId"
              choices={cashiers}
              optionText="firstName"
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setSelectedChoices(cashiers.find((find) => find.id === val));
              }}
            />
            <TextInput
              source="title"
              label={translate("resources.root.title")}
              validate={[required()]}
            />
            <TextField
              onChange={(e) => {
                setSeparatedNumber(e.target.value);
              }}
              value={separatedNumber}
              helperText={
                byMain
                  ? `${parseFloat(
                      separatedNumber
                    )?.toLocaleString()} ${translate("resources.root.usd")}`
                  : `${parseFloat(
                      separatedNumber
                    )?.toLocaleString()} ${translate("resources.root.iqd")}`
              }
              label={translate("resources.root.price")}
            />
            <FormControlLabel
              className="m-0 text-nowrap "
              control={
                <Switch
                  checked={byMain}
                  onChange={(e) => {
                    setByMain(e.target.checked);
                    setSeparatedNumber((prevState) => {
                      return byMain
                        ? prevState * dollarPrice
                        : prevState / dollarPrice;
                    });
                  }}
                  name="gilad"
                />
              }
              label={
                byMain
                  ? translate("resources.root.dollar")
                  : translate("resources.root.dinar")
              }
            />
            <DateTimeInput
              source="date"
              defaultValue={new Date()}
              label={translate("resources.root.date")}
            />
            <TextInput
              source="accountant"
              label={translate("resources.root.accountant")}
            />
            <TextInput
              source="withdrawer"
              label={translate("resources.root.withdrawer")}
            />
            <TextInput
              source="note"
              label={translate("resources.root.note")}
              options={{ multiLine: true }}
            />
          </div>
          <div className={classes.formCol}>
            {selectedChoices?.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {translate("resources.root.currentInBox")}: $
                    {selectedChoices.mcCurrentCash?.toLocaleString()}
                    <br />
                    {translate("resources.root.currentInBox")}:{" "}
                    {selectedChoices.scCurrentCash?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const CashierWithdrawsEdit = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify(translate("resources.root.pleaseCheckYourSettings"), "warning");
      window.open("#/Settings/create", "_self");
    } else {
      notify("ra.notification.http_error", "warning");
    }
  };

  React.useEffect(() => {
    setSeparatedNumber(0);
  }, [currency]);

  return (
    <Edit {...props} onFailure={onFailure} redirect="list">
      <SimpleForm>
        <TextInput
          source="title"
          label={translate("resources.root.title")}
          validate={[required()]}
        />
        <TextInput
          source="description"
          options={{ multiLine: true }}
          label={translate("resources.root.description")}
        />
        <DateTimeInput
          source="date"
          defaultValue={new Date()}
          label={translate("resources.root.date")}
        />
        <ReferenceInput
          label={translate("resources.root.expenseType")}
          source="expenseTypeId"
          reference="ExpenseTypes"
          validate={[required()]}
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <TextInput
          source="note"
          label={translate("resources.root.note")}
          options={{ multiLine: true }}
        />
        <NumberInput
          source="qte"
          label={translate("resources.root.quantity")}
        />
        <BooleanInput
          source="byMain"
          defaultValue={true}
          label={
            currency
              ? translate("resources.root.dollar")
              : translate("resources.root.dinar")
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            let totalPrice = formData.qte * parseFloat(separatedNumber);
            setTotal(isNaN(totalPrice) ? 0 : totalPrice);

            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `$${parseFloat(separatedNumber)?.toLocaleString()}`
                    : ""
                }
                label={translate("resources.root.price")}
              />
            ) : (
              <NumberInput
                source="scPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `${parseFloat(
                        separatedNumber
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`
                    : ""
                }
                label={translate("resources.root.price")}
              />
            );
          }}
        </FormDataConsumer>
        <FunctionField
          label={translate("resources.root.total")}
          render={(record) =>
            currency
              ? `$${total?.toLocaleString()}`
              : `${total?.toLocaleString()} ${translate("resources.root.iqd")}`
          }
        />
      </SimpleForm>
    </Edit>
  );
};

export const CashierWithdrawsShow = (props) => {
  const [cashierWithdraws, setCashierWithdraws] = React.useState({});
  const translate = useTranslate();
  React.useEffect(() => {
    HTTP.get(`${url}/CashierWithdraws/${props.id}`).then((response) => {
      setCashierWithdraws(response.data);
    });
  }, [props]);
  return (
    <div>
      <VoucherInvoice
        invoiceNumber={
          cashierWithdraws.invoiceNumber && cashierWithdraws.invoiceNumber
        }
        name={cashierWithdraws.title && `${cashierWithdraws.title}`}
        priceMc={cashierWithdraws.mcTotalPrice && cashierWithdraws.mcTotalPrice}
        priceSc={cashierWithdraws.scTotalPrice && cashierWithdraws.scTotalPrice}
        payedTo={cashierWithdraws.title && `${cashierWithdraws.title}`}
        byMain={cashierWithdraws.byMain && cashierWithdraws.byMain}
        received={false}
        date={cashierWithdraws.date && cashierWithdraws.date}
        note={
          cashierWithdraws.qte &&
          (
            cashierWithdraws.qte *
            (cashierWithdraws.myMain
              ? cashierWithdraws.mcPrice
              : cashierWithdraws.scPrice)
          )?.toLocaleString() +
            (cashierWithdraws.description
              ? " - " + cashierWithdraws.description
              : "")
        }
      />
      {/*<Page*/}
      {/*    data={{*/}
      {/*        invoiceNo: (cashierWithdraws.invoiceNumber && cashierWithdraws.invoiceNumber),*/}
      {/*        serialNo: (cashierWithdraws.id && cashierWithdraws.id),*/}
      {/*        dollar: (cashierWithdraws.byMain && cashierWithdraws.byMain),*/}
      {/*        isDeposit: false,*/}
      {/*        date: (cashierWithdraws.date && cashierWithdraws.date),*/}
      {/*        payedTo: (cashierWithdraws.title && cashierWithdraws.title),*/}
      {/*        amount: (*/}
      {/*            (cashierWithdraws.byMain && cashierWithdraws.byMain)*/}
      {/*                ? (cashierWithdraws.mcTotalPrice && cashierWithdraws.mcTotalPrice?.toLocaleString())*/}
      {/*                : (cashierWithdraws.scTotalPrice && cashierWithdraws.scTotalPrice?.toLocaleString())*/}
      {/*        ),*/}
      {/*        type: "Expense",*/}
      {/*        note: (cashierWithdraws.qte && (cashierWithdraws.qte * (cashierWithdraws.byMain ? cashierWithdraws.mcPrice : cashierWithdraws.scPrice))?.toLocaleString() + (cashierWithdraws.description ? ' - ' + cashierWithdraws.description : ''))*/}
      {/*    }}*/}
      {/*/>*/}
      <Show>
        <SimpleShowLayout>
          <RATextField
            source="title"
            label={translate("resources.root.title")}
          />
          <RATextField
            source="description"
            label={translate("resources.root.description")}
          />
          <DateField
            source="date"
            showTime
            label={translate("resources.root.date")}
            locales={"en-GB"}
          />
          <RATextField
            source="cashierWithdrawsType.label"
            label={translate("resources.root.withdrawType")}
          />
          <RATextField source="note" label={translate("resources.root.note")} />
          <RATextField
            source="qte"
            label={translate("resources.root.quantity")}
          />
          <FunctionField
            label={translate("resources.root.price")}
            render={(record) =>
              record.byMain
                ? record.mcPrice?.toLocaleString()
                : record.scPrice?.toLocaleString()
            }
          />
          <FunctionField
            label={translate("resources.root.totalPrice")}
            render={(record) =>
              record.byMain
                ? record.mcTotalPrice?.toLocaleString()
                : record.scTotalPrice?.toLocaleString()
            }
          />
          <button
            className={`btn btn-primary`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("ifmcontentstoprint");
              printInvoice.classList.remove("d-none");
              printInvoice.classList.add("d-flex");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.remove("d-flex");
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
