import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  BooleanInput,
  AutocompleteInput,
  useTranslate,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeeSalaryPaysList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="hr.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="hr.list" />}
      >
        <FunctionField
          label="resources.root.name"
          render={(record) =>
            record.employee &&
            `${
              record.employee.firstName +
              " " +
              record.employee.middleName +
              " " +
              record.employee.lastName
            }`
          }
        />
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label="resources.root.salary"
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice}`
                : `${record.scPrice} ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label="resources.root.reward"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice}`
                : `${record.scPrice} ${translate("resources.root.iqd")}`
            }
          />
        )}
        <TextField source="accountant" label="resources.root.accountant" />
        <TextField source="tookBy" label="resources.root.tookBy" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
      </Datagrid>
    </List>
  );
};

export const EmployeeSalaryPaysCreate = (props) => {
  const translate = useTranslate();
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [employeeDetails, setEmployeeDetails] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [reward, setReward] = React.useState(0);
  const [punsihment, setPunsihment] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=10000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (selectedChoices?.id === undefined) return;
    HTTP.get(`${url}/Employees/${selectedChoices?.id}`).then((res) => {
      setEmployeeDetails(res.data);
      setLoading(false);
    });
  }, [selectedChoices]);

  React.useEffect(() => {
    setSeparatedNumber(
      selectedChoices.salaryByMain
        ? selectedChoices.mcBasicSalary +
            employeeDetails.employeeRewards
              .filter((el) => !el.cashPaid)
              .map((el) => el.mcPrice)
              .reduce((a, b) => a + b, 0) -
            employeeDetails.employeePunishments
              .map((el) => el.mcPrice)
              .reduce((a, b) => a + b, 0) -
            employeeDetails.employeeAllows
              .map((el) => el.mcSubtractedSalary)
              .reduce((a, b) => a + b, 0) -
            employeeDetails.employeeAbsents
              .map((el) => el.mcSubtractedSalary)
              .reduce((a, b) => a + b, 0)
        : employeeDetails.employeeRewards &&
            selectedChoices.scBasicSalary +
              employeeDetails.employeeRewards
                .filter((el) => !el.cashPaid)
                .map((el) => el.scPrice)
                .reduce((a, b) => a + b, 0) -
              employeeDetails.employeePunishments
                .map((el) => el.scPrice)
                .reduce((a, b) => a + b, 0) -
              employeeDetails.employeeAllows
                .map((el) => el.scSubtractedSalary)
                .reduce((a, b) => a + b, 0) -
              employeeDetails.employeeAbsents
                .map((el) => el.scSubtractedSalary)
                .reduce((a, b) => a + b, 0)
    );
  }, [employeeDetails]);
  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
        byMain: selectedChoices.salaryByMain,
      })}
    >
      {}
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              choices={choices}
              optionText={optionRenderer}
              onChange={(val) => {
                setSelectedChoices(choices.find((find) => find?.id === val));
                setSeparatedNumber(0);
                setLoading(true);
              }}
              label="resources.root.chooseEmployee"
            />
            {separatedNumber > 0 &&
              (selectedChoices && selectedChoices.salaryByMain ? (
                <NumberInput
                  validate={[required(), minValue(0)]}
                  source="mcPrice"
                  label="resources.root.price"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={separatedNumber}
                />
              ) : (
                <NumberInput
                  validate={[required(), minValue(0)]}
                  source="scPrice"
                  label="resources.root.price"
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={separatedNumber}
                />
              ))}

            {/* {selectedChoices && selectedChoices.salaryByMain ? (
              <NumberInput
                validate={[minValue(0)]}
                source="mcSummaryPunishment"
                label="resources.root.punishment"
                defaultValue={separatedNumber}
                onChange={(e) => {
                  setPunsihment(e.target.value);
                }}
              />
            ) : (
              <NumberInput
                validate={[minValue(0)]}
                source="scSummaryPunishment"
                label="resources.root.punishment"
                defaultValue={separatedNumber}
                onChange={(e) => {
                  setPunsihment(e.target.value);
                }}
              />
            )}
            {punsihment > 0 && (
              <TextInput
                source="punishmentNote"
                multiline
                label="resources.root.punishmentNote"
                s
              />
            )}
            {selectedChoices && selectedChoices.salaryByMain ? (
              <NumberInput
                validate={[minValue(0)]}
                source="mcSummaryReward"
                label="resources.root.rewards"
                defaultValue={separatedNumber}
                onChange={(e) => {
                  setReward(e.target.value);
                }}
              />
            ) : (
              <NumberInput
                validate={[minValue(0)]}
                source="scSummaryReward"
                label="resources.root.rewards"
                defaultValue={separatedNumber}
                onChange={(e) => {
                  setReward(e.target.value);
                }}
              />
            )}
            {reward > 0 && (
              <TextInput
                source="rewardNote"
                multiline
                label={`${translate("resources.root.rewardsNote")} 
              ${translate("resources.root.note")}`}
              />
            )} */}
            <DateInput
              source="date"
              label={translate("resources.root.date")}
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            {/* <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedChoices.id &&
                  (selectedChoices.salaryByMain ? (
                    <BooleanInput
                      source="byMain"
                      defaultValue={true}
                      label={translate("resources.root.dollar")}
                    />
                  ) : (
                    <BooleanInput
                      source="byMain"
                      defaultValue={false}
                      label={translate("resources.root.dollar")}
                    />
                  ))
                );
              }}
            </FormDataConsumer> */}
            <TextInput source="accountant" label="resources.root.accountant" />
            <TextInput source="tookBy" label="resources.root.tookBy" />
            <TextInput source="note" multiline label="resources.root.note" />
          </div>
          <div className={classes.formCol}>
            {selectedChoices?.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.basicSalary")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {selectedChoices.salaryByMain
                      ? `$${selectedChoices.mcBasicSalary?.toLocaleString()}`
                      : `${selectedChoices.scBasicSalary?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  {employeeDetails.employeeRewards && (
                    <Typography
                      style={{ color: "green" }}
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.rewards")}
                    </Typography>
                  )}
                  {employeeDetails.employeeRewards && (
                    <Typography
                      style={{ color: "green" }}
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedChoices.salaryByMain
                        ? `$${(
                            parseInt(reward) +
                            parseInt(
                              employeeDetails.employeeRewards
                                .filter((el) => !el.cashPaid)
                                .map((el) => el.mcPrice)
                                .reduce((a, b) => a + b, 0)
                            )
                          )?.toLocaleString()}`
                        : `${(
                            parseInt(reward) +
                            parseInt(
                              employeeDetails.employeeRewards
                                .filter((el) => !el.cashPaid)
                                .map((el) => el.scPrice)
                                .reduce((a, b) => a + b, 0)
                            )
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    style={{ color: "red" }}
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.absents")}
                  </Typography>
                  {employeeDetails.employeeAbsents && (
                    <Typography
                      style={{ color: "red" }}
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedChoices.salaryByMain
                        ? `$${employeeDetails.employeeAbsents
                            .map((el) => el.mcSubtractedSalary)
                            .reduce((a, b) => a + b, 0)
                            ?.toLocaleString()}`
                        : `${employeeDetails.employeeAbsents
                            .map((el) => el.scSubtractedSalary)
                            .reduce((a, b) => a + b, 0)
                            ?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    style={{ color: "red" }}
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.punishments")}
                  </Typography>
                  {employeeDetails.employeePunishments && (
                    <Typography
                      style={{ color: "red" }}
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedChoices.salaryByMain
                        ? `$${(
                            parseInt(punsihment) +
                            parseInt(
                              employeeDetails.employeePunishments
                                .map((el) => el.mcPrice)
                                .reduce((a, b) => a + b, 0)
                            )
                          )?.toLocaleString()}`
                        : `${(
                            parseInt(punsihment) +
                            parseInt(
                              employeeDetails.employeePunishments
                                .map((el) => el.scPrice)
                                .reduce((a, b) => a + b, 0)
                            )
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    style={{ color: "red" }}
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.permissions")}
                  </Typography>
                  {employeeDetails.employeeAllows && (
                    <Typography
                      style={{ color: "red" }}
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedChoices.salaryByMain
                        ? `$${employeeDetails.employeeAllows
                            .map((el) => el.mcSubtractedSalary)
                            .reduce((a, b) => a + b, 0)
                            ?.toLocaleString()}`
                        : `${employeeDetails.employeeAllows
                            .map((el) => el.scSubtractedSalary)
                            .reduce((a, b) => a + b, 0)
                            ?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <hr />
                  {employeeDetails && (
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.currentSalary")}
                    </Typography>
                  )}
                  {employeeDetails && (
                    <Typography
                      className={classes.marginBottom}
                      variant="h5"
                      component="h2"
                    >
                      {selectedChoices.salaryByMain
                        ? `$${
                            employeeDetails.employeeRewards &&
                            (
                              parseInt(reward) +
                              selectedChoices.mcBasicSalary +
                              employeeDetails.employeeRewards
                                .filter((el) => !el.cashPaid)
                                .map((el) => el.mcPrice)
                                .reduce((a, b) => a + b, 0) -
                              employeeDetails.employeePunishments
                                .map((el) => el.mcPrice)
                                .reduce((a, b) => a + b, 0) -
                              parseInt(punsihment) -
                              employeeDetails.employeeAllows
                                .map((el) => el.mcSubtractedSalary)
                                .reduce((a, b) => a + b, 0) -
                              employeeDetails.employeeAbsents
                                .map((el) => el.mcSubtractedSalary)
                                .reduce((a, b) => a + b, 0)
                            )?.toLocaleString()
                          }`
                        : `${
                            employeeDetails.employeeRewards &&
                            (
                              parseInt(reward) +
                              selectedChoices.scBasicSalary +
                              employeeDetails.employeeRewards
                                .filter((el) => !el.cashPaid)
                                .map((el) => el.scPrice)
                                .reduce((a, b) => a + b, 0) -
                              employeeDetails.employeePunishments
                                .map((el) => el.scPrice)
                                .reduce((a, b) => a + b, 0) -
                              parseInt(punsihment) -
                              employeeDetails.employeeAllows
                                .map((el) => el.scSubtractedSalary)
                                .reduce((a, b) => a + b, 0) -
                              employeeDetails.employeeAbsents
                                .map((el) => el.scSubtractedSalary)
                                .reduce((a, b) => a + b, 0)
                            )?.toLocaleString()
                          } IQD`}
                    </Typography>
                  )}
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedChoices?.id &&
                      translate("resources.root.employee")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedChoices.phone}
                    <br />
                    {selectedChoices.email}
                    <br />
                    {selectedChoices.address}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
