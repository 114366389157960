import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [representatives, setRepresentatives] = React.useState([]);
  const [representative, setRepresentative] = React.useState(0);
  const [representativeObject, setRepresentativeObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [openAccountQty, setOpenAccountQty] = React.useState(0);
  const [previousBalance, setPreviousBalance] = React.useState(0);
  const [initialBalance, setInitialBalance] = React.useState(0);
  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    await HTTP.get(
      `${url}/Reports/RepresentativeAccountStatement/${representative}?_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        let cus = representatives.find((x) => x.value === representative);
        if (cus) {
          setToTalItems(response.headers["x-total-count"]);
          setCurrentBalance(
            cus.representative.payLoanByMain
              ? response.headers.mccurrentbalance ?? 0
              : response.headers.sccurrentbalance ?? 0
          );
          setOpenAccountQty(
            cus.representative.payLoanByMain
              ? response.headers.mcopenaccountqty ?? 0
              : response.headers.scopenaccountqty ?? 0
          );
          setPreviousBalance(
            cus.representative.payLoanByMain
              ? response.headers.mcpreviousbalance ?? 0
              : response.headers.scpreviousbalance ?? 0
          );
          setInitialBalance(
            cus.representative.payLoanByMain
              ? response.headers.mcinitialbalance ?? 0
              : response.headers.scinitialbalance ?? 0
          );
        }
        if (response.data && response.data.length > 0) {
          let firstBalance =
            parseFloat(
              (representativeObject.payLoanByMain
                ? response.headers.mcinitialbalance
                : response.headers.scinitialbalance) ?? 0
            ) +
            parseFloat(
              (representativeObject.payLoanByMain
                ? response.headers.mcpreviousbalance
                : response.headers.scpreviousbalance) ?? 0
            );
          let newBalance = response.data[0].isWithdraw
            ? firstBalance +
              parseFloat(
                representativeObject.payLoanByMain
                  ? response.data[0].mcWithdraw
                  : response.data[0].scWithdraw
              )
            : firstBalance -
              parseFloat(
                representativeObject.payLoanByMain
                  ? response.data[0].mcDeposit
                  : response.data[0].scDeposit
              );
          response.data[0].balance = newBalance;
          for (let i = 1; i < response.data.length; i++) {
            let temp = response.data[i].isWithdraw
              ? newBalance +
                parseFloat(
                  representativeObject.payLoanByMain
                    ? response.data[i].mcWithdraw
                    : response.data[i].scWithdraw
                )
              : newBalance -
                parseFloat(
                  representativeObject.payLoanByMain
                    ? response.data[i].mcDeposit
                    : response.data[i].scDeposit
                );
            response.data[i].balance = temp;
            newBalance = temp;
          }
        }
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );
  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Representatives?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((representative) => {
        newArray.push({
          value: representative.id,
          label: `${representative.firstName} ${
            representative.middleName
          } (${representative.phone.replace("+964", "0")})`,
          representative: representative,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`representativeStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.representativeStatement")}
              {representative === 0
                ? ""
                : "(" +
                  representativeObject.firstName +
                  " " +
                  representativeObject.middleName +
                  ")"}
            </h1>
          </div>
          <NoPrint force>
            <Row
              style={{ fontSize: "15px" }}
              className="justify-content-center align-items-center"
            >
              <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.representative")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1"
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setRepresentative(e ? e.value : 0);
                    setRepresentativeObject(e ? e.representative : 0);
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-8  mt-4">
                <Row className="justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 ">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 ">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col className="col-4 col-lg-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={representative === 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 col-lg-4 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={representative === 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 col-lg-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={representative === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.dollarWithdraw")}</th>
                    <th>{translate("resources.root.dinarWithdraw")}</th>
                    <th>{translate("resources.root.dollarDeposit")}</th>
                    <th>{translate("resources.root.dinarDeposit")}</th>
                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#BFEAA3"
                              : "#FCE8E9",
                          }}
                        >
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          <td>{one.mcWithdraw?.toLocaleString()}</td>
                          <td>{one.scWithdraw?.toLocaleString()}</td>
                          <td>{one.mcDeposit?.toLocaleString()}</td>
                          <td>{one.scDeposit?.toLocaleString()}</td>
                          <td>
                            {one.balance?.toLocaleString()}{" "}
                            {representativeObject.payLoanByMain
                              ? translate("resources.root.usd")
                              : translate("resources.root.iqd")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
