import axios from "axios";
import { url } from "./request";

const token = localStorage.getItem("token") ?? null;

export const HTTP = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    storeid: localStorage.getItem("storeId") ?? 1,
    ratio: localStorage.getItem("dollarPrice"),
    Authorization: `Bearer ${token}`,
    language: localStorage.getItem("locale") === "kr" ? "Kurdish" : "English",
    platform: "Web",
  },
});

HTTP.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      delete HTTP.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      window.open("#/Login", "_self");
    }
    return Promise.reject(error);
  }
);
