import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  Show,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  minValue,
  TopToolbar,
  useRefresh,
  useTranslate,
  useNotify,
  useEditController,
  useGetList,
  BooleanField,
  ReferenceInput,
  TimeInput,
  useRecordContext,
  DateField,
  CheckboxGroupInput,
  SimpleShowLayout,
  ShowButton,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { path, url } from "../../request";
import Grid from "@mui/material/Grid";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Datetime from "react-datetime";
import Typography from "@mui/material/Typography";

export const DoctorsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="doctor.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="doctor.list" />}
      >
        <TextField source="id" label="resources.root.id" />

        <FunctionField
          label={`resources.root.image`}
          render={(record) => (
            <img
              style={{
                width: 75,
                height: 75,
                objectFit: "scale-down",
                cursor: "pointer",
                objectFit: "contain",
              }}
              src={
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`
              }
              alt={record.name}
              onClick={() =>
                window.open(
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`,
                  "_blank"
                )
              }
            />
          )}
        />

        <TextField source="departmentName" label="resources.root.department" />
        <FunctionField
          label="resources.root.name"
          sortBy={"name"}
          render={(record) => `${record.name}`}
        />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="profitRatio" label="resources.root.RVS" />
        {/* <TextField source="profitRatio2" label="resources.root.SVS1" /> */}
        {/* <TextField source="salaryType" label="resources.root.salaryType" /> */}

        {currency === "default" && (
          <FunctionField
            label="resources.root.visitPrice"
            sortBy={"mcVisitPrice"}
            render={(record) =>
              (record.mcVisitPrice || record.scVisitPrice) &&
              (record.payByMain
                ? `$${record.mcVisitPrice?.toLocaleString()}`
                : `${record.scVisitPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.visitPrice"
            sortBy={"mcVisitPrice"}
            render={(record) =>
              (record.mcVisitPrice || record.scVisitPrice) &&
              (currency === "dollar"
                ? `$${record.mcVisitPrice?.toLocaleString()}`
                : `${record.scVisitPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}

        {currency === "default" && (
          <FunctionField
            label="resources.root.loan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (record.payByMain
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.Loan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (currency === "dollar"
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DateFilter = () => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <Grid item className="col-8 col-lg-6 my-3">
      <Row>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="startDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.startDate")} :
              </Form.Label>
              <Form.Control
                className=""
                label="startDate"
                type="date"
                value={
                  localStorage.getItem("startDate") &&
                  localStorage.getItem("startDate")
                }
                onChange={(e) => {
                  localStorage.setItem("startDate", e.target.value);
                  refresh();
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="endDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.endDate")} :
              </Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  localStorage.setItem("endDate", e.target.value);
                  refresh();
                }}
                value={
                  localStorage.getItem("endDate") &&
                  localStorage.getItem("endDate")
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Grid>
    // <div className="d-flex align-items-center justify-content-start col-6 my-4">
    //   <input
    //     style={{
    //       marginRight: 5,
    //       padding: 6,
    //       borderRadius: 4,
    //       borderWidth: 1,
    //       borderStyle: "solid",
    //       borderColor: "gray",
    //     }}
    //     type="date"
    //     label="resources.root.startDate"
    //     source="startDate"
    //     alwaysOn
    //     onChange={(e) => {
    //       localStorage.setItem("startDate", e.target.value);
    //       refresh();
    //     }}
    //     value={
    //       localStorage.getItem("startDate") && localStorage.getItem("startDate")
    //     }
    //   />
    //   <input
    //     style={{
    //       marginRight: 5,
    //       padding: 6,
    //       borderRadius: 4,
    //       borderWidth: 1,
    //       borderStyle: "solid",
    //       borderColor: "gray",
    //     }}
    //     type="date"
    //     label="resources.root.endDate"
    //     source="endDate"
    //     alwaysOn
    //     onChange={(e) => {
    //       localStorage.setItem("endDate", e.target.value);
    //       refresh();
    //     }}
    //     value={
    //       localStorage.getItem("endDate") && localStorage.getItem("endDate")
    //     }
    //   />
    // </div>
  );
};

export const DoctorsShow = (props) => {
  const translate = useTranslate();
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  // const [salaryCurrency, setSalaryCurrency] = React  .useState(false);
  const [departmentId, setDepartmentId] = React.useState(0);
  const classes = useStyles();
  // const [data, setData] = React.useState([]);

  const { departments } = useGetList("departments");

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="resources.root.generalInfo">
          <SimpleShowLayout>
            <TextField source="name" label="resources.root.name" />
            <TextField source="phone" label="resources.root.phone" />
            <TextField
              source="departmentId"
              label="resources.root.department"
            />
            <TextField source="email" label="resources.root.email" />
            <BooleanField source="isMale" label="resources.root.gender" />
            <TextField source="degrees" label="resources.root.degrees" />
            <TextField source="specialist" label="resources.root.specialist" />
            <TextField source="experience" label="resources.root.experience" />
            <TextField source="bloodGroup" label="resources.root.bloodGroup" />
            <TextField
              source="profitRatio"
              label="resources.root.profitRatio"
            />
            {/* <TextField
              source="profitRatio2"
              label="resources.root.specialVisitsRatio"
            /> */}
            <DateField source="birthDate" label="resources.root.dob" />

            <FunctionField
              label="resources.root.salary"
              render={(record) =>
                `${record.payByMain ? record.mcBaseSalary : record.scBaseSalary}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />
            <FunctionField
              label="resources.root.visitPrice"
              render={(record) =>
                `${record.payByMain ? record.mcVisitPrice : record.scVisitPrice}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />

            <FunctionField
              label="resources.root.attachment"
              render={(record) => (
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              )}
            />

            <TextField source="note" label="resources.root.note" />
            <TextField source="about" label="resources.root.about" />

            <BooleanField source="saturday" label="resources.root.saturday" />

            <BooleanField source="sunday" label="resources.root.sunday" />

            <BooleanField source="monday" label="resources.root.monday" />

            <BooleanField source="tuesday" label="resources.root.tuesday" />

            <BooleanField source="wednesday" label="resources.root.wednesday" />

            <BooleanField source="thursday" label="resources.root.thursday" />

            <BooleanField source="friday" label="resources.root.friday" />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const DoctorsCreate = (props) => {
  const translate = useTranslate();
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(false);
  // const [salaryCurrency, setSalaryCurrency] = React  .useState(false);
  const [departmentId, setDepartmentId] = React.useState(0);
  const classes = useStyles();

  const { departments } = useGetList("departments");

  const [weekDays, setWeekDays] = React.useState({
    saturday: true,
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
  });

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        mcBaseSalary: data.salary,
        scBaseSalary: data.salary,
        mcVisitPrice: data.visitPrice,
        scVisitPrice: data.visitPrice,
        isAvailable: true,
      })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <ReferenceInput
              label={translate("resources.root.department")}
              source="departmentId"
              reference="departments"
              className={classes.input}
            >
              <SelectInput
                className={classes.input}
                optionText="name"
                validate={[required()]}
              />
            </ReferenceInput>

            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
            />

            <TextInput
              source="degrees"
              label="resources.root.degrees"
              className={classes.input}
            />
            <TextInput
              source="specialist"
              label="resources.root.specialist"
              className={classes.input}
            />
            <TextInput
              source="experience"
              label="resources.root.experience"
              className={classes.input}
            />
            {/* <AutocompleteInput
              source="departmentId"
              perPage={25}
              label="resources.root.department"
              choices={departments}
              validate={[required()]}
              optionText="department"
              optionValue="id"
            /> */}
            <SelectInput
              source="bloodGroup"
              label="resources.root.bloodGroup"
              validate={[required()]}
              className={classes.input}
              defaultValue={"AB+"}
              choices={[
                {
                  id: "AB+",
                  name: `${translate("resources.root.ABp")}`,
                },
                {
                  id: "AB-",
                  name: `${translate("resources.root.ABm")}`,
                },
                {
                  id: "B+",
                  name: `${translate("resources.root.Bp")}`,
                },
                {
                  id: "B-",
                  name: `${translate("resources.root.Bm")}`,
                },
                {
                  id: "A+",
                  name: `${translate("resources.root.Ap")}`,
                },
                {
                  id: "A-",
                  name: `${translate("resources.root.Am")}`,
                },
                {
                  id: "O+",
                  name: `${translate("resources.root.Op")}`,
                },
                {
                  id: "O-",
                  name: `${translate("resources.root.Om")}`,
                },
              ]}
            />

            <DateInput
              source="birthDate"
              label="resources.root.dob"
              defaultValue={moment().subtract(18, "years")}
              className={classes.input}
              validate={[required()]}
            />
            <BooleanInput
              source="isMale"
              label={gender ? "resources.root.male" : "resources.root.female"}
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />
          </div>
          <div className={classes.formCol}>
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              step={0.001}
              min={0}
              max={1}
              source="profitRatio"
              label="resources.root.profitRatio"
              className={classes.input}
            />
            {/* <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              max={1}
              source="profitRatio2"
              label="resources.root.specialVisitsRatio"
              className={classes.input}
            /> */}
            <BooleanInput
              source="payByMain"
              className={classes.input}
              label={
                currency
                  ? "resources.root.salaryUsd"
                  : "resources.root.salaryIqd"
              }
              defaultValue={false}
              onChange={(e) => setCurrency(e.target.checked)}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="salary"
              label={
                currency
                  ? "resources.root.mcBasicSalary"
                  : "resources.root.scBasicSalary"
              }
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />

            <NumberInput
              defaultValue={0}
              validate={[required(), minValue(0)]}
              min={0}
              source="visitPrice"
              label={
                currency
                  ? "resources.root.mcVisitPrice"
                  : "resources.root.scVisitPrice"
              }
              className={classes.input}
            />

            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                source="src"
                title="title"
              />
            </ImageInput>
            <Typography variant="h4" gutterBottom>
              {translate("resources.root.weekDayAvailability")}
            </Typography>
            <Row
              className={`justify-content-center align-items-center ${classes.input}`}
            >
              <Col xs="3">
                <BooleanInput
                  source="saturday"
                  label={"resources.root.saturday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, saturday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="sunday"
                  label={"resources.root.sunday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, sunday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="monday"
                  label={"resources.root.monday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, monday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="tuesday"
                  label={"resources.root.tuesday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, tuesday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="wednesday"
                  label={"resources.root.wednesday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, wednesday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="thursday"
                  label={"resources.root.thursday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, thursday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="friday"
                  label={"resources.root.friday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, friday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
            </Row>
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <TextInput
              source="about"
              label="resources.root.about"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const DoctorsEdit = (props) => {
  const translate = useTranslate();
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  // const [salaryCurrency, setSalaryCurrency] = React  .useState(false);
  const [departmentId, setDepartmentId] = React.useState(0);
  const classes = useStyles();
  // const [data, setData] = React.useState([]);

  const { departments } = useGetList("departments");

  const [weekDays, setWeekDays] = React.useState({
    saturday: true,
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
  });
  const { record } = useEditController();
  console.log(record);
  if (!record) return null;

  record.salary = record.payByMain ? record.mcBaseSalary : record.scBaseSalary;
  record.visitPrice = record.payByMain
    ? record.mcVisitPrice
    : record.scVisitPrice;
  return (
    <Edit
      {...props}
      transform={(data) => ({
        ...data,
        mcBaseSalary: data.salary,
        scBaseSalary: data.salary,
        mcVisitPrice: data.visitPrice,
        scVisitPrice: data.visitPrice,
      })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <ReferenceInput
              label={translate("resources.root.department")}
              source="departmentId"
              reference="departments"
              className={classes.input}
            >
              <SelectInput
                className={classes.input}
                optionText="name"
                validate={[required()]}
              />
            </ReferenceInput>
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
            />

            <TextInput
              source="degrees"
              label="resources.root.degrees"
              className={classes.input}
            />
            <TextInput
              source="specialist"
              label="resources.root.specialist"
              className={classes.input}
            />
            <TextInput
              source="experience"
              label="resources.root.experience"
              className={classes.input}
            />

            <SelectInput
              source="bloodGroup"
              label="resources.root.bloodGroup"
              validate={[required()]}
              className={classes.input}
              defaultValue={"AB+"}
              choices={[
                {
                  id: "AB+",
                  name: `${translate("resources.root.ABp")}`,
                },
                {
                  id: "AB-",
                  name: `${translate("resources.root.ABm")}`,
                },
                {
                  id: "B+",
                  name: `${translate("resources.root.Bp")}`,
                },
                {
                  id: "B-",
                  name: `${translate("resources.root.Bm")}`,
                },
                {
                  id: "A+",
                  name: `${translate("resources.root.Ap")}`,
                },
                {
                  id: "A-",
                  name: `${translate("resources.root.Am")}`,
                },
                {
                  id: "O+",
                  name: `${translate("resources.root.Op")}`,
                },
                {
                  id: "O-",
                  name: `${translate("resources.root.Om")}`,
                },
              ]}
            />

            <DateInput
              source="birthDate"
              label="resources.root.dob"
              defaultValue={moment().subtract(18, "years")}
              className={classes.input}
              validate={[required()]}
            />
            <BooleanInput
              source="isMale"
              label={gender ? "resources.root.male" : "resources.root.female"}
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />
            <BooleanInput
              source="isAvailable"
              label={"isAvailable"}
              className={classes.input}
              validate={[required()]}
            />
          </div>
          <div className={classes.formCol}>
            <NumberInput
              validate={[required()]}
              defaultValue={0}
              source="profitRatio"
              label="resources.root.profitRatio"
              className={classes.input}
            />
            {/* <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              max={1}
              source="commission2"
              label="resources.root.specialVisitsRatio"
              className={classes.input}
            /> */}

            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="salary"
              label={
                currency
                  ? "resources.root.mcBasicSalary"
                  : "resources.root.scBasicSalary"
              }
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />

            <NumberInput
              defaultValue={0}
              validate={[required(), minValue(0)]}
              min={0}
              source="visitPrice"
              label={
                currency
                  ? "resources.root.mcVisitPrice"
                  : "resources.root.scVisitPrice"
              }
              className={classes.input}
            />

            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                source="src"
                title="title"
              />
            </ImageInput>
            <Typography variant="h4" gutterBottom>
              {translate("resources.root.weekDayAvailability")}
            </Typography>
            <Row
              className={`justify-content-center align-items-center ${classes.input}`}
            >
              <Col xs="3">
                <BooleanInput
                  source="saturday"
                  label={"resources.root.saturday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, saturday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="sunday"
                  label={"resources.root.sunday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, sunday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="monday"
                  label={"resources.root.monday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, monday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="3">
                <BooleanInput
                  source="tuesday"
                  label={"resources.root.tuesday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, tuesday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="wednesday"
                  label={"resources.root.wednesday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, wednesday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="thursday"
                  label={"resources.root.thursday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, thursday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
              <Col xs="4">
                <BooleanInput
                  source="friday"
                  label={"resources.root.friday"}
                  defaultValue={true}
                  onChange={(e) =>
                    setWeekDays({ ...weekDays, friday: e.target.checked })
                  }
                  className={classes.input}
                />
              </Col>
            </Row>
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <TextInput
              source="about"
              label="resources.root.about"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
