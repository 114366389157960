import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import Select from "react-select";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";
import { toCsv } from "../CustomComponents/Helpers";

export default (EmployeeAccountStatement) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(0);
  const [employeeObject, setEmployeeObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;

    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    await HTTP.get(
      `${url}/Reports/EmployeeAccountStatement/${employee}?_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(function () {
    getEmployees("");
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  function getEmployees(value) {
    HTTP.get(`${url}/Employees?_end=10000&search=${value}`)
      .then((response) => {
        let newArray = [];
        response.data.map((employee) => {
          newArray.push({
            value: employee.id,
            label: `${employee.firstName} ${
              employee.middleName
            } (${employee.phone.replace("+964", "0")})`,
            employee: employee,
          });
        });
        setEmployees(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }

  return (
    <div>
      <PrintProvider>
        <Print single name={`employeeStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.employeeStatement")}
              {employee === 0
                ? ""
                : "(" +
                  employeeObject.firstName +
                  " " +
                  employeeObject.middleName +
                  ")"}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row
              style={{ fontSize: "15px" }}
              className="m-3 align-items-center"
            >
              <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.employees")} :
                </p>{" "}
                <Select
                  name="colors"
                  isClearable
                  isSearchable
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                  options={employees}
                  onChange={(e) => {
                    setEmployee(e ? e.value : 0);
                    setEmployeeObject(e ? e.employee : []);
                  }}
                />
              </Col>

              <Col className="col-12 col-lg-6 mt-4">
                <Row className="justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  disabled={employee === 0}
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  disabled={employee === 0}
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={employee === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`change`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.dollarWithdraw")}</th>
                    <th>{translate("resources.root.dinarWithdraw")}</th>
                    <th>{translate("resources.root.dollarDeposit")}</th>
                    <th>{translate("resources.root.dinarDeposit")}</th>
                    <th>{translate("resources.root.balance")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.note")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#BFEAA3"
                              : "#FCE8E9",
                          }}
                        >
                          <td>{one.invoiceNumber}</td>
                          <td>{one.qte}</td>
                          <td>{one.mcWithdraw?.toLocaleString()}</td>
                          <td>{one.scWithdraw?.toLocaleString()}</td>
                          <td>{one.mcDeposit?.toLocaleString()}</td>
                          <td>{one.scDeposit?.toLocaleString()}</td>
                          <td>
                            {one.balance?.toLocaleString()}{" "}
                            {employeeObject.loanByMain
                              ? translate("resources.root.usd")
                              : translate("resources.root.iqd")}
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          <td>{one.note}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}></div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
