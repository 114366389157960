import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  FunctionField,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import { ListActions } from "../templates/ListActions";
export const ProductDamageTypesList = (props) => (
  <div>
    <List
      debounce={1500}
      actions={<ListActions hasCreate="damagetype.list" />}
      pagination={<MyCustomPagination />}
      {...props}
      filters={<SearchFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="damagetype.list" />
        }
      >
        <TextField source="title" label="resources.root.title" />
        <TextField source="description" label="resources.root.description" />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  </div>
);

export const ProductDamageTypesCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput
          source="title"
          label="resources.root.title"
          className={classes.input}
        />
        <TextInput
          source="description"
          label="resources.root.description"
          options={{ multiLine: true }}
          className={classes.input}
        />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
          className={classes.input}
        />
      </SimpleForm>
    </Create>
  );
};

export const ProductDamageTypesEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="title"
          label="resources.root.title"
          className={classes.input}
        />
        <TextInput
          source="description"
          label="resources.root.description"
          options={{ multiLine: true }}
          className={classes.input}
        />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  input: {
    width: "30%",
  },
});
