import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import { path } from "../../request";

import AsyncSelect from "react-select/async/dist/react-select.esm";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplier, setSupplier] = React.useState(0);
  const [productCategories, setProductCategories] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).add(-1, "M").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [ignoreZero, setIgnoreZero] = React.useState(false);

  const [total, setTotal] = React.useState({
    mcTotalPrice: 0,
    scTotalPrice: 0,
    totalQty: 0,
    totalSmallQty: 0,
  });

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }
    let productCategory = ``;
    if (productCategories !== 0) {
      productCategory = `${productCategories}`;
    } else {
      productCategory = "";
    }

    let supplierSet = ``;
    if (supplier !== 0) {
      supplierSet = `&enableSubLevel=true&subLevelId=${supplier}`;
    } else {
      supplierSet = "";
    }

    let bellowZero = ``;
    if (ignoreZero) {
      bellowZero = `&ignoreZero=${ignoreZero}`;
    } else {
      bellowZero = "";
    }

    await HTTP.get(
      `${url}/Reports/NotSaleReport?_start=${start}&_end=${end}${sendDates}${productCategory}${supplierSet}${bellowZero}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotal({
          mcTotalPrice: parseFloat(response.headers["mctotalsellprice"]),
          scTotalPrice: parseFloat(response.headers["sctotalsellprice"]),
          totalQty: parseFloat(response.headers["totalqte"]),
          totalSmallQty: parseFloat(response.headers["totalsmallqte"]),
        });
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/ProductCategories?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((productCategory) => {
        newArray.push({
          value: productCategory.id,
          label: productCategory.name,
          productCategory: productCategory,
        });
      });
      callback(newArray);
    }
  };

  const loadSuppliers = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Suppliers?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((supplier) => {
        newArray.push({
          value: supplier.id,
          label: supplier.supplierName,
          supplier: supplier,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`customerStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.notSaleReport")}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }} className="m-2">
              <Col className="col-12 col-lg-3 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.products")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  className="w-100"
                  isMulti
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    let productCategories = "";
                    if (e.length !== 0) {
                      e.map((x) => (productCategories += "&id=" + x.value));
                      setProductCategories(productCategories);
                    } else {
                      setProductCategories(``);
                    }
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-3 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.supplier")} :
                </p>
                <AsyncSelect
                  isClearable
                  defaultOptions
                  className="w-100"
                  loadOptions={loadSuppliers}
                  onChange={(e) => setSupplier(e ? e.value : 0)}
                />
              </Col>

              <Col className="col-12 col-lg-6 mt-4">
                <Row>
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className={`col-12 col-lg-6 mt-4 row row-cols-2`}>
                <h6>
                  {translate("resources.root.totalPrice")}: $
                  {parseFloat(total.mcTotalPrice)?.toLocaleString()}
                </h6>
                <h6>
                  {translate("resources.root.totalPrice")}:{" "}
                  {parseFloat(total.scTotalPrice)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h6>
                <h6>
                  {translate("resources.root.totalQte")}:{" "}
                  {parseFloat(total.totalQty)?.toLocaleString()}
                </h6>
                <h6>
                  {translate("resources.root.totalSmallQte")}:{" "}
                  {parseFloat(total.totalSmallQty)?.toLocaleString()}
                </h6>
              </Col>
              <Col className="col-4 col-lg-2 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={data.length <= 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 col-lg-2 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={data.length <= 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 col-lg-2 mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.barcode")}</th>
                    <th>{translate("resources.root.name")}</th>
                    {/* <th>{translate("resources.root.brandName")}</th> */}
                    <th>{translate("resources.root.categoryName")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.qtyInStock")}</th>
                    <th>{translate("resources.root.smallMeasure")}</th>
                    <th>{translate("resources.root.image")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.barcode}
                            </a>
                          </td>
                          <td>{one.name}</td>
                          {/* <td>{one.brandName}</td> */}
                          <td>{one.productCategoryLabel}</td>
                          <td>
                            {process.env.REACT_APP_SINGLE_CURRENCY === "true"
                              ? process.env.REACT_APP_SHOW_MC === "true"
                                ? one.mcSellPrice?.toLocaleString()
                                : one.scSellPrice?.toLocaleString()
                              : localStorage.getItem("sellMainCurrency") ===
                                "true"
                              ? one.mcSellPrice?.toLocaleString()
                              : one.scSellPrice?.toLocaleString()}
                          </td>
                          <td>{one.qteInStock?.toLocaleString()}</td>
                          <td>{one.smallMeasureQte?.toLocaleString()}</td>{" "}
                          <td>
                            <img
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "scale-down",
                                cursor: "pointer",
                              }}
                              src={
                                one.attachment !== null
                                  ? `${path}${one.attachment}`
                                  : `https://via.placeholder.com/50`
                              }
                              alt={one.name}
                              onClick={() =>
                                window.open(
                                  one.attachment !== null
                                    ? `${path}${one.attachment}`
                                    : `https://via.placeholder.com/50`,
                                  "_blank"
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                      <option value={10000}>10000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
