import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export const StoreTransactionHistoriesList = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [balance, setBalance] = React.useState({
    mcTotalPrice: 0,
    scTotalPrice: 0,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    await HTTP.get(
      `${url}/StoreTransactionHistories?_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        const tempBalance = {
          mcTotalPrice: parseFloat(response.headers.mctotallost) ?? 0,
          scTotalPrice: parseFloat(response.headers.sctotallost) ?? 0,
        };
        setBalance(tempBalance);

        setData(response?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          redirect("/Login");
        }
        console.log(error?.response?.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print single name={``}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {/* {translate("resources.root.userStoreBankAccountHistory")} */}
              Bank Account History
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <div
              style={{ fontSize: "15px" }}
              className="row justify-content-center align-items-center m-2"
            >
              <Col className={`col-12 col-lg-7 mt-4`}>
                <div className="row justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3  mb-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </div>
              </Col>
              <Col className="col-12 col-lg-5 mt-4 ">
                <h6 className={`text-center d-flex align-items-center gap-3`}>
                  {translate("resources.root.totalPrice")}:{" "}
                  {process.env.REACT_APP_SHOW_MC === "true" && (
                    <span>$ {balance.mcTotalPrice?.toLocaleString()}</span>
                  )}
                  {process.env.REACT_APP_SHOW_MC === "true" && (
                    <span>{balance.scTotalPrice?.toLocaleString()} IQD</span>
                  )}
                </h6>
              </Col>{" "}
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={data.length === 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={data.length === 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    let sidBarStyle = sidebar[0].style.display;
                    let MenuStyle = appMenu[0].style.display;
                    let appBarMargin = appBar[0].style.marginTop;
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";

                    window.print();

                    sidebar[0].style.display = sidBarStyle;
                    appMenu[0].style.display = MenuStyle;
                    appBar[0].style.marginTop = appBarMargin;
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </div>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.amount")}</th>
                    <th>{translate("resources.root.withdraw")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.refNo")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.reason")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${one.lost ? "bg-red" : "bg-green"}`}
                        >
                          <td>{one.id}</td>
                          {one.byMain ? (
                            <td>$ {one.mcAmount.toLocaleString()}</td>
                          ) : (
                            <td>{one.scAmount.toLocaleString()} IQD</td>
                          )}
                          <td>
                            {one.lost
                              ? `${translate("resources.root.yes")}`
                              : `${translate("resources.root.no")}`}
                          </td>
                          <td>{one.description}</td>
                          <td>{one.referenceNumber}</td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.reason}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={parseInt(totalItems)}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
