import { BulkDeleteButton, useTranslate } from "react-admin";
import * as React from "react";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import { hasPermission } from "../../App";

const BulkAction = (props) => {
  const translate = useTranslate();
  return (
    <>
      {props.hasPrintOrders && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              let ids = "";
              for (let i = 0; i < props.selectedIds.length; i++) {
                if (i === 0) {
                  ids += "?ids[]=" + props.selectedIds[i];
                } else {
                  ids += "&ids[]=" + props.selectedIds[i];
                }
              }
              window
                .open(
                  `#/PrintInvoiceOrders/${props.hasPrintOrders}${ids}`,
                  "_blank"
                )
                .focus();
            }}
          >
            <PrintIcon /> &nbsp; {translate("resources.root.printOrders")}
          </Button>
        </div>
      )}
      {props.selectedIds.length === 1 &&
        hasPermission([`permissions.${props.hasDelete}`]) && (
          <BulkDeleteButton
            {...props}
            undoable={false}
            style={{ marginBottom: 15 }}
            confirmTitle={"Sure delete selected!!!"}
            confirmContent={`${props.selectedIds.length} Selected data`}
          />
        )}
    </>
  );
};

export default BulkAction;
