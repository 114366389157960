import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  FormDataConsumer,
  DateInput,
  FunctionField,
  BooleanField,
  BooleanInput,
  Show,
  DateField,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  useTranslate,
  useResourceDefinition,
  ShowButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { ListActions } from "../templates/ListActions";
import { path, url } from "../../request";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import moment from "moment";

export const DepositsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="deposit.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="deposit.list" />}
      >
        <TextField source="id" label={translate("resources.root.id")} />
        <TextField source="title" label={translate("resources.root.title")} />
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label={translate("resources.root.price")}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} IQD`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label={translate("resources.root.price")}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} IQD`
            }
          />
        )}
        <TextField
          source="accountant"
          label={translate("resources.root.accountant")}
        />
        <TextField
          source="depositer"
          label={translate("resources.root.depositor")}
        />
        <DateField
          source="date"
          label={translate("resources.root.date")}
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("YYYY-MM-DD HH:MM")
                : "-"
            }
          />
        )}{" "}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const DepositsShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [deposit, setDeposit] = React.useState({});
  React.useEffect(() => {
    HTTP.get(`${url}/Deposits/${id}`).then((response) => {
      setDeposit(response.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: deposit.invoiceNumber && deposit.invoiceNumber,
          serialNo: deposit.id && deposit.id,
          dollar: deposit.byMain && deposit.byMain,
          isDeposit: true,
          date: deposit.date && deposit.date,
          payedTo: deposit.accountant && deposit.accountant,
          amount:
            deposit.byMain && deposit.byMain
              ? deposit.mcPrice && deposit.mcPrice?.toLocaleString()
              : deposit.scPrice && deposit.scPrice?.toLocaleString(),
          type: "Deposit",
          note: deposit.note && deposit.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <FunctionField
            label={translate("resources.root.price")}
            render={(record) =>
              record.byMain
                ? record.mcPrice?.toLocaleString()
                : record.scPrice?.toLocaleString()
            }
          />
          <BooleanField
            source="byMain"
            label={translate("resources.root.byMain")}
          />
          <TextField
            source="accountant"
            label={translate("resources.root.accountant")}
          />
          <TextField
            source="depositer"
            label={translate(translate("resources.root.depositor"))}
          />
          <TextField source="note" label={translate("resources.root.note")} />
          <DateField
            source="date"
            label={translate("resources.root.date")}
            locales={"en-GB"}
          />
          <FunctionField
            label={translate("resources.root.attachment")}
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          <button
            className={`btn btn-primary btn-sm btn-block`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const DepositsCreate = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify("Please Check Your Settings", { type: "warning", undoable: true });
      window.open("#/Settings/create", "_self");
    } else {
      notify("ra.notification.http_error", { type: "warning", undoable: true });
    }
  };
  return (
    <Create
      onFailure={onFailure}
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
      redirect="list"
    >
      <SimpleForm>
        <TextInput source="title" label={translate("resources.root.title")} />
        <BooleanInput
          source="byMain"
          onChange={() => setSeparatedNumber(0)}
          defaultValue={true}
          label={
            currency
              ? translate("resources.root.dollar")
              : translate("resources.root.dinar")
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                label={translate("resources.root.price")}
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `$${separatedNumber?.toLocaleString()}`
                    : ""
                }
              />
            ) : (
              <NumberInput
                source="scPrice"
                label={translate("resources.root.price")}
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `${separatedNumber?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`
                    : ""
                }
              />
            );
          }}
        </FormDataConsumer>
        <ReferenceInput
          label={translate("resources.root.depositType")}
          source="depositeTypeId"
          reference="DepositTypes"
        >
          <SelectInput optionText="label" validate={[required()]} />
        </ReferenceInput>
        <TextInput
          source="depositer"
          label={translate("resources.root.depositor")}
        />
        <DateInput
          source="date"
          defaultValue={new Date()}
          label={translate("resources.root.date")}
        />
        <TextInput
          source="note"
          options={{ multiLine: true }}
          label={translate("resources.root.note")}
        />
        <ImageInput
          source="attachment"
          label={translate("resources.root.attachment")}
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
