import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  FormDataConsumer,
  DateInput,
  FunctionField,
  BooleanField,
  BooleanInput,
  Show,
  DateField,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageField,
  ImageInput,
  required,
  useTranslate,
  useResourceDefinition,
  ShowButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { ListActions } from "../templates/ListActions";
import { path, url } from "../../request";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import moment from "moment";

export const WithdrawsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="withdraw.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="withdraw.list" />}
      >
        <TextField source="id" label="resources.root.id" />
        <TextField source="title" label="resources.root.title" />
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.price"
            sortBy={"mcPrice"}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} 
                ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.price"
            sortBy={"mcPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} resources.root.title`
            }
          />
        )}
        <TextField source="accountant" label="resources.root.accountant" />
        <TextField source="withdrawer" label="resources.root.withdrawer" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const WithdrawsShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [withdraw, setWithdraw] = React.useState({});
  React.useEffect(() => {
    HTTP.get(`${url}/Withdraws/${id}`).then((response) => {
      setWithdraw(response.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: withdraw.invoiceNumber && withdraw.invoiceNumber,
          serialNo: withdraw.id && withdraw.id,
          dollar: withdraw.byMain && withdraw.byMain,
          isDeposit: false,
          date: withdraw.date && withdraw.date,
          payedTo: withdraw.accountant && withdraw.accountant,
          amount:
            withdraw.byMain && withdraw.byMain
              ? withdraw.mcPrice && withdraw.mcPrice?.toLocaleString()
              : withdraw.scPrice && withdraw.scPrice?.toLocaleString(),
          type: "Withdraw",
          note: withdraw.note && withdraw.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? record.mcPrice?.toLocaleString()
                : record.scPrice?.toLocaleString()
            }
          />
          <BooleanField source="byMain" label="resources.root.byMain" />
          <TextField source="accountant" label="resources.root.accountant" />
          <TextField source="withdrawer" label="resources.root.withdrawer" />
          <TextField source="note" label="resources.root.note" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <FunctionField
            label="resources.root.attachment"
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          <button
            className={`btn btn-primary btn-sm btn-block`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const WithdrawsCreate = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify(translate("resources.root.pleaseCheckYourSettings"), {
        type: "warning",
        undoable: true,
      });
      window.open("#/Settings/create", "_self");
    } else {
      notify("ra.notification.http_error", { type: "warning", undoable: true });
    }
  };
  return (
    <Create
      onFailure={onFailure}
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
      redirect="list"
    >
      <SimpleForm>
        <TextInput source="title" label="resources.root.title" />
        <BooleanInput
          source="byMain"
          onChange={() => setSeparatedNumber(0)}
          defaultValue={true}
          label={currency ? "resources.root.dollar" : "resources.root.dinar"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                label="resources.root.price"
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `$${separatedNumber?.toLocaleString()}`
                    : ""
                }
              />
            ) : (
              <NumberInput
                source="scPrice"
                label="resources.root.price"
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `${separatedNumber?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`
                    : ""
                }
              />
            );
          }}
        </FormDataConsumer>
        <ReferenceInput
          label="resources.root.withdrawType"
          source="withdrawTypeId"
          reference="WithdrawTypes"
        >
          <SelectInput optionText="label" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="withdrawer" label="resources.root.withdrawer" />
        <DateInput
          source="date"
          defaultValue={new Date()}
          label="resources.root.date"
        />
        <TextInput
          source="note"
          options={{ multiLine: true }}
          label="resources.root.note"
        />
        <ImageInput
          source="attachment"
          label="resources.root.attachment"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
