import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import lodash from "lodash";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import { path } from "../../request";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

function BrandItemReport() {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [brand, setBrand] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [showPurchaseQty, setShowPurchaseQty] = React.useState(true);
  const [showSmallMeasurePurchaseQty, setShowSmallMeasurePurchaseQty] =
    React.useState(true);
  const [showSellQty, setShowSellQty] = React.useState(true);
  const [showSmallMeasureSellQty, setShowSmallMeasureSellQty] =
    React.useState(true);
  const [showAveragePurchasePrice, setShowAveragePurchasePrice] =
    React.useState(true);
  const [showAveragePurchaseOnePrice, setShowAveragePurchaseOnePrice] =
    React.useState(true);
  const [showAverageSellPrice, setShowAverageSellPrice] = React.useState(true);
  const [showAverageSellOnePrice, setShowAverageSellOnePrice] =
    React.useState(true);
  const [showTotalPurchasePrice, setShowTotalPurchasePrice] =
    React.useState(true);
  const [showTotalSellPrice, setShowTotalSellPrice] = React.useState(true);
  const [showTotalSellProfit, setShowTotalSellProfit] = React.useState(true);
  const [showAttachment, setShowAttachment] = React.useState(true);

  const [total, setTotal] = React.useState({
    mcTotalsell: 0,
    scTotalsell: 0,
    mcTotalPurchase: 0,
    scTotalPurchase: 0,
    mcTotalSellPerPurchasePrice: 0,
    scTotalSellPerPurchasePrice: 0,
  });

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }
  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    } else {
      sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
    }

    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    } else {
      sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
    }
    brand &&
      (await HTTP.get(
        `${url}/Reports/GetSupplierItemReport?id=${brand?.value}&byBrand=true&_start=${start}&_end=${end}${sendDates}`
      )
        .then((response) => {
          setTotal({
            mcTotalsell: parseFloat(response.headers["mctotalsellprice"]),
            scTotalsell: parseFloat(response.headers["sctotalsellprice"]),
            mcTotalPurchase: parseFloat(
              response.headers["mctotalpurchaseprice"]
            ),
            scTotalPurchase: parseFloat(
              response.headers["sctotalpurchaseprice"]
            ),
            mcTotalSellPerPurchasePrice: parseFloat(
              response.headers["mctotalsellperpurchaseprice"]
            ),
            scTotalSellPerPurchasePrice: parseFloat(
              response.headers["sctotalsellperpurchaseprice"]
            ),
          });
          setToTalItems(response.headers["x-total-count"]);

          // setMcTotalSellPerPurchasePrice(
          //   response.headers.mctotalsellperpurchaseprice
          // );
          // setScTotalSellPerPurchasePrice(
          //   response.headers.sctotalsellperpurchaseprice
          // );

          setData(response.data);

          let prod = [{ value: "All", label: "All" }];
          lodash
            .uniqBy(response.data, "productName")
            .map((e) => e.productName)
            .map((e) => prod.push({ value: e, label: e }));
        })
        .catch((error) => {
          if (error.response.status === 401) {
            redirect("/Login");
          }
          console.log(error.response.status);
        }));
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Brands?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((brand) => {
        newArray.push({
          value: brand.id,
          label: `${brand.name}`,
          brand: brand,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`brandStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.brandItemReport")}
              {brand === 0 ? "" : " (" + brand.label + ")"}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }}>
              <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.brands")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="w-100"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setBrand(e ? e : 0);
                  }}
                />
              </Col>

              <Col className="col-12 col-lg-8 mt-4">
                <Row>
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              {/* <Col className="col-6 col-md-3 col-lg-2 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => {
                          e.target.checked &&
                            setData(
                              lodash
                                .chain(data)
                                .groupBy("productId")
                                .map((value, key) => {
                                  let mcAveragePurchaseOnePriceList = value;
                                  let mcAveragePurchasePriceList = value;
                                  let mcAverageSellOnePriceList = value;
                                  let mcAverageSellPriceList = value;
                                  let scAveragePurchaseOnePriceList = value;
                                  let scAveragePurchasePriceList = value;
                                  let scAverageSellOnePriceList = value;
                                  let scAverageSellPriceList = value;

                                  mcAveragePurchaseOnePriceList =
                                    mcAveragePurchaseOnePriceList.filter(
                                      (o) => {
                                        return o.type === "Purchase";
                                      }
                                    );
                                  mcAveragePurchasePriceList =
                                    mcAveragePurchasePriceList.filter((o) => {
                                      return o.type === "Purchase";
                                    });
                                  mcAverageSellOnePriceList =
                                    mcAverageSellOnePriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  mcAverageSellPriceList =
                                    mcAverageSellPriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  scAveragePurchaseOnePriceList =
                                    scAveragePurchaseOnePriceList.filter(
                                      (o) => {
                                        return o.type === "Purchase";
                                      }
                                    );
                                  scAveragePurchasePriceList =
                                    scAveragePurchasePriceList.filter((o) => {
                                      return o.type === "Purchase";
                                    });
                                  scAverageSellOnePriceList =
                                    scAverageSellOnePriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  scAverageSellPriceList =
                                    scAverageSellPriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  return {
                                    productId: key,
                                    productName: value[0].productName,
                                    productBarcode: value[0].productBarcode,
                                    smallMeasurePurchaseQty: lodash.sumBy(
                                      value,
                                      "smallMeasurePurchaseQty"
                                    ),
                                    purchaseQty: lodash.sumBy(
                                      value,
                                      "purchaseQty"
                                    ),
                                    sellQty: lodash.sumBy(value, "sellQty"),
                                    smallMeasureSellQty: lodash.sumBy(
                                      value,
                                      "smallMeasureSellQty"
                                    ),
                                    smallMeasurePurchaseReturnQty: lodash.sumBy(
                                      value,
                                      "smallMeasurePurchaseReturnQty"
                                    ),
                                    purchaseReturnQty: lodash.sumBy(
                                      value,
                                      "purchaseReturnQty"
                                    ),
                                    sellReturnQty: lodash.sumBy(
                                      value,
                                      "sellReturnQty"
                                    ),
                                    smallMeasureSellReturnQty: lodash.sumBy(
                                      value,
                                      "smallMeasureSellReturnQty"
                                    ),

                                    ///// type === purchase
                                    mcAveragePurchaseOnePrice: lodash.meanBy(
                                      scAveragePurchaseOnePriceList,
                                      "mcAveragePurchaseOnePrice"
                                    ),
                                    scAveragePurchaseOnePrice: lodash.meanBy(
                                      mcAveragePurchaseOnePriceList,
                                      "scAveragePurchaseOnePrice"
                                    ),

                                    mcAveragePurchasePrice: lodash.meanBy(
                                      mcAveragePurchasePriceList,
                                      "mcAveragePurchasePrice"
                                    ),
                                    scAveragePurchasePrice: lodash.meanBy(
                                      scAveragePurchasePriceList,
                                      "scAveragePurchasePrice"
                                    ),

                                    ///// type === quick sell or sell
                                    mcAverageSellOnePrice: lodash.meanBy(
                                      mcAverageSellOnePriceList,
                                      "mcAverageSellOnePrice"
                                    ),
                                    scAverageSellOnePrice: lodash.meanBy(
                                      scAverageSellOnePriceList,
                                      "scAverageSellOnePrice"
                                    ),

                                    mcAverageSellPrice: lodash.meanBy(
                                      mcAverageSellPriceList,
                                      "mcAverageSellPrice"
                                    ),
                                    scAverageSellPrice: lodash.meanBy(
                                      scAverageSellPriceList,
                                      "scAverageSellPrice"
                                    ),

                                    mcTotalSellPrice: lodash.sumBy(
                                      value,
                                      "mcTotalSellPrice"
                                    ),
                                    scTotalSellPrice: lodash.sumBy(
                                      value,
                                      "scTotalSellPrice"
                                    ),
                                    mcTotalPurchasePrice: lodash.sumBy(
                                      value,
                                      "mcTotalPurchasePrice"
                                    ),
                                    scTotalPurchasePrice: lodash.sumBy(
                                      value,
                                      "scTotalPurchasePrice"
                                    ),
                                    mcTotalSellProfit: lodash.sumBy(
                                      value,
                                      "mcTotalSellProfit"
                                    ),
                                    scTotalSellProfit: lodash.sumBy(
                                      value,
                                      "scTotalSellProfit"
                                    ),
                                    type: "-",
                                  };
                                })
                                .value()
                            );
                        }}
                        defaultChecked={false}
                        name="gilad"
                      />
                    }
                    label={translate("resources.root.groupProducts")}
                    labelPlacement="bottom"
                  />
                </InputGroup>
              </Col> */}
              <Col className="col-6 col-md-3 col-lg-3 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setShowInUSD(e.target.checked)}
                        defaultChecked={showInUSD}
                      />
                    }
                    label={translate("resources.root.showInUSD")}
                    labelPlacement="bottom"
                  />
                </InputGroup>
              </Col>

              <Col className={`col-12 col-lg-9 mt-4 row row-cols-3`}>
                <h6>
                  {translate("resources.root.totalSellPrice")}:{" "}
                  {parseFloat(total?.mcTotalsell)?.toLocaleString()}{" "}
                  {translate("resources.root.usd")}
                </h6>{" "}
                <h6>
                  {translate("resources.root.totalPurchasePrice")}:{" "}
                  {parseFloat(total?.mcTotalPurchase)?.toLocaleString()}{" "}
                  {translate("resources.root.usd")}
                </h6>{" "}
                <h6>
                  {translate("resources.root.totalSellPerPurchasePrice")}:{" "}
                  {parseFloat(
                    total?.mcTotalSellPerPurchasePrice
                  )?.toLocaleString()}{" "}
                  {translate("resources.root.usd")}
                </h6>
                <h6>
                  {translate("resources.root.totalSellPrice")}:{" "}
                  {parseFloat(total?.scTotalsell)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h6>
                <h6>
                  {translate("resources.root.totalPurchasePrice")}:{" "}
                  {parseFloat(total?.scTotalPurchase)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h6>
                <h6>
                  {translate("resources.root.totalSellPerPurchasePrice")}:{" "}
                  {parseFloat(
                    total?.scTotalSellPerPurchasePrice
                  )?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h6>
              </Col>

              <Col xs={12}>
                <Row>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"purchaseQty"}
                        className="m-0"
                        defaultChecked={showPurchaseQty}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowPurchaseQty(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.purchaseQty")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"smallMeasurePurchaseQty"}
                        className="m-0"
                        defaultChecked={showSmallMeasurePurchaseQty}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowSmallMeasurePurchaseQty(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate(
                          "resources.root.smallMeasurePurchaseQty"
                        )}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"sellQty"}
                        className="m-0"
                        defaultChecked={showSellQty}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowSellQty(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.sellQty")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"smallMeasureSellQty"}
                        className="m-0"
                        defaultChecked={showSmallMeasureSellQty}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowSmallMeasureSellQty(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.smallMeasureSellQty")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"averagePurchasePrice"}
                        className="m-0"
                        defaultChecked={showAveragePurchasePrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowAveragePurchasePrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.averagePurchasePrice")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"averagePurchaseOnePrice"}
                        className="m-0"
                        defaultChecked={showAveragePurchaseOnePrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowAveragePurchaseOnePrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate(
                          "resources.root.averagePurchaseOnePrice"
                        )}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"averageSellPrice"}
                        className="m-0"
                        defaultChecked={showAverageSellPrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowAverageSellPrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.averageSellPrice")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"averageSellOnePrice"}
                        className="m-0"
                        defaultChecked={showAverageSellOnePrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowAverageSellOnePrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.averageSellOnePrice")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"totalPurchasePrice"}
                        className="m-0"
                        defaultChecked={showTotalPurchasePrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowTotalPurchasePrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.totalPurchasePrice")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"totalSellPrice"}
                        className="m-0"
                        defaultChecked={showTotalSellPrice}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowTotalSellPrice(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.totalSellPrice")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"attachment"}
                        className="m-0"
                        defaultChecked={showAttachment}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowAttachment(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.attachment")}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={6} md={4} lg={3}>
                    <InputGroup>
                      <FormControlLabel
                        id={"totalSellProfit"}
                        className="m-0"
                        defaultChecked={showTotalSellProfit}
                        control={
                          <Switch
                            defaultChecked
                            onChange={(e) => {
                              setShowTotalSellProfit(e.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label={translate("resources.root.totalSellProfit")}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>

              <Col className="col-4  mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={data.length <= 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>

              <Col className="col-4  mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={data.length <= 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4  mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>

                    {showAttachment && (
                      <th>{translate("resources.root.attachment")}</th>
                    )}
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>

                    {showPurchaseQty && (
                      <th>{translate("resources.root.purchaseQty")}</th>
                    )}
                    {showSmallMeasurePurchaseQty && (
                      <th>
                        {translate("resources.root.smallMeasurePurchaseQty")}
                      </th>
                    )}
                    {showSellQty && (
                      <th>{translate("resources.root.sellQty")}</th>
                    )}
                    {showSmallMeasureSellQty && (
                      <th>{translate("resources.root.smallMeasureSellQty")}</th>
                    )}
                    {showAveragePurchasePrice && (
                      <th>
                        {translate("resources.root.averagePurchasePrice")}
                      </th>
                    )}
                    {showAveragePurchaseOnePrice && (
                      <th>
                        {translate("resources.root.averagePurchaseOnePrice")}
                      </th>
                    )}
                    {showAverageSellPrice && (
                      <th>{translate("resources.root.averageSellPrice")}</th>
                    )}
                    {showAverageSellOnePrice && (
                      <th>{translate("resources.root.averageSellOnePrice")}</th>
                    )}
                    {showTotalPurchasePrice && (
                      <th>{translate("resources.root.totalPurchasePrice")}</th>
                    )}
                    {showTotalSellPrice && (
                      <th>{translate("resources.root.totalSellPrice")}</th>
                    )}
                    {showTotalSellProfit && (
                      <th>{translate("resources.root.totalSellProfit")}</th>
                    )}

                    <th>{translate("resources.root.type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          {showAttachment && (
                            <td>
                              <img src={`${path}${one.attachment}`} />
                            </td>
                          )}
                          <td>
                            {one.productName.substring(0, 50).concat("...") ??
                              "-"}
                          </td>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.productBarcode ?? "-"}
                            </a>
                          </td>
                          {showPurchaseQty && <td>{one.purchaseQty ?? "0"}</td>}
                          {showSmallMeasurePurchaseQty && (
                            <td>{one.smallMeasurePurchaseQty ?? "0"}</td>
                          )}
                          {showSellQty && <td>{one.sellQty ?? "0"}</td>}
                          {showSmallMeasureSellQty && (
                            <td>{one.smallMeasureSellQty ?? "0"}</td>
                          )}
                          {showAveragePurchasePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAveragePurchaseOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalPurchasePrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellProfit && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}

                          <td>{one.type ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                      <option value={10000}>10000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
}

export default BrandItemReport;
