import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [departmentReportVisits, setDepartmentReportVisits] = React.useState(
    []
  );
  const [pharmacy, setPharmacy] = React.useState([]);

  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const [totalSum, setTotalSum] = React.useState({
    visitor: 0,
    pharmacyVisitor: 0,
    totalSell: 0,
    totalCost: 0,
    labTestSell: 0,
    labTestCost: 0,
    xRaySell: 0,
    xRayCost: 0,
    medItemSell: 0,
    medItemCost: 0,
    pharmacyTotal: 0,
    totalDiscount: 0,
    totalExpense: 0,
  });

  const _ = require("lodash");

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }
    let sendDates2 = ``;
    if (startDate && startDate.length > 0) {
      sendDates2 += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates2 += `&endDate=${moment(endDate)
        .add(1, "day")
        .format("YYYY-MM-DD")}`;
    }

    await HTTP.get(
      `${url}/Expenses?_start=0&_end=100${sendDates}&cashers=${true}`
    ).then((response) => {
      setTotalSum((prevState) => ({
        ...prevState,
        totalExpense: response.headers.sctotalprice,
      }));
    });
    await HTTP.get(
      `${url}/QuickSellInvoices?_start=0&_end=500000${sendDates2}`
    ).then((response) => {
      setTotalSum((prevState) => ({
        ...prevState,
        pharmacyVisitor: response.headers["x-total-count"],
        pharmacyTotal: response.headers["sctotalprice"],
      }));
      setPharmacy(response.data);
    });

    await HTTP.get(`${url}/CustomerVisits?_start=0&_end=5000${sendDates}`)
      .then((response) => {
        let data = _(response.data)
          ?.groupBy((x) => x.doctorDepartmentId)
          ?.map((group, key) => ({
            departmentId: key,
            departmentName: _.head(group)?.doctorDepartmentName,
            customerCount: group.length,
            commission: _.head(group)?.doctorCommission ?? 0,
            mcCostPrice: parseFloat(_.sumBy(group, "mcCostPrice")),
            scCostPrice: parseFloat(_.sumBy(group, "scCostPrice")),
            mcSellPrice: parseFloat(_.sumBy(group, "mcSellPrice")),
            scSellPrice: parseFloat(_.sumBy(group, "scSellPrice")),
            scDiscountPrice: parseFloat(_.sumBy(group, "scDiscountPrice")),

            mcTotalLabTestCostPrice: parseFloat(
              _.sumBy(group, "mcTotalLabTestCostPrice")
            ),
            mcTotalLabTestSellPrice: parseFloat(
              _.sumBy(group, "mcTotalLabTestSellPrice")
            ),
            mcTotalMedItemCostPrice: parseFloat(
              _.sumBy(group, "mcTotalMedItemCostPrice")
            ),
            mcTotalMedItemSellPrice: parseFloat(
              _.sumBy(group, "mcTotalMedItemSellPrice")
            ),
            mcTotalXRayCostPrice: parseFloat(
              _.sumBy(group, "mcTotalXRayCostPrice")
            ),
            mcTotalXRaySellPrice: parseFloat(
              _.sumBy(group, "mcTotalXRaySellPrice")
            ),
            scTotalLabTestCostPrice: parseFloat(
              _.sumBy(group, "scTotalLabTestCostPrice")
            ),
            scTotalLabTestSellPrice: parseFloat(
              _.sumBy(group, "scTotalLabTestSellPrice")
            ),
            scTotalMedItemCostPrice: parseFloat(
              _.sumBy(group, "scTotalMedItemCostPrice")
            ),
            scTotalMedItemSellPrice: parseFloat(
              _.sumBy(group, "scTotalMedItemSellPrice")
            ),
            scTotalXRayCostPrice: parseFloat(
              _.sumBy(group, "scTotalXRayCostPrice")
            ),
            scTotalXRaySellPrice: parseFloat(
              _.sumBy(group, "scTotalXRaySellPrice")
            ),
            scTotalXRaySellPrice: parseFloat(
              _.sumBy(group, "scTotalXRaySellPrice")
            ),
            visitIds: _?.map(group, "Id"),
            payByMain: _.head(group)?.payByMain ?? true,
            endDate: _.head(group)?.date,
            date: _.last(group)?.date,
          }))
          .value();
        setDepartmentReportVisits(data);
        setTotalSum((prevState) => ({
          ...prevState,
          totalSell: _.sumBy(data, "scSellPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          totalCost: _.sumBy(data, "scCostPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          visitor: _.sumBy(data, "customerCount"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          labTestSell: _.sumBy(data, "scTotalLabTestSellPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          labTestCost: _.sumBy(data, "scTotalLabTestCostPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          medItemSell: _.sumBy(data, "scTotalMedItemSellPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          medItemCost: _.sumBy(data, "scTotalMedItemCostPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          xRaySell: _.sumBy(data, "scTotalXRaySellPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          xRayCost: _.sumBy(data, "scTotalXRayCostPrice"),
        }));
        setTotalSum((prevState) => ({
          ...prevState,
          totalDiscount: _.sumBy(data, "scDiscountPrice"),
        }));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div className="my-5">
      <PrintProvider>
        <Print single name={`customersStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("ra.navigation.DailyVisitReport")}
            </h1>
          </div>
        </Print>

        <NoPrint force>
          {isLoading && <LoadingScreen />}
          <Row className="d-flex align-items-center justify-content-around my-3">
            <Col className="row col-12 col-md-6">
              <Col className="col-12 col-md-6 ">
                <Form.Group controlId="startDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.startDate")} :
                    </Form.Label>
                    <Form.Control
                      className=""
                      label="startDate"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6 ">
                <Form.Group controlId="endDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2  text-nowrap">
                      {translate("resources.root.endDate")} :
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Col>
            <Col className="col-2">
              <Button
                variant={"outline-primary btn-block"}
                onClick={() => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";
                  window.print();
                  sidebar[0].style.display = "block";
                  appMenu[0].style.display = "block";
                  appBar[0].style.marginTop = "40px";
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}{" "}
              </Button>
            </Col>
            <Col className="col-2">
              <Button
                id="export"
                className="btn btn-primary btn-block"
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col className="col-2">
              <Button
                id="export"
                className="btn btn-primary btn-block"
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate("resources.root.search")}
              </Button>
            </Col>
          </Row>
        </NoPrint>
        <Print single>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col className="col-12 mt-4">
              {departmentReportVisits.length > 0 && (
                <Table bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>{translate("resources.root.totalVisitCount")}</th>
                      <th>{translate("resources.root.docotrRatio")}</th>
                      <th>{translate("resources.root.hospitalRatio")}</th>
                      <th>{translate("resources.root.totalPrice")}</th>
                      <th>{translate("resources.root.discount")}</th>{" "}
                      <th>{translate("resources.root.expenses")}</th>
                      <th>{translate("resources.root.finalPrice")}</th>
                      <th>{translate("resources.root.netPrice")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{parseFloat(totalSum.visitor)?.toLocaleString()}</td>
                      <td>
                        {(
                          parseFloat(totalSum.totalCost) +
                          parseFloat(totalSum.medItemCost)
                        )?.toLocaleString()}
                        &nbsp; IQD
                      </td>
                      <td>
                        {(
                          parseFloat(totalSum.totalSell) -
                          parseFloat(totalSum.totalCost) +
                          (parseFloat(totalSum.labTestSell) -
                            parseFloat(totalSum.labTestCost)) +
                          (parseFloat(totalSum.medItemSell) -
                            parseFloat(totalSum.medItemCost)) +
                          (parseFloat(totalSum.xRaySell) -
                            parseFloat(totalSum.xRayCost))
                        )?.toLocaleString()}
                        &nbsp; IQD
                      </td>
                      <td>
                        {(
                          parseFloat(totalSum.totalSell) +
                          parseFloat(totalSum.xRaySell) +
                          parseFloat(totalSum.labTestSell) +
                          parseFloat(totalSum.medItemSell)
                        )?.toLocaleString()}
                        &nbsp; IQD
                      </td>{" "}
                      <td>
                        {parseFloat(totalSum?.totalDiscount)?.toLocaleString()}{" "}
                        IQD
                      </td>
                      <td>
                        {parseFloat(totalSum?.totalExpense)?.toLocaleString()}{" "}
                        IQD
                      </td>
                      <td>
                        {(
                          parseFloat(totalSum.totalSell) +
                          parseFloat(totalSum.xRaySell) +
                          parseFloat(totalSum.labTestSell) +
                          parseFloat(totalSum.medItemSell) -
                          parseFloat(totalSum?.totalExpense) -
                          parseFloat(totalSum?.totalDiscount)
                        )?.toLocaleString()}{" "}
                        IQD
                      </td>
                      <td>
                        {(
                          parseFloat(totalSum.totalSell) +
                          parseFloat(totalSum.xRaySell) +
                          parseFloat(totalSum.labTestSell) +
                          parseFloat(totalSum.medItemSell) -
                          parseFloat(totalSum?.totalExpense) -
                          parseFloat(totalSum?.totalDiscount) -
                          (parseFloat(totalSum.totalCost) +
                            parseFloat(totalSum.medItemCost))
                        )?.toLocaleString()}{" "}
                        IQD
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
            <Col className="col-12 ">
              <Table bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.department")}</th>
                    <th>{translate("resources.root.totalVisitCount")}</th>
                    <th>{translate("resources.root.docotrRatio")}</th>
                    <th>{translate("resources.root.hospitalRatio")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                  </tr>
                </thead>
                {!isLoading && departmentReportVisits.length !== 0 && (
                  <tbody>
                    {departmentReportVisits?.map((one, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={`#/CustomerVisits/${one.departmentId}/show`}
                              target={"_blank"}
                            >
                              {one.departmentId}
                            </a>
                          </td>
                          <td> {one.departmentName}</td>
                          <td> {one.customerCount?.toLocaleString()}</td>
                          <td>
                            <Accordion className="w-100">
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="h5 text-cneter"
                              >
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scCostPrice +
                                      one.scTotalMedItemCostPrice
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.costPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scCostPrice?.toLocaleString()}&nbsp;
                                    IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate(
                                      "resources.root.medItemsCostPrice"
                                    )}
                                    : &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalMedItemCostPrice?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </td>
                          <td>
                            <Accordion className="w-100">
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="h5 text-cneter"
                              >
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice -
                                      one.scCostPrice +
                                      (one.scTotalLabTestSellPrice -
                                        one.scTotalLabTestCostPrice) +
                                      (one.scTotalMedItemSellPrice -
                                        one.scTotalMedItemCostPrice) +
                                      (one.scTotalXRaySellPrice -
                                        one.scTotalXRayCostPrice)
                                    )?.toLocaleString()}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionSummary>

                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.visitPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice - one.scCostPrice
                                    )?.toLocaleString()}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.labTest")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scTotalLabTestSellPrice -
                                      one.scTotalLabTestCostPrice
                                    )?.toLocaleString()}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.medItems")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scTotalMedItemSellPrice -
                                      one.scTotalMedItemCostPrice
                                    )?.toLocaleString()}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.XRay")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scTotalXRaySellPrice -
                                      one.scTotalXRayCostPrice
                                    )?.toLocaleString()}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </td>
                          <td>
                            <Accordion className="w-100">
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="h5 text-cneter"
                              >
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice +
                                      one.scTotalXRaySellPrice +
                                      one.scTotalLabTestSellPrice +
                                      one.scTotalMedItemSellPrice
                                    )?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.visitPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scSellPrice?.toLocaleString()} &nbsp;
                                    IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.labTest")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalLabTestSellPrice?.toLocaleString()}{" "}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.medItems")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalMedItemSellPrice?.toLocaleString()}{" "}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                              <AccordionDetails className="p-0 px-2">
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.XRay")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalXRaySellPrice?.toLocaleString()}{" "}
                                    &nbsp;IQD
                                  </p>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </td>{" "}
                        </tr>
                      );
                    })}{" "}
                    <tr>
                      <td></td>
                      <td> {translate("resources.root.pharmacy")}</td>
                      <td>
                        {parseFloat(totalSum.pharmacyVisitor)?.toLocaleString()}
                      </td>

                      <td colSpan={3} className="h5">
                        {parseFloat(totalSum.pharmacyTotal)?.toLocaleString()}
                        &nbsp; IQD
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
            <Col className="col-12 d-none">
              <Table
                className="text-center"
                id="exportableTable"
                bordered
                hover
              >
                <thead>
                  <tr>
                    <th>{translate("resources.root.totalVisitCount")}</th>
                    <th>{translate("resources.root.docotrRatio")}</th>
                    <th>{translate("resources.root.hospitalRatio")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                    <th>{translate("resources.root.discount")}</th>{" "}
                    <th>{translate("resources.root.expenses")}</th>
                    <th>{translate("resources.root.finalPrice")}</th>
                    <th>{translate("resources.root.netPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{parseFloat(totalSum.visitor)?.toLocaleString()}</td>
                    <td>
                      {(
                        parseFloat(totalSum.totalCost) +
                        parseFloat(totalSum.medItemCost)
                      )?.toLocaleString()}
                      &nbsp; IQD
                    </td>
                    <td>
                      {(
                        parseFloat(totalSum.totalSell) -
                        parseFloat(totalSum.totalCost) +
                        (parseFloat(totalSum.labTestSell) -
                          parseFloat(totalSum.labTestCost)) +
                        (parseFloat(totalSum.medItemSell) -
                          parseFloat(totalSum.medItemCost)) +
                        (parseFloat(totalSum.xRaySell) -
                          parseFloat(totalSum.xRayCost))
                      )?.toLocaleString()}
                      &nbsp; IQD
                    </td>
                    <td>
                      {(
                        parseFloat(totalSum.totalSell) +
                        parseFloat(totalSum.xRaySell) +
                        parseFloat(totalSum.labTestSell) +
                        parseFloat(totalSum.medItemSell)
                      )?.toLocaleString()}
                      &nbsp; IQD
                    </td>{" "}
                    <td>
                      {parseFloat(totalSum?.totalDiscount)?.toLocaleString()}{" "}
                      IQD
                    </td>
                    <td>
                      {parseFloat(totalSum?.totalExpense)?.toLocaleString()} IQD
                    </td>
                    <td>
                      {(
                        parseFloat(totalSum.totalSell) +
                        parseFloat(totalSum.xRaySell) +
                        parseFloat(totalSum.labTestSell) +
                        parseFloat(totalSum.medItemSell) -
                        parseFloat(totalSum?.totalExpense) -
                        parseFloat(totalSum?.totalDiscount)
                      )?.toLocaleString()}{" "}
                      IQD
                    </td>
                    <td>
                      {(
                        parseFloat(totalSum.totalSell) +
                        parseFloat(totalSum.xRaySell) +
                        parseFloat(totalSum.labTestSell) +
                        parseFloat(totalSum.medItemSell) -
                        parseFloat(totalSum?.totalExpense) -
                        parseFloat(totalSum?.totalDiscount) -
                        (parseFloat(totalSum.totalCost) +
                          parseFloat(totalSum.medItemCost))
                      )?.toLocaleString()}{" "}
                      IQD
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.department")}</th>
                    <th>{translate("resources.root.totalVisitCount")}</th>
                    <th>{translate("resources.root.docotrRatio")}</th>
                    <th>{translate("resources.root.hospitalRatio")}</th>
                    <th colSpan={3}>
                      {translate("resources.root.totalPrice")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    departmentReportVisits?.map((one, index) => {
                      return [0, 1, 2, 3, 4].map((two, index2) => {
                        return (
                          <tr key={index}>
                            {/* Doctor Ratio */}
                            <td>
                              {index2 === 0 && (
                                <a
                                  href={`#/CustomerVisits/${one.departmentId}/show`}
                                  target={"_blank"}
                                >
                                  {one.departmentId}
                                </a>
                              )}
                            </td>
                            <td>{index2 === 0 && one.departmentName}</td>
                            <td>{index2 === 0 && one.customerCount}</td>
                            {index2 === 0 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scCostPrice +
                                      one.scTotalMedItemCostPrice
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 1 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.costPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scCostPrice?.toLocaleString()}&nbsp;
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 2 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate(
                                      "resources.root.medItemsCostPrice"
                                    )}
                                    : &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalMedItemCostPrice?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 3 && <td></td>}
                            {/* ‌Hospital Ratio */}
                            {index2 === 0 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice -
                                      one.scCostPrice +
                                      (one.scTotalLabTestSellPrice -
                                        one.scTotalLabTestCostPrice) +
                                      (one.scTotalMedItemSellPrice -
                                        one.scTotalMedItemCostPrice) +
                                      (one.scTotalXRaySellPrice -
                                        one.scTotalXRayCostPrice)
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 1 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.visitPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice - one.scCostPrice
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 2 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.labTest")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scTotalLabTestSellPrice -
                                      one.scTotalLabTestCostPrice
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 3 && (
                              <td>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.XRay")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scTotalXRaySellPrice -
                                      one.scTotalXRayCostPrice
                                    )?.toLocaleString()}
                                    &nbsp; IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {/* Total Ratio */}
                            {index2 === 0 && (
                              <td colSpan={3}>
                                <div className="d-flex justify-content-start align-items-center h5 ">
                                  <p>
                                    {translate("resources.root.totalPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {(
                                      one.scSellPrice +
                                      one.scTotalXRaySellPrice +
                                      one.scTotalLabTestSellPrice +
                                      one.scTotalMedItemSellPrice
                                    )?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 1 && (
                              <td colSpan={3}>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.visitPrice")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scSellPrice?.toLocaleString()}&nbsp;
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 2 && (
                              <td colSpan={3}>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.labTest")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalLabTestSellPrice?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                            {index2 === 3 && (
                              <td colSpan={3}>
                                <div className="d-flex justify-content-start align-items-center h5">
                                  <p>
                                    {translate("resources.root.XRay")}:
                                    &nbsp;&nbsp;
                                  </p>
                                  <p>
                                    {one.scTotalXRaySellPrice?.toLocaleString()}{" "}
                                    IQD
                                  </p>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      });
                    })}{" "}
                  <tr>
                    <td></td>
                    <td> {translate("resources.root.pharmacy")}</td>
                    <td>
                      {parseFloat(totalSum.pharmacyVisitor)?.toLocaleString()}
                    </td>
                    <td></td>

                    <td className="h5">
                      {parseFloat(totalSum.pharmacyTotal)?.toLocaleString()}
                      &nbsp; IQD
                    </td>
                    <td colSpan={3} className="h5">
                      {parseFloat(totalSum.pharmacyTotal)?.toLocaleString()}
                      &nbsp; IQD
                    </td>
                  </tr>
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
