import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";

export default () => {
  const { id } = useParams();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10000);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplier, setSupplier] = React.useState(0);
  const [supplierObject, setSupplierObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [openAccountQty, setOpenAccountQty] = React.useState(0);
  const [previousBalance, setPreviousBalance] = React.useState(0);
  const [initialBalance, setInitialBalance] = React.useState(0);
  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [returnInvoice, setReturnInvoice] = React.useState(0);
  const [supplierInvoice, setSupplierInvoice] = React.useState(0);
  const [supplierPayment, setSupplierPayment] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }
    await HTTP.get(
      `${url}/Reports/SupplierAccountStatement/${supplier}?_start=${0}&_end=${10000}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setReturnInvoice(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcreturninvoice ?? 0
            : response.headers.screturninvoice ?? 0
        );
        setSupplierInvoice(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcsupplierinvoice ?? 0
            : response.headers.mcsupplierinvoice ?? 0
        );
        setSupplierPayment(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcsupplierpayment ?? 0
            : response.headers.mcsupplierpayment ?? 0
        );
        setCurrentBalance(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mccurrentbalance ?? 0
            : response.headers.sccurrentbalance ?? 0
        );
        setOpenAccountQty(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcopenaccountqty ?? 0
            : response.headers.scopenaccountqty ?? 0
        );
        setPreviousBalance(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcpreviousbalance ?? 0
            : response.headers.scpreviousbalance ?? 0
        );
        setInitialBalance(
          supplierObject && supplierObject.payLoanByMain
            ? response.headers.mcinitialbalance ?? 0
            : response.headers.scinitialbalance ?? 0
        );
        if (response.data && response.data.length > 0) {
          let firstBalance =
            parseFloat(
              (supplierObject.payLoanByMain
                ? response.headers.mcinitialbalance
                : response.headers.scinitialbalance) ?? 0
            ) +
            parseFloat(
              (supplierObject.payLoanByMain
                ? response.headers.mcpreviousbalance
                : response.headers.scpreviousbalance) ?? 0
            );
          let newBalance = !response.data[0].isWithdraw
            ? firstBalance +
              parseFloat(
                supplierObject.payLoanByMain
                  ? response.data[0].mcWithdraw
                  : response.data[0].scWithdraw
              )
            : firstBalance -
              parseFloat(
                supplierObject.payLoanByMain
                  ? response.data[0].mcDeposit
                  : response.data[0].scDeposit
              );
          response.data[0].balance = newBalance;
          for (let i = 1; i < response.data.length; i++) {
            let temp = !response.data[i].isWithdraw
              ? newBalance +
                parseFloat(
                  supplierObject.payLoanByMain
                    ? response.data[i].mcWithdraw
                    : response.data[i].scWithdraw
                )
              : newBalance -
                parseFloat(
                  supplierObject.payLoanByMain
                    ? response.data[i].mcDeposit
                    : response.data[i].scDeposit
                );
            response.data[i].balance = temp;
            newBalance = temp;
          }
        }
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [supplier, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  React.useEffect(async function () {
    await HTTP.get(`${url}/Suppliers/${id}`).then((response) => {
      setSupplierObject(response.data);
    });
    setSupplier(id);
    getData();
  }, []);

  return (
    <div>
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierStatement")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            <Row style={{ fontSize: "15px" }}>
              {isLoading && <LoadingScreen />}

              <Col xs={4}>
                <Form.Group as={Row} controlId="startDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.startDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group as={Row} controlId="endDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.endDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <h5 className={`text-center`}>
                  {startDate && startDate.length > 0 ? (
                    <span>
                      {translate("resources.root.previousBalance")}:{" "}
                      <b>
                        {parseFloat(previousBalance)?.toLocaleString()}{" "}
                        {supplierObject.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")}
                      </b>
                    </span>
                  ) : (
                    <span>
                      {translate("resources.root.initialBalance")}:{" "}
                      <b>
                        {parseFloat(initialBalance)?.toLocaleString()}{" "}
                        {supplierObject.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")}
                      </b>
                    </span>
                  )}{" "}
                  -{" "}
                  <span>
                    {translate("resources.root.currentBalance")}:{" "}
                    <b>
                      {parseFloat(currentBalance)?.toLocaleString()}{" "}
                      {supplierObject.payLoanByMain
                        ? translate("resources.root.usd")
                        : translate("resources.root.iqd")}
                    </b>
                  </span>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>{`${translate(
                  "resources.root.returnInvoice"
                )}: ${parseFloat(returnInvoice)?.toLocaleString()}`}</h4>
              </Col>
              <Col>
                <h4>{`${translate(
                  "resources.root.supplierInvoice"
                )}: ${parseFloat(supplierInvoice)?.toLocaleString()}`}</h4>
              </Col>
              <Col>
                <h4>{`${translate(
                  "resources.root.supplierPayment"
                )}: ${parseFloat(supplierPayment)?.toLocaleString()}`}</h4>
              </Col>
              <Col>
                <Button
                  id="export"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.dollarDeposit")}</th>
                    <th>{translate("resources.root.dinarDeposit")}</th>
                    <th>{translate("resources.root.dollarWithdraw")}</th>
                    <th>{translate("resources.root.dinarWithdraw")}</th>
                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#dcf7f7" }}>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td>
                      {startDate && startDate.length > 0 ? (
                        <span>
                          {translate("resources.root.previousBalance")}
                        </span>
                      ) : (
                        <span>
                          {translate("resources.root.initialBalance")}
                        </span>
                      )}
                    </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td>
                      {startDate && startDate.length > 0 ? (
                        <span>
                          {parseFloat(previousBalance)?.toLocaleString()}{" "}
                          {supplierObject.payLoanByMain
                            ? translate("resources.root.usd")
                            : translate("resources.root.iqd")}
                        </span>
                      ) : (
                        <span>
                          {parseFloat(initialBalance)?.toLocaleString()}{" "}
                          {supplierObject.payLoanByMain
                            ? translate("resources.root.usd")
                            : translate("resources.root.iqd")}
                        </span>
                      )}
                    </td>
                  </tr>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#BFEAA3"
                              : "#FCE8E9",
                          }}
                        >
                          <td>{one.id}</td>
                          <td>
                            <a
                              href={
                                one.description === "Supplier Payment"
                                  ? `#/SupplierPayments/${one.id}/show`
                                  : one.description === "Return Invoice"
                                  ? `#/PurchaseReturnInvoices/${one.id}/show`
                                  : one.description === "Supplier Invoice"
                                  ? `#/PurchaseInvoices/${one.id}/show`
                                  : "#/"
                              }
                              target="_blank"
                            >
                              {one.invoiceNumber &&
                                one.invoiceNumber.toUpperCase()}
                            </a>
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          <td>{one.mcWithdraw?.toLocaleString()}</td>
                          <td>{one.scWithdraw?.toLocaleString()}</td>
                          <td>{one.mcDeposit?.toLocaleString()}</td>
                          <td>{one.scDeposit?.toLocaleString()}</td>
                          <td>
                            {one.balance.toFixed(3)?.toLocaleString()}{" "}
                            {supplierObject.payLoanByMain
                              ? translate("resources.root.usd")
                              : translate("resources.root.iqd")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
