import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import lodash from "lodash";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [invoices, setInvoices] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [total, setTotal] = React.useState({
    totalSellQty: 0,
    totalSellSmallQty: 0,
    totalSellGiftQty: 0,
    totalSellSmallGiftQty: 0,

    totalDiscountPrice: {
      mc: 0,
      sc: 0,
    },
    totalSellPrice: {
      mc: 0,
      sc: 0,
    },
    totalProfit: {
      mc: 0,
      sc: 0,
    },
  });

  const [switches, setSwitches] = React.useState([
    {
      title: "purchaseQty",
      value: true,
    },
    {
      title: "smallMeasurePurchaseQty",
      value: true,
    },
    {
      title: "sellQty",
      value: true,
    },
    {
      title: "giftQty",
      value: true,
    },
    {
      title: "averageSellPrice",
      value: true,
    },
    {
      title: "averageSellOnePrice",
      value: true,
    },
    {
      title: "totalSellPrice",
      value: true,
    },
    {
      title: "totalSellProfit",
      value: true,
    },
  ]);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let invoice = ``;
    if (invoices !== 0) {
      invoice = `${invoices}`;
    } else {
      invoice = "";
    }

    await HTTP.get(
      `${url}/AdminReports/GetInvoiceProfitLoseReport?_start=${start}&_end=${end}${invoice}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotal({
          totalSellQty: response.headers["totalsellqty"],
          totalSellSmallQty: response.headers["totalsellsmallqty"],
          totalSellGiftQty: response.headers["totalsellgiftqty"],
          totalSellSmallGiftQty: response.headers["totalsellsmallgiftqty"],

          totalDiscountPrice: {
            mc: response.headers["mctotaldiscountprice"],
            sc: response.headers["sctotaldiscountprice"],
          },

          totalSellPrice: {
            mc: response.headers["mctotalsellprice"],
            sc: response.headers["sctotalsellprice"],
          },
          totalProfit: {
            mc: response.headers["mctotalprofit"],
            sc: response.headers["sctotalprofit"],
          },
        });
        setData(
          lodash
            .chain(response.data)
            .groupBy("productId")
            .map((value, key) => {
              let mcAveragePurchaseOnePriceList = value;
              let mcAveragePurchasePriceList = value;
              let mcAverageSellOnePriceList = value;
              let mcAverageSellPriceList = value;
              let scAveragePurchaseOnePriceList = value;
              let scAveragePurchasePriceList = value;
              let scAverageSellOnePriceList = value;
              let scAverageSellPriceList = value;

              mcAveragePurchaseOnePriceList =
                mcAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              mcAveragePurchasePriceList = mcAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              mcAverageSellOnePriceList = mcAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              mcAverageSellPriceList = mcAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              scAveragePurchaseOnePriceList =
                scAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              scAveragePurchasePriceList = scAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              scAverageSellOnePriceList = scAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              scAverageSellPriceList = scAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              return {
                productId: key,
                productName: value[0].productName,
                productBarcode: value[0].productBarcode,
                smallMeasurePurchaseQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseQty"
                ),
                purchaseQty: lodash.sumBy(value, "purchaseQty"),
                sellQty: lodash.sumBy(value, "sellQty"),
                smallMeasureSellQty: lodash.sumBy(value, "smallMeasureSellQty"),

                ///// type === purchase
                mcAveragePurchaseOnePrice: lodash.meanBy(
                  scAveragePurchaseOnePriceList,
                  "mcAveragePurchaseOnePrice"
                ),
                scAveragePurchaseOnePrice: lodash.meanBy(
                  mcAveragePurchaseOnePriceList,
                  "scAveragePurchaseOnePrice"
                ),

                mcAveragePurchasePrice: lodash.meanBy(
                  mcAveragePurchasePriceList,
                  "mcAveragePurchasePrice"
                ),
                scAveragePurchasePrice: lodash.meanBy(
                  scAveragePurchasePriceList,
                  "scAveragePurchasePrice"
                ),

                ///// type === quick sell or sell
                mcAverageSellOnePrice: lodash.meanBy(
                  mcAverageSellOnePriceList,
                  "mcAverageSellOnePrice"
                ),
                scAverageSellOnePrice: lodash.meanBy(
                  scAverageSellOnePriceList,
                  "scAverageSellOnePrice"
                ),

                mcAverageSellPrice: lodash.meanBy(
                  mcAverageSellPriceList,
                  "mcAverageSellPrice"
                ),
                scAverageSellPrice: lodash.meanBy(
                  scAverageSellPriceList,
                  "scAverageSellPrice"
                ),

                mcTotalSellPrice: lodash.sumBy(value, "mcTotalSellPrice"),
                scTotalSellPrice: lodash.sumBy(value, "scTotalSellPrice"),
                mcTotalPurchasePrice: lodash.sumBy(
                  value,
                  "mcTotalPurchasePrice"
                ),
                scTotalPurchasePrice: lodash.sumBy(
                  value,
                  "scTotalPurchasePrice"
                ),
                mcTotalSellProfit: lodash.sumBy(value, "mcTotalSellProfit"),
                scTotalSellProfit: lodash.sumBy(value, "scTotalSellProfit"),
                type: "-",
              };
            })
            .value()
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(function () {
    setIsLoading(true);
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [invoices, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/SellInvoices?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((invoice) => {
        newArray.push({
          value: invoice.id,
          label: `${invoice.invoiceNumber} (${invoice.customerName})`,
          invoice: invoice,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div className="m-4">
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.profitLoseReportByInvoice")}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}

            <Row className="mb-3">
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.chooseCustomer")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  isMulti
                  menuPortalTarget={document.body}
                  className="w-100"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    let invoice = "";
                    if (e.length !== 0) {
                      e.map((x) => (invoice += "&id=" + x.value));
                      setInvoices(invoice);
                    } else {
                      setInvoices(``);
                    }
                  }}
                />
              </Col>
              <Col className="col-12 row row-cols-2 row-cols-md-3 row-cols-lg-4 p-0 mt-4 justify-content-start ">
                <InputGroup className="row justify-content-start mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setShowInUSD(e.target.checked)}
                        defaultChecked={showInUSD}
                      />
                    }
                    label={translate("resources.root.showInUSD")}
                  />
                </InputGroup>
                {switches.map((switcher, index) => {
                  return (
                    <InputGroup
                      key={index}
                      className="row justify-content-start mx-md-5 mx-lg-0  "
                    >
                      <FormControlLabel
                        className="m-0 text-nowrap "
                        control={
                          <Switch
                            onChange={(e) => {
                              let updatedSwitches = switches.map((item, i) => {
                                if (i === index) {
                                  return { ...item, value: e.target.checked };
                                }
                                return item;
                              });
                              setSwitches(updatedSwitches);
                            }}
                            defaultChecked={switcher.value}
                          />
                        }
                        label={translate(`resources.root.${switcher.title}`)}
                      />
                    </InputGroup>
                  );
                })}
              </Col>
              <Col className="col-12 mt-4 row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-start">
                <h5>
                  {translate("resources.root.totalDiscountPrice")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalDiscountPrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalDiscountPrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalSellPrice")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalSellPrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalSellPrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalProfit")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalProfit?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalProfit?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalSellQty")}:&nbsp;
                  {parseFloat(total?.totalSellQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(total?.totalSellSmallQty)?.toLocaleString()}
                </h5>

                <h5>
                  {translate("resources.root.totalSellGiftQty")}:&nbsp;
                  {parseFloat(total?.totalSellGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(total?.totalSellSmallGiftQty)?.toLocaleString()}
                </h5>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row className={`mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {switches.map((switcher) => {
                      return (
                        switcher.value && (
                          <th>
                            {translate(`resources.root.${switcher.title}`)}
                          </th>
                        )
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one?.mcAveragePurchaseOnePrice >
                                one?.mcAverageSellOnePrice &&
                              one?.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one?.productId ?? "-"}</td>
                          <td>
                            {one?.productName.length > 50
                              ? one?.productName.substring(0, 50).concat("...")
                              : one?.productName}
                          </td>
                          <td>{one?.productBarcode ?? "-"}</td>

                          {switches[0].value && (
                            <td>{one?.purchaseQty ?? "0"}</td>
                          )}
                          {switches[1].value && (
                            <td>{one?.smallMeasurePurchaseQty ?? "0"}</td>
                          )}

                          {switches[2].value && (
                            <td>
                              {parseFloat(one?.sellQty)?.toLocaleString() ??
                                "0"}{" "}
                              +{" "}
                              {parseFloat(
                                one?.smallMeasureSellQty
                              )?.toLocaleString() ?? "0"}
                            </td>
                          )}
                          {switches[3].value && (
                            <td>
                              {parseFloat(
                                one?.totalSellGiftQty
                              )?.toLocaleString() ?? "0"}{" "}
                              +{" "}
                              {parseFloat(
                                one?.totalsellsmallgiftqty
                              )?.toLocaleString() ?? "0"}
                            </td>
                          )}
                          {switches[4].value && (
                            <td>
                              {showInUSD
                                ? one?.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one?.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[5].value && (
                            <td>
                              {showInUSD
                                ? one?.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one?.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[6].value && (
                            <td>
                              {showInUSD
                                ? one?.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one?.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[7].value && (
                            <td>
                              {showInUSD
                                ? one?.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one?.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
