import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  FunctionField,
  Edit,
  ShowButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { ListActions } from "../templates/ListActions";

import moment from "moment";

export const ProductCategoriesList = (props) => (
  <div>
    <List
      actions={<ListActions hasCreate="productcategory.list" />}
      {...props}
      debounce={1500}
      filters={<SearchFilter />}
      sort={{ field: "id", order: "DESC" }}
      pagination={<MyCustomPagination />}
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="productcategory.list" />
        }
      >
        {" "}
        <TextField source="name" label="resources.root.name" />
        <TextField
          source="shortDescription"
          label="resources.root.shortDescription"
        />
        <TextField
          source="longDescription"
          label="resources.root.longDescription"
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  </div>
);

export const ProductCategoriesCreate = (props) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" label="resources.root.name" />
        <TextInput
          source="shortDescription"
          label="resources.root.shortDescription"
          options={{ multiLine: true }}
        />
        <TextInput
          source="longDescription"
          label="resources.root.longDescription"
          options={{ multiLine: true }}
        />
      </SimpleForm>
    </Create>
  );
};

export const ProductCategoriesEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" label="resources.root.name" />
        <TextInput
          source="shortDescription"
          label="resources.root.shortDescription"
          options={{ multiLine: true }}
        />
        <TextInput
          source="longDescription"
          label="resources.root.longDescription"
          options={{ multiLine: true }}
        />
      </SimpleForm>
    </Edit>
  );
};
