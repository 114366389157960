import React, { useEffect, useState } from "react";
import { url } from "../request";
import { useNotify, useTranslate } from "react-admin";
import { Col, Form, Row, Button, Card, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { HTTP } from "../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Settings = () => {
  const [dollarPrice, setDollarPrice] = useState(
    localStorage.getItem("dollarPrice") || 1200
  );
  const [deactivated, setDeactivated] = useState(false);
  const [ignoreProductPriceType, setIgnoreProductPriceType] = useState(
    localStorage.getItem("ignoreProductPriceType") === "true" ? true : false
  );
  const [useCustomPhoneNumbers, setUseCustomPhoneNumbers] = useState(
    localStorage.getItem("useCustomPhoneNumbers") === "true" ? true : false
  );

  const [updateSellingPrice, setUpdateSellingPrice] = useState(
    localStorage.getItem("updateSellingPrice") === "true" ? true : false
  );

  const [updatePurchasePrice, setUpdatePurchasePrice] = useState(
    localStorage.getItem("updatePurchasePrice") === "true" ? true : false
  );

  const [defaultProducts, setDefaultProducts] = useState(
    localStorage.getItem("defaultProducts") || JSON.stringify([])
  );

  const translate = useTranslate();
  const notify = useNotify();

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [commands, setCommands] = useState([]);
  const [stores, setStores] = useState([]);

  const [showCurrentLoan, setShowCurrentLoan] = useState(
    localStorage?.getItem("showCurrentLoan") === "true" ? true : false
  );
  const [showLastSellPrice, setShowLastSellPrice] = useState(
    localStorage.getItem("showLastSellPrice") === "true" ? true : false
  );
  const [productPurchaseByUSD, setProductPurchaseByUSD] = useState(
    localStorage.getItem("productPurchaseByUSD") === "true" ? true : false
  );
  const [productSellByUSD, setProductSellByUSD] = useState(
    localStorage.getItem("productSellByUSD") === "true" ? true : false
  );
  const [showSmallMeasure, setShowSmallMeasure] = useState(
    localStorage.getItem("showSmallMeasure") === "true" ? true : false
  );
  const [showDiscountInvoice, setShowDiscountInvoice] = useState(
    localStorage.getItem("showDiscountInvoice") === "true" ? true : false
  );
  const [colorizeTable, setColorizeTable] = useState(
    localStorage.getItem("colorizeTable") === "true" ? true : false
  );
  const [sellMainCurrency, setSellMainCurrency] = useState(
    localStorage.getItem("sellMainCurrency") === "true" ? true : false
  );
  const [purchaseMainCurrency, setPurchaseMainCurrency] = useState(
    localStorage.getItem("purchaseMainCurrency") === "true" ? true : false
  );

  const [ignoreZeroDashboard, setIgnoreZeroDashboard] = useState(
    localStorage.getItem("ignoreZeroDashboard") === "true" ? true : false
  );
  const [
    ignoreBelowZeroProductsDashboard,
    setIgnoreBelowZeroProductsDashboard,
  ] = useState(
    localStorage.getItem("ignoreBelowZeroProductsDashboard") === "true"
      ? true
      : false
  );

  const [useOldSelect, setUseOldSelect] = useState(
    localStorage.getItem("useOldSelect") === "true" ? true : false
  );
  const [showCreatedAt, setShowCreatedAt] = useState(
    localStorage.getItem("showCreatedAt") === "true" ? true : false
  );
  const [showPurchasePriceInList, setShowPurchasePriceInList] = useState(
    localStorage.getItem("showPurchasePriceInList") === "true" ?? true
  );
  const [showLastUser, setShowLastUser] = useState(
    localStorage.getItem("showLastUser") === "true" ? true : false
  );

  const [showDriver, setShowDriver] = useState(
    localStorage.getItem("showDriver") === "true" ? true : false
  );
  const [showNote, setShowNote] = useState(
    localStorage.getItem("showNote") === "true" ? true : false
  );
  const [showInvoiceNumber, setShowInvoiceNumber] = useState(
    localStorage.getItem("showInvoiceNumber") === "true" ? true : false
  );
  const [showTotalPrice, setShowTotalPrice] = useState(
    localStorage.getItem("showTotalPrice") === "true" ? true : false
  );

  function handleQuickCommands(e, command) {
    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    if (e.target.checked) {
      elements.push(command);
    } else {
      let index = elements.findIndex((x) => x.route === command.route);
      elements.splice(index, 1);
    }
    localStorage.setItem("quickCommands", JSON.stringify(elements));
  }

  const buttons = [
    {
      window: "SellReceipt",
      title: "invoiceMockup",
    },
    {
      window: "VoucherReceipt",
      title: "voucherMockup",
    },
    {
      window: "ProductLabel",
      title: "labelPrinter",
    },
    {
      window: "ProductOffers",
      title: "offerPrint",
    },
    {
      window: "ProductStick",
      title: "printStick",
    },
  ];

  useEffect(() => {
    let commands = [
      {
        icon: "fa-plus",
        route: "Products",
        label: "products",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Products/create",
        label: "createProducts",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Damages/create",
        label: "createDamages",
        group: "product",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "StoreTransferInvoices/create",
        label: "createTransfers",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "ProcessInvoices/create",
        label: "createProcess",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Customers",
        label: "customers",
        group: "customer",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Customers/create",
        label: "createCustomer",
        group: "customer",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "CustomerPayments/create",
        label: "createCustomerPayment",
        group: "customer",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellInvoices",
        label: "sellInvoices",
        group: "sellInvoice",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellInvoices/create",
        label: "createSellInvoices",
        group: "sellInvoice",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellReturnInvoices/create",
        label: "createSellReturnInvoice",
        group: "sellInvoice",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Suppliers",
        label: "suppliers",
        group: "supplier",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Suppliers/create",
        label: "createSupplier",
        group: "supplier",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SupplierPayments/create",
        label: "createSupplierPayment",
        group: "supplier",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseInvoices",
        label: "purchaseInvoices",
        group: "purchaseInvoices",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseInvoices/create",
        label: "createPurchaseInvoices",
        group: "purchaseInvoices",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseReturnInvoices/create",
        label: "createPurchaseReturnInvoice",
        group: "purchaseInvoices",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Withdraws/create",
        label: "createWithdraws",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Deposits/create",
        label: "createDeposits",
        group: "accountant",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Expenses",
        label: "expenses",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Expenses/create",
        label: "createExpenses",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
    ];
    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    commands.map((command) => {
      let index = elements.findIndex((x) => x.route === command.route);
      command.checked = index > -1;
    });
    setCommands(commands);

    HTTP.get(`${url}/Stores?_end=1000`)
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    HTTP.get(`${url}/Customers?_end=1000`)
      .then((response) => {
        let newArray = [];
        response.data.map((customer) => {
          newArray.push({
            value: customer.id,
            label: customer.firstName + " " + customer.middleName,
            customer: customer,
          });
        });
        setCustomers(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.get(`${url}/Products?_end=5000`)
      .then((response) => {
        let newArray = [];
        response.data.map((product) => {
          newArray.push({
            value: product.id,
            label: product.name,
            product: product,
          });
        });
        setProducts(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
    localStorage.setItem("ignoreProductPriceType", ignoreProductPriceType);
    localStorage.setItem("useCustomPhoneNumbers", useCustomPhoneNumbers);
    localStorage.setItem("defaultProducts", defaultProducts);

    HTTP.get(`${url}/ProductCategories?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((category) => {
          newArray.push({
            value: category.id,
            label: category.name,
            category: category,
          });
        });
        setCategories(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ignoreProductPriceType, useCustomPhoneNumbers]);

  const permissonsSwitch = [
    {
      id: "updateSellingPriceOnSale",
      title: "updateSellingPriceOnSale",
      localStorage: "updateSellingPrice",
      state: updateSellingPrice,
      setState: setUpdateSellingPrice,
    },
    {
      id: "showCurrentLoan",
      title: "showCurrentLoan",
      localStorage: "showCurrentLoan",
      state: showCurrentLoan,
      setState: setShowCurrentLoan,
    },
    {
      id: "getLastPriceForSelling",
      title: "getLastPriceForSelling",
      localStorage: "showLastSellPrice",
      state: showLastSellPrice,
      setState: setShowLastSellPrice,
    },
    {
      id: "productPurchaseByUSD",
      title: "purchaseByUsd",
      localStorage: "productPurchaseByUSD",
      state: productPurchaseByUSD,
      setState: setProductPurchaseByUSD,
    },
    {
      id: "sellByUsd",
      title: "sellByUsd",
      localStorage: "productSellByUSD",
      state: productSellByUSD,
      setState: setProductSellByUSD,
    },
    {
      id: "showSmallMeasure",
      title: "showSmallMeasure",
      localStorage: "showSmallMeasure",
      state: showSmallMeasure,
      setState: setShowSmallMeasure,
    },
    {
      id: "showDiscountInvoice",
      title: "showDiscountInvoice",
      localStorage: "showDiscountInvoice",
      state: showSmallMeasure,
      setState: setShowSmallMeasure,
    },
    {
      id: "colorizeTable",
      title: "colorizeTable",
      localStorage: "colorizeTable",
      state: colorizeTable,
      setState: setColorizeTable,
    },
    {
      id: "sellMainCurrency",
      title: "sellMainCurrency",
      localStorage: "sellMainCurrency",
      state: sellMainCurrency,
      setState: setSellMainCurrency,
    },
    {
      id: "purchaseMainCurrency",
      title: "purchaseMainCurrency",
      localStorage: "purchaseMainCurrency",
      state: purchaseMainCurrency,
      setState: setPurchaseMainCurrency,
    },
    {
      id: "updatePurchasePriceOnPurchase",
      title: "updatePurchasePriceOnPurchase",
      localStorage: "updatePurchasePrice",
      state: updatePurchasePrice,
      setState: setUpdatePurchasePrice,
    },
    {
      id: "useOldSelect",
      title: "useOldSelect",
      localStorage: "useOldSelect",
      state: useOldSelect,
      setState: setUseOldSelect,
    },
    {
      id: "showCreatedAt",
      title: "showCreatedAt",
      localStorage: "showCreatedAt",
      state: showCreatedAt,
      setState: setShowCreatedAt,
    },
    {
      id: "showPurchasePriceInList",
      title: "purchasePrice",
      localStorage: "showPurchasePriceInList",
      state: showPurchasePriceInList,
      setState: setShowPurchasePriceInList,
    },
    {
      id: "showLastUser",
      title: "lastUser",
      localStorage: "showLastUser",
      state: showLastUser,
      setState: setShowLastUser,
    },

    {
      id: "showDriver",
      title: "showDriver",
      localStorage: "showDriver",
      state: showDriver,
      setState: setShowDriver,
    },
    {
      id: "showNote",
      title: "showNote",
      localStorage: "showNote",
      state: showNote,
      setState: setShowNote,
    },
    {
      id: "ShowInvoiceNumber",
      title: "showInvoiceNumber",
      localStorage: "showInvoiceNumber",
      state: showInvoiceNumber,
      setState: setShowInvoiceNumber,
    },
    {
      id: "ShowTotalPrice",
      title: "showTotalPrice",
      localStorage: "showTotalPrice",
      state: showTotalPrice,
      setState: setShowTotalPrice,
    },
  ];

  return (
    <div style={{ textAlign: "start" }} className="p-2">
      <Card border="dark">
        <Card.Header>{translate("resources.root.general")}</Card.Header>
        <Card.Body>
          <div className="d-flex flex-wrap justify-content-between align-itmes-center">
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label
                htmlFor="dollarPrice"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.dollarPrice")} :
              </Form.Label>
              <Form.Control
                id={`dollarPrice`}
                label="Price"
                value={dollarPrice}
                onChange={(e) => {
                  setDollarPrice(e.target.value);
                  setDeactivated(false);
                }}
              />
              <Button
                block
                variant="primary"
                className="mx-2"
                onClick={() => {
                  setDeactivated(true);
                  HTTP.put(`${url}/DbPrefenses/1`, {
                    id: 1,
                    mcLabel: localStorage.getItem("mcLabel", "Dollar"),
                    scLabel: localStorage.getItem("scLabel", "Dinar"),
                    mcSymbol: localStorage.getItem("mcSymbol", "USD"),
                    scSymbol: localStorage.getItem("scSymbol", " IQD"),
                    mcToScPrice: dollarPrice,
                    storeId: localStorage.getItem("storeId") ?? 1,
                  })
                    .then(() => {
                      notify("resources.root.updated", "success");
                    })
                    .catch((err) =>
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      })
                    );
                  localStorage.setItem("dollarPrice", dollarPrice);
                }}
              >
                {translate("ra.action.save")}
              </Button>
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label
                htmlFor="formHorizontalEmail"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.vouchersFontSize")} :
              </Form.Label>
              <Form.Control
                id={`formHorizontalEmail`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("depositVoucherFont", e.target.value);
                }}
                defaultValue={localStorage.getItem("depositVoucherFont") ?? 14}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label
                htmlFor="systemFontSize"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.systemFontSize")} :
              </Form.Label>
              <Form.Control
                id="systemFontSize"
                as={"select"}
                onChange={(e) => {
                  localStorage.setItem("systemFontSize", e.target.value);
                  window.location.reload();
                }}
                defaultValue={localStorage.getItem("systemFontSize") ?? 14}
              >
                <option value={`12`}>12</option>
                <option value={`14`}>14</option>
                <option value={`15`}>15</option>
                <option value={`16`}>16</option>
                <option value={`17`}>17</option>
                <option value={`18`}>18</option>
              </Form.Control>
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label htmlFor="sotres" className="m-0 mr-2 text-nowrap">
                {translate("resources.root.store")} :
              </Form.Label>
              <Form.Control
                id="sotres"
                as={"select"}
                onChange={(e) => {
                  localStorage.setItem("storeId", e.target.value);
                }}
                defaultValue={localStorage.getItem("storeId") ?? 1}
              >
                <option value={0}>
                  {translate("resources.root.allStores")}
                </option>
                {stores.map((store) => {
                  return (
                    <option
                      selected={
                        parseInt(localStorage.getItem("storeId")) === store.id
                      }
                      value={store.id}
                    >
                      {store.title}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-5">
            {buttons.map((button, index) => {
              return (
                <div className="p-2" key={index}>
                  <Button
                    className={`btn-block`}
                    onClick={() => window.open(`#/${button.window}`, "_self")}
                  >
                    {translate(`resources.root.${button.title}`)}
                  </Button>
                </div>
              );
            })}
          </div>
        </Card.Body>
      </Card>

      <Card border="dark">
        <Card.Header>{translate("resources.root.sells")}</Card.Header>
        <Card.Body>
          <div className="row row-cols-1 row-cols-lg-3">
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.defaultProductsInPos")}&nbsp;:&nbsp;
              </Form.Label>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                isClearable
                isSearchable
                className="basic-multi-select w-100"
                classNamePrefix="select"
                options={products}
                defaultValue={JSON.parse(
                  localStorage.getItem("defaultProducts")
                )}
                onChange={(e) => {
                  localStorage.setItem("defaultProducts", JSON.stringify(e));
                }}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.selectDefaultCustomer")}&nbsp;:&nbsp;
              </Form.Label>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                id={`selectDefaultCustomer`}
                isClearable
                isSearchable
                className="basic-multi-select w-100"
                classNamePrefix="select"
                options={customers}
                defaultValue={customers.find(
                  (customer) =>
                    customer.value ===
                    parseInt(localStorage.getItem("defaultCustomer"))
                )}
                onChange={(e) => {
                  localStorage.setItem("defaultCustomer", e.value);
                }}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-0  border-bottom border-2 p-2 rounded "
            >
              <Form.Label
                htmlFor="formHorizontalEmail"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.selectDefaultCategory")}&nbsp;:&nbsp;
              </Form.Label>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                id={`selectDefaultCategory`}
                isClearable
                isSearchable
                className="basic-multi-select w-100"
                classNamePrefix="select"
                options={categories}
                defaultValue={categories.find(
                  (category) =>
                    category.value ===
                    parseInt(localStorage.getItem("defaultCategory"))
                )}
                onChange={(e) => {
                  localStorage.setItem("defaultCategory", e.value);
                }}
              />
            </Form.Group>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
            {permissonsSwitch.map((permiss) => {
              return (
                <InputGroup
                  is={permiss.id}
                  key={permiss.id}
                  className="mx-md-5 mx-lg-0 p-2"
                >
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => {
                          localStorage.setItem(
                            `${permiss.localStorage}`,
                            e.target.checked
                          );
                          permiss.setState(e.target.checked);
                        }}
                        defaultChecked={permiss.state}
                        name="gilad"
                      />
                    }
                    label={translate(`resources.root.${permiss.title}`)}
                  />
                </InputGroup>
              );
            })}
          </div>
        </Card.Body>
      </Card>

      <Card border="dark">
        <Card.Header>{translate("resources.root.product")}</Card.Header>
        <Card.Body>
          <div className="row row-cols-1 row-cols-lg-4">
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-2 rounded "
            >
              <Form.Label
                htmlFor="productMeasure"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.productMeasure")} &nbsp;:&nbsp;
              </Form.Label>
              <Form.Control
                id={`productMeasure`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("productMeasure", e.target.value);
                }}
                defaultValue={localStorage.getItem("productMeasure") ?? "Kg"}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-2 rounded "
            >
              <Form.Label htmlFor="oneContain" className="m-0 mr-2 text-nowrap">
                {translate("resources.root.productOneContains")} :
              </Form.Label>
              <Form.Control
                id={`oneContain`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("productOneContains", e.target.value);
                }}
                defaultValue={localStorage.getItem("productOneContains") ?? 1}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-2 rounded "
            >
              <Form.Label htmlFor="oneMeasure" className="m-0 mr-2 text-nowrap">
                {translate("resources.root.productOneMeasure")} :
              </Form.Label>
              <Form.Control
                id={`oneMeasure`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("productOneMeasure", e.target.value);
                }}
                defaultValue={localStorage.getItem("productOneMeasure") ?? "Kg"}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-2 rounded "
            >
              <Form.Label
                htmlFor="productWeight"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.productWeightInKg")} :
              </Form.Label>
              <Form.Control
                id={`productWeight`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("productWeightInKg", e.target.value);
                }}
                defaultValue={localStorage.getItem("productWeightInKg") ?? 1}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
          </div>
        </Card.Body>
      </Card>

      <Card border="dark">
        <Card.Header>{translate("resources.root.dashboard")}</Card.Header>
        <Card.Body>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            {commands.map((command) => {
              return (
                <InputGroup id={command.label} className="mx-md-5 mx-lg-0 p-2">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => handleQuickCommands(e, command)}
                        defaultChecked={command.checked}
                        name="gilad"
                      />
                    }
                    label={translate("resources.root." + command.label)}
                  />
                </InputGroup>
              );
            })}
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <InputGroup
              id="ignoreZeroDashboard"
              className="mx-md-5 mx-lg-0 p-2"
            >
              <FormControlLabel
                className="m-0 text-nowrap "
                control={
                  <Switch
                    onChange={(e) =>
                      localStorage.setItem(
                        "ignoreZeroDashboard",
                        e.target.checked
                      )
                    }
                    defaultChecked={ignoreZeroDashboard}
                    name="gilad"
                  />
                }
                label={translate("resources.root.ignoreZeroDashboard")}
              />
            </InputGroup>

            <InputGroup
              id="ignoreBelowZeroProductsDashboard"
              className="mx-md-5 mx-lg-0 p-2 "
            >
              <FormControlLabel
                className="m-0 text-nowrap "
                control={
                  <Switch
                    onChange={(e) =>
                      localStorage.setItem(
                        "ignoreBelowZeroProductsDashboard",
                        e.target.checked
                      )
                    }
                    defaultChecked={ignoreBelowZeroProductsDashboard}
                    name="gilad"
                  />
                }
                label={translate(
                  "resources.root.ignoreBelowZeroProductsDashboard"
                )}
              />
            </InputGroup>

            <Form.Group
              controlId="startDate"
              className="d-flex align-items-center m-0 p-2 rounded "
            >
              <Form.Label
                htmlFor="expireDaysDashboard"
                className="m-0 mr-2 text-nowrap"
              >
                {translate("resources.root.expireDaysDashboard")} :
              </Form.Label>
              <Form.Control
                id={`expireDaysDashboard`}
                type="text"
                onChange={(e) => {
                  localStorage.setItem("expireDaysDashboard", e.target.value);
                }}
                defaultValue={localStorage.getItem("expireDaysDashboard") ?? 30}
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Settings;
