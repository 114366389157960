import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    let selectedCustomer = ``;
    if (customer != 0) {
      selectedCustomer = `&subLevelId=${customer}`;
    } else {
      selectedCustomer = ``;
    }
    await HTTP.get(
      `${url}/SellInvoices?_start=${start}&_end=${end}${sendDates}${selectedCustomer}&nonPaid=${true}`
    )
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
        setMcTotalPrice(response.headers["mctotalprice"]);
        setScTotalPrice(response.headers["sctotalprice"]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Customers?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      newArray.push({
        value: 0,
        label: "none",
        customer: "none",
      });
      response.data.map((customer) => {
        newArray.push({
          value: customer.id,
          label: `${customer.firstName} ${
            customer.middleName
          } (${customer.phone.replace("+964", "0")})`,
          customer: customer,
        });
      });
      callback(newArray);
    }
  };

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div className="">
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.nonPaidSellInvoice")}
            </h1>
            <div className={`clearfix`}></div>
            <NoPrint>
              <Row className="row-cols-1">
                {isLoading && <LoadingScreen />}
                <Col className="row">
                  <Col className="col-12 col-lg-4 mb-3 d-flex justify-content-start align-items-center g-4">
                    <p className="m-0 px-2">
                      {translate("resources.root.customers")} :
                    </p>

                    <AsyncSelect
                      className="w-75"
                      defaultOptions
                      loadOptions={loadOptions}
                      onChange={(e) => {
                        setCustomer(e ? e.value : 0);
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-lg-4 mb-3 px-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-lg-4 mb-3 px-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Col>
                <Col className="row mb-3">
                  <Col className="col-4 d-none d-md-block px-3">
                    <Button
                      className="btn-block"
                      variant={"outline-primary"}
                      onClick={(e) => {
                        let sidebar =
                          document.getElementsByClassName("MuiDrawer-root");
                        let appMenu =
                          document.getElementsByClassName("MuiPaper-root");
                        let appBar = document.getElementsByClassName("theRoot");
                        let table = document.getElementsByTagName("table");
                        sidebar[0].style.display = "none";
                        appMenu[0].style.display = "none";
                        appBar[0].style.marginTop = "0px";
                        table[0].style.fontSize = "12px";
                        window.print();
                        sidebar[0].style.display = "block";
                        appMenu[0].style.display = "block";
                        appBar[0].style.marginTop = "40px";
                        table[0].style.fontSize = "15px";
                      }}
                    >
                      <FaPrint /> {translate("resources.root.print")}
                    </Button>
                  </Col>
                  <Col className="col-4 d-none d-md-block px-3">
                    <Button
                      className="btn-block"
                      id="export"
                      onClick={() => {
                        toCsv(
                          document.getElementById("exportableTable"),
                          "download.csv"
                        );
                      }}
                    >
                      {translate("resources.root.exportCsv")}
                    </Button>
                  </Col>{" "}
                  <Col className="col-12 col-md-4 px-3">
                    <Button
                      className="btn-block"
                      variant={"outline-primary"}
                      onClick={(e) => {
                        getData();
                      }}
                    >
                      {translate("resources.root.search")}
                    </Button>
                  </Col>
                </Col>
              </Row>
            </NoPrint>
          </div>
          {data.length > 0 && (
            <Row>
              <Col className="px-3 mt-2">
                {" "}
                <Table bordered hover className="">
                  <thead>
                    <tr>
                      <th>{translate("resources.root.totalPrice")}</th>
                      <th>{translate("resources.root.totalPrice")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>$ {parseFloat(mcTotalPrice)?.toLocaleString()}</td>
                      <td>{parseFloat(scTotalPrice)?.toLocaleString()} IQD</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <Row className={`mt-2 px-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.finalQuantity")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.accountant")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>{one.id}</td>
                          <td>
                            <a
                              href={`#/SellInvoices/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.invoiceNumber &&
                                one.invoiceNumber.toUpperCase()}
                            </a>
                          </td>
                          <td>{`${one.customerName}`}</td>
                          <td>
                            {one.payByMain
                              ? `$${one.mcFinalPrice?.toLocaleString()}`
                              : `${one.scFinalPrice?.toLocaleString()} IQD`}
                          </td>

                          <td>{one.totalQte}</td>
                          <td>
                            {(
                              one.totalQte + one.totalGiftQte
                            )?.toLocaleString()}
                          </td>
                          <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                          <td>{one.lastUser}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
