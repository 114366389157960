import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  NumberField,
  useTranslate,
} from "react-admin";
import * as React from "react";
import { MyCustomPagination, SearchFilter } from "../../App";
import { ListActions } from "../templates/ListActions";

export const QuickSellReturnInvoicesList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");
  return (
    <List
      {...props}
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={false}>
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <FunctionField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
            render={(record) => (
              <a
                href={`#/Cashier/SellInfo/${record.id}/show/return`}
                target={`_blank`}
              >
                {record.invoiceNumber}
              </a>
            )}
          />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {currency === "default" && (
          <FunctionField
            label="resources.root.totalPrice"
            render={(record) =>
              record.payByMain
                ? `$${record.mcTotalPrice?.toLocaleString()}`
                : `${record.scTotalPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.totalPrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcTotalPrice?.toLocaleString()}`
                : `${record.scTotalPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.discount"
            render={(record) =>
              record.payByMain
                ? `$${record.mcInvoiceDiscount?.toLocaleString()}`
                : `${record.scInvoiceDiscount?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.discount"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcInvoiceDiscount?.toLocaleString()}`
                : `${record.scInvoiceDiscount?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <NumberField source="totalQte" label="resources.root.totalQte" />
        <NumberField
          source="totalSmallMeasureQte"
          label="resources.root.smallMeasureQty"
        />
        {currency === "default" && (
          <FunctionField
            label="resources.root.cashPrice"
            render={(record) =>
              record.payByMain
                ? `$${record.mcCashPrice?.toLocaleString()}`
                : `${record.scCashPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.cashPrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCashPrice?.toLocaleString()}`
                : `${record.scCashPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.returnPrice"
            render={(record) =>
              record.payByMain
                ? `$${record.mcReturnPrice?.toLocaleString()}`
                : `${record.scReturnPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.returnPrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcReturnPrice?.toLocaleString()}`
                : `${record.scReturnPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
      </Datagrid>
    </List>
  );
};
