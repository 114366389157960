import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  useTranslate,
  EditButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { ListActions } from "../templates/ListActions";

export const LabTestCategoriesList = (props) => {
  const translate = useTranslate();
  return (
    <List
      actions={<ListActions hasCreate="labtestcategory.list" />}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="labtestcategory.list" />
        }
      >
        <TextField source="id" label={translate("resources.root.id")} />
        <TextField source="name" label={translate("resources.root.name")} />
        <TextField
          source="description"
          label={translate("resources.root.description")}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const LabTestCategoriesCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" label={translate("resources.root.name")} />
        <TextInput
          source="description"
          label={translate("resources.root.description")}
        />
      </SimpleForm>
    </Create>
  );
};

export const LabTestCategoriesEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate("resources.root.name")}
          margin="normal"
          variant="outlined"
        />
        <TextInput
          source="description"
          label={translate("resources.root.description")}
          margin="normal"
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};
