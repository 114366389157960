import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import moment from "moment";
import { HTTP } from "../../axios";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toCsv } from "../CustomComponents/Helpers";
import _ from "lodash";

const SellPricePerQtyReport = () => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isByMain, setIsByMain] = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  async function getData() {
    setIsLoading(true);
    let sendDates = ``;
    if (startDate && startDate.length > 0)
      sendDates += `startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    await HTTP.get(
      `${url}/Reports/GetAllClientReportPerSellPriceQuantity?${sendDates}`
    )
      .then((response) => {
        const sortedData = _.orderBy(
          response.data,
          (item) => {
            const { totalQte, mcTotalSellPrice, mcCurrentLoan } = item;
            return mcCurrentLoan === 0
              ? 0
              : (totalQte * mcTotalSellPrice) / mcCurrentLoan;
          },
          ["desc"]
        );
        setData(sortedData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  return (
    <div>
      <PrintProvider>
        <Print single name={`ordersReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary my-3 text-center d-inline-block">
              {translate("ra.navigation.SellPricePerQtyReport")}
            </h1>
          </div>
          <NoPrint force>
            <Row className="justify-content-between align-items-center">
              {isLoading && <LoadingScreen />}
              <Col className="col-12 col-md-10  mt-4">
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>{" "}
              <Col className="col-12 col-md-2 p-0 mt-4 mb-3">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => {
                          setIsByMain(e.target.checked);
                        }}
                        name="gilad"
                      />
                    }
                    label={translate(
                      `resources.root.${isByMain ? "dollar" : "dinar"}`
                    )}
                  />
                </InputGroup>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>{" "}
            </Row>
          </NoPrint>

          <Row className="m-2 mt-4">
            <Col className=" col-12">
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.currentLoan")}</th>
                    <th>{translate("resources.root.customerPayment")}</th>
                    <th>{translate("resources.root.finalPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data?.map((one) => {
                      return (
                        <tr>
                          <td>{one.fullName}</td>
                          <td>{one.totalQte}</td>
                          {isByMain ? (
                            <>
                              <td>{`$ ${one.mcTotalSellPrice?.toLocaleString()}`}</td>
                              <td>{`$ ${one.mcCurrentLoan?.toLocaleString()}`}</td>
                              <td>{`$ ${one.mcTotalPayment?.toLocaleString()}`}</td>
                              <td>{`${
                                one.mcCurrentLoan !== 0
                                  ? (one.totalQte * one.mcTotalSellPrice) /
                                    one.mcCurrentLoan
                                  : 0
                              }`}</td>
                            </>
                          ) : (
                            <>
                              <td>{`${one.scTotalSellPrice?.toLocaleString()} IQD`}</td>
                              <td>{`${one.scCurrentLoan?.toLocaleString()} IQD`}</td>
                              <td>{`${one.scTotalPayment?.toLocaleString()} IQD`}</td>
                              <td>{`${
                                one.scCurrentLoan !== 0
                                  ? (one.totalQte * one.scTotalSellPrice) /
                                    one.scCurrentLoan
                                  : 0
                              } `}</td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {/* <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint> */}
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};

export default SellPricePerQtyReport;
