import * as React from "react";
import { useRedirect, useTranslate, EditButton } from "react-admin";
import { Table } from "react-bootstrap";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import { HTTP } from "../../axios";
import Page from "../CustomPages/SellReceipt/Page";
import Button from "@mui/material/Button";
import { NoPrint, Print, PrintProvider } from "react-easy-print";
import { makeStyles } from "@mui/styles";
import { useLocation, useParams } from "react-router-dom";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const classes = useStyles();

  const [sell, setSell] = React.useState(null);
  const [orders, setOrders] = React.useState([]);
  const tt = useParams();
  const location = useLocation();
  const fetchData = async (props) => {
    let isReturn = "";
    if (location.pathname.match("return")) {
      isReturn = "Return";
    }
    await HTTP.get(`${url}/QuickSell${isReturn}Invoices/${tt.id}`)
      .then((response) => {
        setSell(response.data);
        setOrders(
          isReturn === "Return"
            ? response.data.quickSellReturnOrders
            : response.data.quickSellOrders
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
  };
  React.useEffect(() => {
    fetchData();
  }, [tt.id]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name={"cashier"}>
          <div className={classes.printWrapper}>
            {sell && orders && (
              <Page
                invoice={sell}
                order={orders}
                title={translate("resources.root.cashierSells")}
                human={sell.name}
              />
            )}
          </div>
        </Print>
        <NoPrint force>
          <Button
            variant="outlined"
            onClick={() => {
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
          <Table bordered striped>
            <tbody>
              <tr>
                <th>{translate("resources.root.invoiceNumber")}</th>
                <td>{sell && sell.invoiceNumber}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.date")}</th>
                <td>
                  {sell && moment(sell.date).format("YYYY-MM-DD hh:mm:ss A")}
                </td>
              </tr>
              <tr>
                <th>{translate("resources.root.totalPrice")}</th>
                <td>{sell && sell.scTotalPrice?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.cashPrice")}</th>
                {sell && sell.mcCashPrice > 0 && (
                  <td>{sell.mcCashPrice?.toLocaleString()}</td>
                )}
                {sell && sell.scCashPrice > 0 && (
                  <td>{sell.scCashPrice?.toLocaleString()}</td>
                )}
              </tr>
              <tr>
                <th>{translate("resources.root.returnPrice")}</th>
                <td>{sell && sell.scReturnPrice?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.discount")}</th>
                <td>{sell && sell.scInvoiceDiscount?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.qty")}</th>
                <td>{sell && sell.totalQte?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.smallMeasureQty")}</th>
                <td>{sell && sell.totalSmallMeasureQte?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>{translate("resources.root.cashierName")}</th>
                <td>{sell && sell.casherName}</td>
              </tr>
            </tbody>
          </Table>
          <Table id={`change`} bordered hover>
            <thead>
              <tr>
                <th>{translate("resources.root.productName")}</th>
                <th>{translate("resources.root.barcode")}</th>
                <th>
                  {translate("resources.root.qty")} +{" "}
                  {translate("resources.root.gift")}
                </th>
                <th>
                  {translate("resources.root.smallMeasureQty")} +{" "}
                  {translate("resources.root.smallMeasureGift")}
                </th>
                <th>{translate("resources.root.price")}</th>
                <th>{translate("resources.root.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((order) => {
                  return (
                    <tr key={order.id}>
                      <td>{order.product.name}</td>
                      <td>{order.product.barcode}</td>
                      <td>
                        {(order.qte ?? "0") + " + " + (order.giftQte ?? "0")}
                      </td>
                      <td>
                        {(order.smallMeasureQte ?? "0") +
                          " + " +
                          (order.smallMeasureGiftQte ?? "0")}
                      </td>
                      <td>{order.scSellPrice?.toLocaleString()}</td>
                      <td>{order.scLastPrice?.toLocaleString()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
