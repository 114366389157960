import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  FunctionField,
  TextInput,
  Edit,
  ShowButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import { ListActions } from "../templates/ListActions";
export const WithdrawsTypesList = (props) => (
  <List
    actions={<ListActions hasCreate="setting" />}
    filters={<SearchFilter />}
    pagination={<MyCustomPagination />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={<BulkAction {...props} hasDelete="setting" />}>
      <TextField source="id" label={"resources.root.id"} />
      <TextField source="label" label="resources.root.label" />
      {localStorage.getItem("showNote") === "true" && (
        <TextField source="note" label="resources.root.note" />
      )}
      {localStorage.getItem("showLastUser") === "true" && (
        <TextField source="lastUser" label="resources.root.lastUser" />
      )}
      {localStorage.getItem("showCreatedAt") === "true" && (
        <FunctionField
          sortBy={"createdAt"}
          label="resources.root.createdAt"
          render={(record) =>
            record.createdAt
              ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
              : "-"
          }
        />
      )}
      <ShowButton />
    </Datagrid>
  </List>
);

export const WithdrawsTypesCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="label" label="resources.root.label" />
      <TextInput
        source="note"
        label="resources.root.label"
        options={{ multiLine: true }}
      />
    </SimpleForm>
  </Create>
);

export const WithdrawsTypesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="label" label="resources.root.label" />
      <TextInput
        source="note"
        label="resources.root.note"
        options={{ multiLine: true }}
      />
    </SimpleForm>
  </Edit>
);
