import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateInput,
  TextInput,
  BulkDeleteButton,
  EditButton,
  Filter,
  FunctionField,
  useRedirect,
  Show,
  TabbedShowLayout,
  Tab,
  useNotify,
  Create,
  ShowButton,
  DateField,
  BooleanField,
  useRefresh,
  FormTab,
  TabbedForm,
  useTranslate,
  ReferenceField,
  useResourceDefinition,
} from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import moment from "moment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import rtl from "jss-rtl";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import PrintIcon from "@mui/icons-material/Print";
import { McRepresentativeInvoice } from "./McRepresentativeInvoice";
import { ScRepresentativeInvoice } from "./ScRepresentativeInvoice";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useParams } from "react-router-dom";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            let ids = "";
            for (let i = 0; i < props.selectedIds.length; i++) {
              if (i === 0) {
                ids += "?ids[]=" + props.selectedIds[i];
              } else {
                ids += "&ids[]=" + props.selectedIds[i];
              }
            }
            window
              .open(
                `#/PrintInvoiceOrders/RepresentativeInvoice${ids}`,
                "_blank"
              )
              .focus();
          }}
        >
          <PrintIcon /> &nbsp; {translate("resources.root.printOrders")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: `${translate("resources.root.newDriver")}`,
              content: "input",
              button: {
                text: `${translate("resources.root.update")}`,
                closeModal: false,
              },
            }).then((name) => {
              if (name) {
                HTTP.post(
                  `${url}/SellInvoices/BatchUpdateDriver?driver=${name}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal("Empty driver name is not accepted!", "", "error");
              }
            });
          }}
        >
          <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
          {translate("resources.root.updateDriver")}
        </Button>
        <BulkAction {...props} />
      </div>
    </React.Fragment>
  );
};

export const RepresentativeInvoicesList = () => {
  const props = useResourceDefinition();
  const [currency, setCurrency] = React.useState("default");
  const [orders, setOrders] = React.useState([]);

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <NoPrint force>
          <List
            filters={<SearchFilter driver={true} date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions
                setCurrency={setCurrency}
                currency={currency}
                hasCreate="representative.create"
              />
            }
          >
            <Datagrid
              bulkActionButtons={
                <BulkActionButtons
                  setOrders={setOrders}
                  hasDelete="representative.delete"
                />
              }
            >
              <TextField source="id" />
              <ReferenceField
                label={translate("resources.root.Representative")}
                source="representativeId"
                reference="Representatives"
                link="show"
              >
                <FunctionField
                  label={translate("resources.root.name")}
                  render={(record) =>
                    `${record.firstName} ${record.middleName}`
                  }
                />
              </ReferenceField>{" "}
              {localStorage.getItem("showInvoiceNumber") === "true" && (
                <TextField
                  source="invoiceNumber"
                  label="resources.root.invoiceNumber"
                />
              )}
              {/*<FunctionField*/}
              {/*  label="resources.root.name"*/}
              {/*  render={(record) =>*/}
              {/*    `${record.representative.firstName} ${record.representative.middleName}`*/}
              {/*  }*/}
              {/*/>*/}
              {currency === "default" && (
                <FunctionField
                  label="resources.root.price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    record.payByMain
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              {currency !== "default" && (
                <FunctionField
                  label="Price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    currency === "dollar"
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              <TextField source="totalQte" label="resources.root.totalQty" />
              <FunctionField
                label="resources.root.finalQuantity"
                render={(record) =>
                  (record.totalQte + record.totalGiftQte)?.toLocaleString()
                }
              />
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
              />
              <BooleanField source="isCash" label="resources.root.isCash" />
              {localStorage.getItem("showNote") === "true" && (
                <TextField source="note" label="resources.root.note" />
              )}
              {localStorage.getItem("showDriver") === "true" && (
                <TextField source="driver" label="resources.root.driver" />
              )}
              {localStorage.getItem("showLastUser") === "true" && (
                <TextField source="lastUser" label="resources.root.lastUser" />
              )}
              {localStorage.getItem("showCreatedAt") === "true" && (
                <FunctionField
                  sortBy={"createdAt"}
                  label="resources.root.createdAt"
                  render={(record) =>
                    record.createdAt
                      ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                      : "-"
                  }
                />
              )}
              <ShowButton />
            </Datagrid>
          </List>
        </NoPrint>
        <div>
          <Print single={true} name="foo">
            <div className={classes.ordersPrintWrapper}>
              <div style={{ marginBottom: 20 }}>
                {translate("resources.root.date")}:{" "}
                {moment().format("DD-MM-YYYY")}
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">
                        {translate("resources.root.name")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.barcode")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.quantity")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.giftQuantity")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.driver")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order, key) => (
                      <TableRow key={order.name}>
                        <TableCell component="th" scope="row">
                          {key + 1}
                        </TableCell>
                        <TableCell align="right">{order.name}</TableCell>
                        <TableCell align="right">{order.barcode}</TableCell>
                        <TableCell align="right">{order.qty}</TableCell>
                        <TableCell align="right">{order.giftQty}</TableCell>
                        <TableCell align="right">{order.driver}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Print>
        </div>
      </PrintProvider>
    </div>
  );
};

export const RepresentativeInvoicesCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="resources.root.dinnarSell">
        <ScRepresentativeInvoice />
      </FormTab>
      <FormTab label="resources.root.dollarSell">
        <McRepresentativeInvoice />
      </FormTab>
    </TabbedForm>
  </Create>
);

export const RepresentativeInvoicesEdit = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const [sellInvoices, setSellInvoices] = React.useState([]);
  const [sellOrders, setSellOrders] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [payByMain, setPayByMain] = React.useState(true);
  const [invoiceNumber, setInvoiceNumber] = React.useState();
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("orders")
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [productDiscount, setProductDiscount] = React.useState(0);
  const [gift, setGift] = React.useState(0);
  const [productNote, setProductNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [previousSellPrice, setPreviousSellPrice] = React.useState(null);

  React.useEffect(() => {
    HTTP.get(`${url}/SellInvoices/${id}`).then((res) => {
      setSellInvoices(res.data);
      setSellOrders(res.data.sellOrders);
    });
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      sellInvoices &&
      HTTP.get(
        `${url}/SellInvoices/GetLastPriceOfProduct?customerId=${sellInvoices.customerId}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousSellPrice(res.data);
      });
  }, [sellInvoices, selectedProduct]);

  React.useEffect(() => {
    localStorage.removeItem("orders");
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPrice);
  }, [modifiedPrice]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.invoice}>
          <div className={classes.invoiceCols}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container justify="space-around">
                <DateTimePicker
                  className={classes.invoiceInputs}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="resources.root.date"
                  value={selectedDate}
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                  // onChange={(data) => setSelectedDate(data)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
            <MaterialTextField
              label="resources.root.invoiceNo"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              className={classes.invoiceNumberInput}
            />
          </div>

          <div className={classes.invoiceCols}>
            <Chip
              icon={<AssignmentIndIcon />}
              label={
                sellInvoices.customer
                  ? `${sellInvoices.customer.firstName} ${sellInvoices.customer.middleName}`
                  : `${translate("resources.root.name")}`
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              icon={<PhoneIphoneIcon />}
              label={
                sellInvoices.customer
                  ? sellInvoices.customer.phone
                  : `${translate("resources.root.phone")}`
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              label={
                sellInvoices.customer
                  ? `${translate("resources.root.currentLoan")} ${
                      sellInvoices.customer.mcCurrentLoan
                    } ${
                      sellInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : ` ${translate("resources.root.iqd")}`
                    }`
                  : translate("resources.root.currentLoan")
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              // icon={<LocalAtmIcon />}
              label={
                sellInvoices.customer
                  ? `${translate("resources.root.maxLoan")} ${
                      sellInvoices.customer.mcRoofLoan
                    } ${
                      sellInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : ` ${translate("resources.root.iqd")}`
                    }`
                  : translate("resources.root.maxLoan")
              }
              className={classes.bottomLeftMargin}
            />
          </div>
        </div>
        <div className={classes.invoice}>
          <div className={classes.toggles}>
            {sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={sellInvoices.payByMain}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  sellInvoices.payByMain
                    ? translate("resources.root.dollar")
                    : ` ${translate("resources.root.dinar")}`
                }
              />
            )}
            {sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={true} name="checkedB" color="primary" />
                }
                label="resources.root.cash"
              />
            )}
            {!sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={false} name="checkedB" color="primary" />
                }
                label="resources.root.cash"
              />
            )}
          </div>
        </div>
        <div className={classes.orders}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.orderCols}>
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.productMeasure")} - ${
                            selectedProduct.productMeasure
                          }`
                        : `${translate("resources.root.productMeasure")}`
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneMeasure")} - ${
                            selectedProduct.oneMeasure
                          }`
                        : `${translate("resources.root.oneMeasure")}`
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneContains")} ${
                            selectedProduct.oneContains
                          } ${translate("resources.root.items")}`
                        : `${translate("resources.root.oneContains")}`
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.totalPrice")} - $${
                            selectedProduct.mcSellPrice * orderQtt
                          }`
                        : `${translate("resources.root.totalPrice")}`
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {previousSellPrice && (
                    <Chip
                      label={
                        selectedProduct && selectedProduct.sellByMain
                          ? `${previousSellPrice.mcSellPrice?.toLocaleString()} ${translate(
                              "resources.root.usd"
                            )}`
                          : `${previousSellPrice.scSellPrice?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      style={{
                        backgroundColor: "lightgreen",
                        color: "black",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </div>

                <div className={classes.orderCols}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={products}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.barcode}`
                    }
                    style={{ width: 300 }}
                    onChange={(el, val) => {
                      setSelectedProduct(val);
                      setOrderQtt(0);
                    }}
                    renderInput={(params) => (
                      <MaterialTextField
                        {...params}
                        label="resources.root.chooseProduct"
                        variant="outlined"
                      />
                    )}
                  />
                  <MaterialTextField
                    type="number"
                    label="resources.root.quantity"
                    disabled={!selectedProduct}
                    value={orderQtt}
                    onChange={(e) => {
                      ((parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        gift <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                        e.target.value >= 0) ||
                        selectedProduct.canSellBellowZero) &&
                        setOrderQtt(e.target.value);
                    }}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    label="Max"
                    disabled={true}
                    value={selectedProduct ? selectedProduct.qteInStock : 0}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label="resources.root.price"
                    disabled={!selectedProduct}
                    value={
                      modifiedPrice <= 0
                        ? selectedProduct
                          ? selectedProduct.mcSellPrice
                          : 0
                        : modifiedPrice
                    }
                    onChange={(e) =>
                      setModifiedPrice(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label="resources.root.gifts"
                    disabled={!selectedProduct}
                    value={gift}
                    onChange={(e) =>
                      parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        parseFloat(orderQtt) <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                      e.target.value >= 0 &&
                      setGift(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <Button
                    variant="contained"
                    className={classes.botton}
                    disabled={orderQtt <= 0 || !selectedProduct}
                    onClick={() => {
                      const sc = localStorage.getItem("dollarPrice");
                      HTTP.post(`${url}/SellOrders`, {
                        sellInvoiceId: sellInvoices.id,
                        productId: selectedProduct.id,
                        mcPurchasePrice: selectedProduct.mcPurchasePrice,
                        scPurchasePrice: selectedProduct.scPurchasePrice,
                        productMeasure: selectedProduct.productMeasure,
                        oneContains: selectedProduct.oneContains,
                        oneMeasure: selectedProduct.oneMeasure,
                        mcDiscount: discount,
                        scDiscount: discount * sc,
                        giftQte: gift,
                        mcLastPrice: selectedProduct.mcSellPrice - discount,
                        scLastPrice:
                          selectedProduct.scSellPrice - discount * sc,
                        payByMain: payByMain,
                        barcode: selectedProduct.barcode,
                        name: selectedProduct.name,
                        qte: orderQtt,
                        mcSellPrice: selectedProduct.mcSellPrice,
                        scSellPrice: selectedProduct.scSellPrice,
                        mcTotalPrice: selectedProduct.mcSellPrice * orderQtt,
                        scTotalPrice: selectedProduct.scSellPrice * orderQtt,
                      })
                        .then((res) => {
                          HTTP.get(`${url}/SellInvoices/${id}`).then((res) => {
                            setSellInvoices(res.data);
                            setSellOrders(res.data.sellOrders);
                          });
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                      setOrderQtt(0);
                      setOrderToEdit(-1);
                      setGift(0);
                    }}
                  >
                    Add Order
                  </Button>
                </div>

                <div className={classes.orderCols}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      stickyHeader
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {translate("resources.root.barcode")}Barcode
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.name")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.quantity")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.gifts")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.price")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.finalPrice")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.actions")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.scrollable}>
                        {sellOrders &&
                          sellOrders.map((order) => (
                            <TableRow key={order.product.barcode}>
                              <TableCell component="th" scope="row">
                                {order.product.barcode}
                              </TableCell>
                              <TableCell align="right">
                                {order.product.name}
                              </TableCell>
                              <TableCell align="right">{order.qte}</TableCell>
                              <TableCell align="right">
                                {order.giftQte}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(order.mcSellPrice)
                                  : roundToTwo(order.scSellPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(order.mcDiscount)
                                  : roundToTwo(order.scDiscount)}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(order.mcLastPrice)
                                  : roundToTwo(order.scLastPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {sellOrders.length > 1 && (
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: `${translate(
                                          "resources.root.areYouSure"
                                        )}`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `${url}/SellOrders/${order.id}`
                                          ).then((res) => {
                                            sellOrders((prev) => {
                                              return prev.filter(
                                                (el) =>
                                                  el.id != order.sellOrders.id
                                              );
                                            });
                                          });
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Chip
                    label={`Total Price - $${sellOrders
                      .map((el) => {
                        return (
                          ((cashPayment ? cashPayment : sellInvoices.payByMain)
                            ? parseFloat(el.mcSellPrice)
                            : parseFloat(el.scSellPrice)) * parseFloat(el.qte)
                        );
                      })
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`Final Price - $${
                      sellOrders
                        .map(
                          (el) =>
                            ((
                              cashPayment ? cashPayment : sellInvoices.payByMain
                            )
                              ? parseFloat(el.mcSellPrice)
                              : parseFloat(el.scSellPrice)) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0) - discount
                    }`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`${translate(
                      "resources.root.totalQuantity"
                    )} - $${sellOrders
                      .map((el) => parseFloat(el.qte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`${translate(
                      "resources.root.totalGifts"
                    )} - $${sellOrders
                      .map((el) => parseFloat(el.giftQte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`${translate(
                      "resources.root.finalQuantity"
                    )} - $${sellOrders
                      .map((el) => parseFloat(el.qte) + parseFloat(el.giftQte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <MaterialTextField
                    type="number"
                    className={classes.inPercentage}
                    label="resources.root.discount"
                    disabled={true}
                    value={
                      sellInvoices.payByMain
                        ? sellInvoices.mcDiscount
                        : sellInvoices.scDiscount
                    }
                    variant="filled"
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <=
                          orders
                            .map(
                              (el) =>
                                parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0) &&
                        setDiscount(e.target.value);
                      setPercentageDiscount(
                        parseFloat(
                          (discount * 100) /
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => roundToTwo(a + b), 0)
                        )
                      );
                    }}
                    // className={classes.discount}
                  />
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                </div>
                <div className={classes.orderCols}>
                  <MaterialTextField
                    label="resources.root.note"
                    value={sellInvoices.note}
                    disabled={true}
                    className={classes.noteInput}
                  />
                  <MaterialTextField
                    label="resources.root.driver"
                    value={sellInvoices.driver}
                    disabled={true}
                    className={classes.orderTextInput}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export const RepresentativeInvoicesShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const translate = useTranslate();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/RepresentativeInvoices/${id}`).then((res) => {
      setOrders(res.data.representativeOrders);
      setInvoice(res.data);
    });
  }, [props]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              title={translate("resources.root.sellInvoice")}
              invoice={invoice}
              human={invoice.representative}
              order={invoice.representativeOrders}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label="resources.root.invoice">
                <FunctionField
                  label="resources.root.name"
                  render={(record) =>
                    record.representative &&
                    `${record.representative.firstName} ${record.representative.middleName} ${record.representative.lastName}`
                  }
                />
                <TextField
                  source="totalQte"
                  label="resources.root.totalQuantity"
                />
                <TextField
                  source="totalGiftQte"
                  label="resources.root.giftQuantity"
                />
                <FunctionField
                  label="resources.root.finalQuantity"
                  render={(record) =>
                    `${record.totalQte + record.totalGiftQte}`
                  }
                />
                <TextField source="note" label="resources.root.note" />
                <TextField source="driver" label="resources.root.driver" />
                {invoice.payByMain ? (
                  <TextField
                    source="mcTotalPrice"
                    label="resources.root.totalPrice"
                  />
                ) : (
                  <TextField
                    source="scTotalPrice"
                    label="resources.root.totalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalPrice"
                    label="resources.root.finalPrice"
                  />
                ) : (
                  <TextField
                    source="scFinalPrice"
                    label="resources.root.finalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                ) : (
                  <TextField
                    source="scFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                )}
              </Tab>
              <Tab label="Orders">
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              {order.product.name}
                            </TableCell>
                            <TableCell align="right">{order.qte}</TableCell>
                            <TableCell align="right">{order.giftQte}</TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSellPrice
                                : order.scSellPrice}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice
                                : order.scLastPrice}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              window.print();
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
