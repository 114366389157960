import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  DateField,
  Show,
  ReferenceField,
  DateInput,
  NumberInput,
  FormDataConsumer,
  BooleanInput,
  ImageInput,
  ImageField,
  useDataProvider,
  SimpleShowLayout,
  FunctionField,
  AutocompleteInput,
  useTranslate,
  useResourceDefinition,
  ShowButton,
  minValue,
} from "react-admin";
import { path } from "../../request";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { url, handleRequestResponse } from "../../request";
import Button from "@mui/material/Button";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import moment from "moment";
import { CardActionArea, CardActions } from "@mui/material";
import { chain } from "lodash";
import { ListActions } from "../templates/ListActions";

export const UserStoreBankAccountTransfersList = (props) => {
  const translate = useTranslate();
  return (
    <List
      actions={
        <ListActions hasCreate={"userstorebankaccounttransfer.create"} />
      }
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        {/* <FunctionField
          label={`resources.root.attachment`}
          render={(record) => (
            <img
              style={{
                width: 75,
                height: 75,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`
              }
              alt={record.name}
              onClick={() =>
                window.open(
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`,
                  "_blank"
                )
              }
            />
          )}
        /> */}{" "}
        <FunctionField
          label="resources.root.fromCashier"
          render={(record) => record.fromUserStoreBankAccount.note}
        />
        <FunctionField
          label="resources.root.toCashier"
          render={(record) => record.toUserStoreBankAccount.note}
        />
        <FunctionField
          label="resources.root.price"
          sortBy={"mcAmount"}
          render={(record) =>
            record.mcAmount > 0
              ? "$" + record.mcAmount?.toLocaleString()
              : record.scAmount?.toLocaleString() +
                ` ${translate("resources.root.iqd")}`
          }
        />
        <TextField source="note" label="resources.root.note" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const UserStoreBankAccountTransfersShow = (props) => {
  const translate = useTranslate();
  const [transfer, setTransfer] = React.useState({});
  React.useEffect(() => {
    HTTP.get(`${url}/BankAccountTransfers/${props.id}`).then((response) => {
      setTransfer(response.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: transfer.invoiceNumber && transfer.invoiceNumber,
          serialNo: transfer.id && transfer.id,
          dollar: transfer.byMain && transfer.byMain,
          isDeposit: false,
          date: transfer.date && transfer.date,
          payedTo: transfer.fromBankAccount && transfer.fromBankAccount.name,
          amount:
            transfer.byMain && transfer.byMain
              ? transfer.mcAmount && transfer.mcAmount?.toLocaleString()
              : transfer.scAmount && transfer.scAmount?.toLocaleString(),
          type: "BankTransfer",
          note: transfer.note && transfer.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <TextField source="name" />
          <TextField source="name" />

          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.mcAmount > 0
                ? record.mcAmount?.toLocaleString()
                : record.scAmount?.toLocaleString()
            }
          />
          <TextField source="note" label="resources.root.note" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <FunctionField
            label="resources.root.attachment"
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          {/* <Button
            className={`btn btn-primary`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button> */}
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const UserStoreBankAccountTransfersCreate = () => {
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [choices, setChoices] = React.useState([]);
  const [fromCashier, setFromCashier] = React.useState(0);
  const [toCashier, setToCashier] = React.useState(0);
  const [sentPrice, setSentPrice] = React.useState(0);
  const [isByMain, setIsByMain] = React.useState(true);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/UserStoreBankAccounts`).then((res) => {
      setChoices(res.data);
    });
  }, [0]);

  // React.useEffect(() => {
  //   HTTP.get(`${url}/UserStoreBankAccounts/${selectedChoices.id}`).then(
  //     (res) => {
  //       setPermissions(res.data);
  //       setLoading(false);
  //     }
  //   );
  // }, [selectedChoices]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="fromUserStoreBankAccountId"
              perPage={500}
              label="resources.root.fromCashier"
              choices={choices}
              onChange={(e) => {
                setFromCashier(e);
              }}
              optionText={optionRenderer}
              optionValue="userStoreBankAccountId"
              validate={[required()]}
            />
            <AutocompleteInput
              source="toUserStoreBankAccountId"
              perPage={500}
              label="resources.root.toCashier"
              choices={choices}
              onChange={(e) => {
                setToCashier(e);
              }}
              optionText={optionRenderer}
              optionValue="userStoreBankAccountId"
              validate={[required()]}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                setCurrency(formData.byMain);
                return formData.byMain ? (
                  <NumberInput
                    source="mcAmount"
                    label="resources.root.dollar"
                    onChange={(e) => {
                      setSentPrice(e.target.value);
                    }}
                    validate={[required(), minValue(0)]}
                    defaultValue={0}
                  />
                ) : (
                  <NumberInput
                    source="scAmount"
                    label="resources.root.dinar"
                    onChange={(e) => {
                      setSentPrice(e.target.value);
                    }}
                    validate={[required(), minValue(0)]}
                    defaultValue={0}
                  />
                );
              }}
            </FormDataConsumer>
            <BooleanInput
              source="byMain"
              defaultValue={true}
              onChange={(e) => {
                setIsByMain(e.target.checked);
              }}
              label={
                currency ? `resources.root.dollar` : `resources.root.dinar`
              }
            />
            <TextInput source="note" label="resources.root.note" multiline />
            <DateInput
              source="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              label="resources.root.date"
            />
            <ImageInput
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
          <div className="row row-cols-1 row-cols-md-2 justify-content-center align-items-center ">
            {choices?.length > 0 &&
              choices?.map((cashier, index) => {
                return (
                  <div key={index} className="p-4">
                    <Card className="w-100">
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {cashier.firstName} {cashier.middleName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {translate("resources.root.currentCash")}
                        </Typography>{" "}
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <Typography className="pr-5" color="primary">
                            ${" "}
                            {fromCashier === cashier.userStoreBankAccountId ||
                            toCashier === cashier.userStoreBankAccountId
                              ? isByMain
                                ? fromCashier === cashier.userStoreBankAccountId
                                  ? parseFloat(cashier?.mcCurrentCash) -
                                    parseFloat(sentPrice)
                                  : toCashier ===
                                      cashier.userStoreBankAccountId &&
                                    parseFloat(cashier?.mcCurrentCash) +
                                      parseFloat(sentPrice)
                                : parseFloat(cashier?.mcCurrentCash)
                              : parseFloat(cashier?.mcCurrentCash)}
                          </Typography>
                          {fromCashier === cashier.userStoreBankAccountId ||
                          toCashier === cashier.userStoreBankAccountId
                            ? !isByMain
                              ? fromCashier === cashier.userStoreBankAccountId
                                ? parseFloat(cashier?.scCurrentCash) -
                                  parseFloat(sentPrice)
                                : toCashier ===
                                    cashier.userStoreBankAccountId &&
                                  parseFloat(cashier?.scCurrentCash) +
                                    parseFloat(sentPrice)
                              : parseFloat(cashier?.scCurrentCash)
                            : parseFloat(cashier?.scCurrentCash)}{" "}
                          IQD
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
