import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [expenseCategory, setExpenseCategory] = React.useState("");
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalPrice, setTotalPrice] = React.useState({});

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    let expense = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }
    if (expenseCategory !== 0) {
      expense = `${expenseCategory}`;
    } else {
      expense = "";
    }
    await HTTP.get(
      `${url}/Expenses?_start=${start}&_end=${end}${sendDates}${expense}`
    )
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
        setTotalPrice({
          sc: response.headers["sctotalprice"],
          mc: response.headers["mctotalprice"],
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(function () {
    setIsLoading(true);
    getData();
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [expenseCategory, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/ExpenseTypes?_end=10000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((expenseCategory) => {
        newArray.push({
          value: expenseCategory.id,
          label: expenseCategory.label,
          expenseCategory: expenseCategory,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.expenseStatementReport")}
            </h1>
          </div>
          <Row>
            {isLoading && <LoadingScreen />}
            <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
              <p className="m-0 mr-2 text-nowrap">
                {translate("resources.root.expenseCategory")} :
              </p>
              <AsyncSelect
                defaultOptions
                loadOptions={loadOptions}
                className="flex-grow-1"
                isMulti
                onChange={(e) => {
                  let expense = "";
                  if (e.length !== 0) {
                    e.map((x) => (expense += "&types=" + x.value));
                    setExpenseCategory(expense);
                  } else {
                    setExpenseCategory(``);
                  }
                }}
              />
            </Col>
            <Col className="col-12 col-lg-8  mt-4">
              <Row>
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="startDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2 text-nowrap">
                        {translate("resources.root.startDate")} :
                      </Form.Label>
                      <Form.Control
                        className=""
                        label="startDate"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="endDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2  text-nowrap">
                        {translate("resources.root.endDate")} :
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-lg-6 d-flex justify-content-between align-items-center mt-4">
              {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
                process.env.REACT_APP_SHOW_MC === "true" ? (
                  <h5>
                    {translate("resources.root.totalPrice")}: $
                    {parseFloat(totalPrice.mc)?.toLocaleString()}
                  </h5>
                ) : (
                  <h5>
                    {translate("resources.root.totalPrice")}:{" "}
                    {parseFloat(totalPrice.sc)?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </h5>
                )
              ) : (
                <>
                  <h5 className="mx-2">
                    {translate("resources.root.totalPrice")}: $
                    {parseFloat(totalPrice.mc)?.toLocaleString()}
                  </h5>
                  <h5 className="mx-2">
                    {translate("resources.root.totalPrice")}:{" "}
                    {parseFloat(totalPrice.sc)?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </h5>
                </>
              )}
            </Col>
            <Col className="col-4 col-lg-2 mt-4">
              <Button
                id="export"
                variant={"primary btn-block"}
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col className="col-4 col-lg-2 mt-4">
              <Button
                variant={"outline-primary btn-block"}
                onClick={() => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";
                  window.print();
                  sidebar[0].style.display = "block";
                  appMenu[0].style.display = "block";
                  appBar[0].style.marginTop = "40px";
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}{" "}
              </Button>
            </Col>
            <Col className="col-4 col-lg-2 mt-4">
              <Button
                variant={"primary btn-block"}
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate(
                  `resources.root.${isLoading ? "isLoading" : "search"}`
                )}
              </Button>
            </Col>{" "}
          </Row>

          <Row className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.title")}</th>
                    <th>{translate("resources.root.note")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>

                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.quantity")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>
                            <a
                              href={`#/Expenses/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.title}
                            </a>
                          </td>
                          <td>{one.note}</td>
                          <td>{one.description}</td>{" "}
                          <td>{one.invoiceNumber}</td>
                          <td>
                            {one.byMain
                              ? one.mcPrice.toLocaleString()
                              : one.scPrice.toLocaleString()}
                          </td>
                          <td>{one.qte}</td>
                          <td>
                            {one.byMain
                              ? one.mcTotalPrice.toLocaleString()
                              : one.scTotalPrice.toLocaleString()}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>5200</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
