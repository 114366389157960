import * as React from "react";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import Autocomplete from "@mui/lab/Autocomplete";
import Swal from "sweetalert2";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Col, Form, Row } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./../../assets/app.css";
import { HTTP } from "../../axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";

export const StoreInventoryInvoiceAddRealStock = () => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [orderSmallQtt, setOrderSmallQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("StoreInventoryInvoiceAddOrdersReaStock")
      ? JSON.parse(
          localStorage.getItem("StoreInventoryInvoiceAddOrdersReaStock")
        )
      : []
  );

  const [productNote, setProductNote] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [storeInventoryType, setStoreInventoryType] =
    React.useState("Periodic");
  const [delay, setDelay] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  const redirect = useRedirect();

  let typeOfInventory = orders[0]?.inventoryType;
  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    localStorage.setItem(
      "StoreInventoryInvoiceAddOrdersReaStock",
      JSON.stringify(orders)
    );
  }, [orders]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  const optionRenderer = (choice) => `${choice.name} ~ ${choice.barcode}`;

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=${
          inputValue.length * 10 > 30 ? 30 : inputValue.length * 10
        }&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };
  const changeQTY = (id, newQty, isSmall = false) => {
    let order = orders.find((x) => x.productId == id);
    let index = orders.indexOf(order);
    if (isSmall) {
      order.smallMeasureQte = newQty;
    } else {
      order.qte = newQty;
    }
    let newOrders = orders.filter((x) => x.productId !== id);

    newOrders = [
      ...newOrders.slice(0, index),
      order,
      ...newOrders.slice(index),
    ];

    setOrders(newOrders);
  };
  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <MaterialTextField
                label={translate("resources.root.invoiceNumber")}
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className={classes.invoiceNumberInput}
              />
              <Form.Group className={`m-4`}>
                <Datetime
                  initialValue={selectedDate}
                  defaultShow={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.qty")}: ${
                          selectedProduct.qteInStock
                        } + ${selectedProduct.smallMeasureQte}`}
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      />
                    )}
                    {selectedProduct && (
                      <Chip
                        label={`${translate("resources.root.futureQty")}: 
                                                ${parseFloat(orderQtt)} + 
                                                ${parseFloat(orderSmallQtt)}
                                                `}
                        className={`${classes.bottomLeftMargin} ${classes.successChips}`}
                      />
                    )}
                  </div>
                  <div className={classes.orderCols}>
                    <div style={{ width: "350px" }}>
                      {localStorage.getItem("useOldSelect") === "true" ? (
                        <Autocomplete
                          id="combo-box-demo"
                          options={products}
                          getOptionLabel={(option) =>
                            `${option.name} - ${option.barcode}`
                          }
                          style={{ width: 300 }}
                          onChange={(e, val) => {
                            setSelectedProduct(val);
                          }}
                          renderInput={(params) => (
                            <MaterialTextField
                              {...params}
                              label={translate("resources.root.chooseProduct")}
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <AsyncSelect
                          defaultOptions
                          menuPortalTarget={document.body}
                          loadOptions={loadOptions}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            setSelectedProduct(e.product);
                            setOrderQtt(1);
                          }}
                        />
                      )}
                    </div>

                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.smallMeasureQty")}
                      disabled={!selectedProduct}
                      value={orderSmallQtt}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setOrderSmallQtt(e.target.value);
                      }}
                      onBlur={(e) => {
                        let currentValue = e.target.value;
                        if (currentValue >= selectedProduct.oneContains) {
                          setOrderSmallQtt(
                            currentValue % selectedProduct.oneContains
                          );
                          setOrderQtt(
                            parseInt(orderQtt) +
                              parseInt(
                                currentValue / selectedProduct.oneContains
                              )
                          );
                        } else {
                          setOrderSmallQtt(e.target.value);
                        }
                      }}
                      className={classes.orderNumberInput}
                    />
                    <Button
                      variant="contained"
                      className={classes.botton}
                      disabled={!selectedProduct}
                      onClick={() => {
                        setOrders((prev) => {
                          const found = prev.find(
                            (el) =>
                              el.productId === parseInt(selectedProduct.id)
                          );
                          if (found) {
                            const newOrder = prev.map((el) => {
                              let totalQty =
                                parseFloat(orderQtt) +
                                parseFloat(el.qte ? el.qte : 0);
                              let totalSmallMeasure =
                                parseFloat(orderSmallQtt) +
                                parseFloat(
                                  el.smallMeasureQte ? el.smallMeasureQte : 0
                                );
                              if (
                                parseFloat(totalSmallMeasure) >=
                                parseFloat(selectedProduct.oneContains)
                              ) {
                                totalQty =
                                  parseInt(totalQty) +
                                  parseInt(
                                    totalSmallMeasure /
                                      selectedProduct.oneContains
                                  );
                                totalSmallMeasure =
                                  totalSmallMeasure %
                                  selectedProduct.oneContains;
                              }
                              return (
                                el.productId === selectedProduct.id && {
                                  productId: selectedProduct.id,
                                  barcode: selectedProduct.barcode,
                                  name: selectedProduct.name,
                                  payByMain: false,
                                  oneContains: selectedProduct.oneContains,
                                  productMeasure:
                                    selectedProduct.productMeasure,
                                  productOneMeasure:
                                    selectedProduct.productOneMeasure,
                                  mcPurchasePrice:
                                    selectedProduct.mcPurchasePrice,
                                  mcSellPrice: selectedProduct.mcSellPrice,
                                  mcSmallMeasureSellPrice:
                                    selectedProduct.mcSmallMeasureSellPrice,
                                  mcSmallMeasurePurchasePrice:
                                    selectedProduct.mcSmallMeasurePurchasePrice,
                                  scPurchasePrice:
                                    selectedProduct.scPurchasePrice,
                                  scSellPrice: selectedProduct.scSellPrice,
                                  scSmallMeasureSellPrice:
                                    selectedProduct.scSmallMeasureSellPrice,
                                  scSmallMeasurePurchasePrice:
                                    selectedProduct.scSmallMeasurePurchasePrice,
                                  qte: totalQty,
                                  oldQty: selectedProduct.qteInStock,
                                  smallMeasureQte: totalSmallMeasure,
                                  isAdded:
                                    orderQtt > selectedProduct.qteInStock,
                                }
                              );
                            });

                            return [
                              ...prev.filter(
                                (el) => el.productId !== selectedProduct.id
                              ),
                              ...newOrder.filter((el) => el !== false),
                            ];
                          } else {
                            return [
                              ...prev,
                              {
                                productId: selectedProduct.id,
                                barcode: selectedProduct.barcode,
                                name: selectedProduct.name,
                                payByMain: false,
                                oneContains: selectedProduct.oneContains,
                                productMeasure: selectedProduct.productMeasure,
                                productOneMeasure:
                                  selectedProduct.productOneMeasure,
                                mcPurchasePrice:
                                  selectedProduct.mcPurchasePrice,
                                mcSellPrice: selectedProduct.mcSellPrice,
                                mcSmallMeasureSellPrice:
                                  selectedProduct.mcSmallMeasureSellPrice,
                                mcSmallMeasurePurchasePrice:
                                  selectedProduct.mcSmallMeasurePurchasePrice,
                                scPurchasePrice:
                                  selectedProduct.scPurchasePrice,
                                scSellPrice: selectedProduct.scSellPrice,
                                scSmallMeasureSellPrice:
                                  selectedProduct.scSmallMeasureSellPrice,
                                scSmallMeasurePurchasePrice:
                                  selectedProduct.scSmallMeasurePurchasePrice,
                                oldQty: selectedProduct.qteInStock,
                                qte: parseFloat(orderQtt),
                                smallMeasureQte: parseFloat(orderSmallQtt),
                                isAdded: orderQtt > selectedProduct.qteInStock,
                              },
                            ];
                          }
                        });
                        setOrderQtt(0);
                        setOrderSmallQtt(0);
                        setOrderToEdit(-1);
                        setDelay(false);
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      style={{ marginLeft: 10 }}
                      disabled={true}
                    >
                      {translate("resources.root.noOfOrders")}: {orders.length}
                    </Button>
                  </div>

                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.smallMeasureQty")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders &&
                            orders.map((order) => (
                              <TableRow
                                key={order.productId}
                                style={{
                                  backgroundColor:
                                    order.qte > order.oldQty
                                      ? "#2ef52e"
                                      : "#f63b3b",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {order.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.name}
                                </TableCell>
                                <TableCell
                                  onDoubleClick={() => {
                                    Swal.fire({
                                      title: "Change order Quantity",
                                      input: "number",
                                      inputValue: order.qte,
                                      showCancelButton: true,
                                      focusConfirm: true,
                                      confirmButtonColor: "#117baf",
                                      cancelButtonColor: "#B71316",
                                      inputValidator: (value) => {
                                        if (!value) {
                                          return "You need to write something!";
                                        }
                                      },
                                      confirmButtonText: translate(
                                        "resources.root.submit"
                                      ),

                                      preConfirm: (value) => {
                                        changeQTY(
                                          order.productId,
                                          parseFloat(value)
                                        );
                                      },
                                    });
                                  }}
                                  align="right"
                                >
                                  {order.qte}
                                </TableCell>
                                <TableCell
                                  onDoubleClick={() => {
                                    Swal.fire({
                                      title: "Change order Small Quantity",
                                      input: "number",
                                      inputValue: order.smallMeasureQte,
                                      showCancelButton: true,
                                      focusConfirm: true,
                                      confirmButtonColor: "#117baf",
                                      cancelButtonColor: "#B71316",
                                      inputValidator: (value) => {
                                        if (!value) {
                                          return "You need to write something!";
                                        }
                                      },
                                      confirmButtonText: translate(
                                        "resources.root.submit"
                                      ),

                                      preConfirm: (value) => {
                                        changeQTY(
                                          order.productId,
                                          parseFloat(value),
                                          true
                                        );
                                      },
                                    });
                                  }}
                                >
                                  {order.smallMeasureQte}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          setOrders((prev) => {
                                            return prev.filter(
                                              (el) =>
                                                el.productId != order.productId
                                            );
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Chip
                      label={`${translate(
                        "resources.root.finalQuantity"
                      )}: ${orders
                        .map((el) => parseFloat(el.qte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()} +
                                                ${orders
                                                  .map((el) =>
                                                    parseFloat(
                                                      el.smallMeasureQte
                                                    )
                                                  )
                                                  .reduce((a, b) => a + b, 0)
                                                  ?.toLocaleString()}
                                                `}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                  </div>
                  <div className={"m-2"}>
                    <Row>
                      <Col>
                        <Select
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          classNamePrefix="select"
                          options={[
                            {
                              value: "periodic",
                              label: translate("resources.root.periodic"),
                            },
                            {
                              value: "continuous",
                              label: translate("resources.root.continuous"),
                            },
                            {
                              value: "sudden",
                              label: translate("resources.root.sudden"),
                            },
                          ]}
                          onChange={(e) => {
                            setStoreInventoryType(e.value);
                          }}
                          defaultValue={{
                            value: typeOfInventory?.toLowerCase() || "periodic",
                            label: translate(
                              `resources.root.${
                                typeOfInventory?.toLowerCase() || "periodic"
                              }`
                            ),
                          }}
                        />
                      </Col>
                      <Col>
                        <MaterialTextField
                          label={translate("resources.root.note")}
                          value={productNote}
                          onChange={(e) => setProductNote(e.target.value)}
                          className={classes.noteInput}
                        />
                      </Col>
                    </Row>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="secondary"
            disabled={delay}
            variant="contained"
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                setDelay(true);
                if (willDelete) {
                  setIsLoading(true);
                  HTTP.post(`${url}/StoreInventoryInvoice?RealStock=true`, {
                    voucherNumber: invoiceNumber,
                    date: selectedDate,
                    inventoryType: storeInventoryType,
                    payByMain: false,
                    totalQte: orders
                      .map((el) => el.qte)
                      .reduce((a, b) => a + b, 0),
                    totalGiftQte: orders
                      .map((el) => el.smallMeasureQty)
                      .reduce((a, b) => a + b, 0),
                    note: productNote,
                    storeInventoryOrders: orders,
                  })
                    .then(() => {
                      localStorage.removeItem(
                        "StoreInventoryInvoiceAddOrdersReaStock"
                      );
                      redirect("/StoreInventoryInvoice");
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      notify(err.response.data.message, { type: "error" });
                    });
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LoadingScreen />}
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "15% !important",
    marginLeft: "10px !important",
    marginRight: "10px !important",
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  successChips: {
    backgroundColor: "#72ffa7",
    color: "#000",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
