import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  useTranslate,
  Show,
  TabbedShowLayout,
  Tab,
  useNotify,
  Create,
  ShowButton,
  SearchInput,
  DateInput,
  NullableBooleanInput,
  DateField,
  BooleanField,
  useRefresh,
  FormTab,
  TabbedForm,
  useResourceDefinition,
} from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import moment from "moment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import PrintIcon from "@mui/icons-material/Print";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { ScSellInvoiceNew } from "./ScSellInvoiceNew";
import { McSellInvoiceNew } from "./McSellInvoiceNew";
import { MCSellInvoiceTest } from "./MCSellInvoiceTest";
import { FaAddressBook, FaCar } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Page from "../CustomPages/SellReceipt/Page";
import { useParams } from "react-router-dom";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Multiple inputs",
                html:
                  '<label class="text-dark">Pay Price:</label> <input value="0" id="swal-input1" type="number" class="swal2-input">' +
                  ' <label class="text-dark">Forgive:</label> <input  value="0" id="swal-input2" type="number" class="swal2-input">',
                focusConfirm: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: translate("resources.root.submit"),
                preConfirm: () => {
                  HTTP.get(
                    `${url}/SellInvoices/Pay?invoiceId=${
                      props.selectedIds[0]
                    }&payPrice=${
                      document.getElementById("swal-input1").value
                    }&forgivePrice=${
                      document.getElementById("swal-input2").value
                    }`
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.pay")}
          </Button>
        )}
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            let ids = "";
            for (let i = 0; i < props.selectedIds.length; i++) {
              if (i === 0) {
                ids += "?ids[]=" + props.selectedIds[i];
              } else {
                ids += "&ids[]=" + props.selectedIds[i];
              }
            }
            window
              .open(`#/PrintInvoiceOrders/SellInvoice${ids}`, "_blank")
              .focus();
          }}
        >
          <PrintIcon /> &nbsp; {translate("resources.root.printOrders")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: `${translate("resources.root.newDriver")}`,
              content: "input",
              button: {
                text: `${translate("resources.root.update")}`,
                closeModal: false,
              },
            }).then((name) => {
              if (name) {
                HTTP.post(
                  `${url}/SellInvoices/BatchUpdateDriver?driver=${name}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal(
                  `${translate("resources.root.emptyDriverNameIsNotAccepted")}`,
                  "",
                  "error"
                );
              }
            });
          }}
        >
          <FaCar /> &nbsp; {translate("resources.root.updateDriver")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            if (props.selectedIds.length === 1) {
              swal({
                text: `${translate("resources.root.newAddress")}`,
                content: "input",
                button: {
                  text: `${translate("resources.root.update")}`,
                  closeModal: false,
                },
              }).then((newAddress) => {
                if (newAddress) {
                  HTTP.post(
                    `${url}/SellInvoices/UpdateAddress?invoiceId=${props.selectedIds[0]}&newAddress=${newAddress}`,
                    {}
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        swal("Oh noes!", "The AJAX request failed!", "error");
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                } else {
                  swal(
                    `${translate(
                      "resources.root.emptyDriverNameIsNotAccepted"
                    )}`,
                    "",
                    "error"
                  );
                }
              });
            } else {
              swal(
                `${translate(
                  "resources.root.pleaseSelectOneInvoiceToUpdateAddress"
                )}`,
                ``,
                "error"
              );
            }
          }}
        >
          <FaAddressBook /> &nbsp; {translate("resources.root.updateAddress")}
        </Button>

        <BulkAction {...props} bulkDeleteButton={true} />
      </div>
    </React.Fragment>
  );
};

export const SellInvoicesList = (props) => {
  const postRowStyle = (record) => {
    if (record) {
      let check = localStorage.getItem("colorizeTable") === "true" || false;
      if (check) {
        let sellPrinted = JSON.parse(
          localStorage.getItem("sellInvoicePrinted") ?? "[]"
        );
        return {
          backgroundColor: sellPrinted.includes(record.id)
            ? "#cbfdcb"
            : "white",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    }
    return;
  };
  const [currency, setCurrency] = React.useState("default");
  const [orders, setOrders] = React.useState([]);
  const translate = useTranslate();
  const classes = useStyles();
  const sumPrintedOrders = orders.reduce((a, { qty }) => a + qty, 0);
  return (
    <div>
      <PrintProvider>
        <div>
          <NoPrint force>
            <List
              debounce={1500}
              pagination={<MyCustomPagination />}
              filters={
                <SearchFilter driver={true} date={true} fullDiscount={null} />
              }
              {...props}
              actions={
                <ListActions
                  setCurrency={setCurrency}
                  currency={currency}
                  hasCreate="sellinvoice.create"
                />
              }
            >
              <Datagrid
                bulkActionButtons={
                  <BulkActionButtons
                    setOrders={setOrders}
                    hasDelete="sellinvoice.delete"
                  />
                }
                rowStyle={postRowStyle}
              >
                <TextField source="id" />
                <FunctionField
                  label="resources.root.name"
                  render={(record) => `${record.customerName}`}
                />
                {localStorage.getItem("showInvoiceNumber") === "true" && (
                  <TextField
                    source="invoiceNumber"
                    label="resources.root.invoiceNumber"
                  />
                )}
                {/* total price of the incovie */}
                {localStorage.getItem("showTotalPrice") === "true" &&
                  currency === "default" && (
                    <FunctionField
                      label="resources.root.totalPrice"
                      sortBy={"mcTotalPrice"}
                      render={(record) =>
                        record.payByMain
                          ? `$${record.mcTotalPrice?.toLocaleString()}`
                          : `${record.scTotalPrice?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }
                    />
                  )}
                {localStorage.getItem("showTotalPrice") === "true" &&
                  currency !== "default" && (
                    <FunctionField
                      label="resources.root.totalPrice"
                      sortBy={"mcTotalPrice"}
                      render={(record) =>
                        currency === "dollar"
                          ? `$${record.mcTotalPrice?.toLocaleString()}`
                          : `${record.scTotalPrice?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }
                    />
                  )}
                {/* price of the incovie */}
                {/* end of the total  price  */}

                {currency === "default" && (
                  <FunctionField
                    label="resources.root.finalPrice"
                    sortBy={"mcFinalPrice"}
                    render={(record) =>
                      record.payByMain
                        ? `$${record.mcFinalPrice?.toLocaleString()}`
                        : `${record.scFinalPrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
                {currency !== "default" && (
                  <FunctionField
                    label="resources.root.finalPrice"
                    sortBy={"mcFinalPrice"}
                    render={(record) =>
                      currency === "dollar"
                        ? `$${record.mcFinalPrice?.toLocaleString()}`
                        : `${record.scFinalPrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
                {/* end of the price  */}

                <FunctionField
                  label={translate("resources.root.quantity")}
                  render={(record) =>
                    (record.totalQte - record.totalGiftQte)?.toLocaleString()
                  }
                />
                <TextField
                  source="totalGiftQte"
                  label="resources.root.giftQuantity"
                />
                <TextField
                  source="totalQte"
                  label="resources.root.totalQuantity"
                />
                <DateField
                  source="date"
                  label="resources.root.date"
                  locales={"en-GB"}
                />
                <TextField source="address" label="resources.root.address" />
                <BooleanField source="isCash" label="resources.root.isCash" />
                {localStorage.getItem("showNote") === "true" && (
                  <TextField source="note" label="resources.root.note" />
                )}
                {localStorage.getItem("showDriver") === "true" && (
                  <TextField source="driver" label="resources.root.driver" />
                )}

                {localStorage.getItem("showLastUser") === "true" && (
                  <TextField
                    source="lastUser"
                    label="resources.root.lastUser"
                  />
                )}
                {localStorage.getItem("showCreatedAt") === "true" && (
                  <FunctionField
                    sortBy={"createdAt"}
                    label="resources.root.createdAt"
                    render={(record) =>
                      record.createdAt
                        ? moment(record.createdAt).format(
                            "YYYY/MM/DD HH:MM:SS A"
                          )
                        : "-"
                    }
                  />
                )}
                <ShowButton />
                <EditButton />
              </Datagrid>
            </List>
          </NoPrint>
          <div>
            <Print single={true} name="foo">
              <div className={classes.ordersPrintWrapper}>
                <div style={{ marginBottom: 20 }}>
                  {translate("resources.root.date")}:
                  {moment().format("DD/MM/YYYY")}
                  {sumPrintedOrders !== 0 && (
                    <div className={`text-center`}>
                      {translate("resources.root.totalNumber")}:
                      {sumPrintedOrders}
                    </div>
                  )}
                </div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.giftQuantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.driver")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders?.map((order, key) => {
                        return (
                          <TableRow key={order?.name}>
                            <TableCell component="th" scope="row">
                              {key + 1}
                            </TableCell>
                            <TableCell align="right">{order?.name}</TableCell>
                            <TableCell align="right">
                              {order?.barcode}
                            </TableCell>
                            <TableCell align="right">{order?.qty}</TableCell>
                            <TableCell align="right">
                              {order?.giftQty}
                            </TableCell>
                            <TableCell align="right">{order?.driver}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Print>
          </div>
        </div>
      </PrintProvider>
    </div>
  );
};

export const SellInvoicesCreate = (props) => (
  <Create {...props}>
    {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
      <TabbedForm>
        {process.env.REACT_APP_SHOW_MC === "true" ? (
          <FormTab label="resources.root.dollarSell">
            <McSellInvoiceNew />
          </FormTab>
        ) : (
          <FormTab label="resources.root.dinnarSell">
            <ScSellInvoiceNew />
          </FormTab>
        )}
      </TabbedForm>
    ) : localStorage.getItem("sellMainCurrency") === "true" ? (
      <TabbedForm>
        <FormTab label="resources.root.dollarSell">
          <McSellInvoiceNew />
        </FormTab>
        <FormTab label="resources.root.dinnarSell">
          <ScSellInvoiceNew />
        </FormTab>
        {process.env.REACT_APP_APP_NAME === "Test" && (
          <FormTab label="Dollar Test">
            <MCSellInvoiceTest />
          </FormTab>
        )}
      </TabbedForm>
    ) : (
      <TabbedForm>
        <FormTab label="resources.root.dinnarSell">
          <ScSellInvoiceNew />
        </FormTab>
        <FormTab label="resources.root.dollarSell">
          <McSellInvoiceNew />
        </FormTab>
        {process.env.REACT_APP_APP_NAME === "Test" && (
          <FormTab label="Dollar Test">
            <MCSellInvoiceTest />
          </FormTab>
        )}
      </TabbedForm>
    )}
  </Create>
);

export const SellInvoicesEdit = () => {
  const notify = useNotify();
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const [sellInvoices, setSellInvoices] = React.useState([]);
  const [sellOrders, setSellOrders] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [payByMain, setPayByMain] = React.useState(true);
  const [address, setAddress] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("orders")
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [gift, setGift] = React.useState(0);
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [previousSellPrice, setPreviousSellPrice] = React.useState(null);
  const [modifiedPriceInUSD, setModifiedPriceInUSD] = React.useState(0);
  const [modifiedPriceInIQD, setModifiedPriceInIQD] = React.useState(0);
  const [productSerial, setProductSerial] = React.useState(null);

  const translate = useTranslate();

  React.useEffect(() => {
    if (props.id !== 0) {
      HTTP.get(`${url}/SellInvoices/${id}`).then((res) => {
        setPayByMain(res.data.payByMain);
        setSellInvoices(res.data);
        setSellOrders(res.data.sellOrders);
        setAddress(res.data.address);
      });
    }
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      sellInvoices &&
      HTTP.get(
        `${url}/SellInvoices/GetLastPriceOfProduct?customerId=${sellInvoices.customerId}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousSellPrice(res.data);
      });
  }, [sellInvoices, selectedProduct]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPrice);
  }, [modifiedPrice]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  const ratio = localStorage.getItem("dollarPrice");

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=20&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.invoice}>
          <div className={classes.invoiceCols}>
            <Form.Group className={`m-4`}>
              <Form.Control
                type="date"
                size={"lg"}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
            <MaterialTextField
              label={translate("resources.root.address")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={classes.addressInput}
            />
          </div>

          <div className={classes.invoiceCols}>
            <Chip
              icon={<AssignmentIndIcon />}
              label={
                sellInvoices.customer
                  ? `${sellInvoices.customer.firstName} ${sellInvoices.customer.middleName}`
                  : translate("resources.root.name")
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              icon={<PhoneIphoneIcon />}
              label={
                sellInvoices.customer
                  ? sellInvoices.customer.phone
                  : translate("resources.root.phone")
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              label={
                sellInvoices.customer
                  ? `${translate("resources.root.currentLoan")} ${
                      sellInvoices.customer.mcCurrentLoan
                    } ${
                      sellInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : " " + translate("resources.root.iqd")
                    }`
                  : `${translate("resources.root.currentLoan")}`
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              // icon={<LocalAtmIcon />}
              label={
                sellInvoices.customer
                  ? `${translate("resources.root.maxLoan")} ${
                      sellInvoices.customer.mcRoofLoan
                    } ${
                      sellInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : " " + translate("resources.root.iqd")
                    }`
                  : `${translate("resources.root.maxLoan")}`
              }
              className={classes.bottomLeftMargin}
            />
          </div>
        </div>
        <div className={classes.invoice}>
          <div className={classes.toggles}>
            {sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={sellInvoices.payByMain}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  sellInvoices.payByMain
                    ? translate("resources.root.dollar")
                    : translate("resources.root.dinar")
                }
              />
            )}
            {sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={true} name="checkedB" color="primary" />
                }
                label={translate("resources.root.isCash")}
              />
            )}
            {!sellInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={false} name="checkedB" color="primary" />
                }
                label={translate("resources.root.isCash")}
              />
            )}
          </div>
        </div>
        <div className={classes.orders}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.orderCols}>
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.productMeasure")} - ${
                            selectedProduct.productMeasure
                          }`
                        : translate("resources.root.productMeasure")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneMeasure")} - ${
                            selectedProduct.oneMeasure
                          }`
                        : translate("resources.root.oneMeasure")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneContains")} ${
                            selectedProduct.oneContains
                          } ${translate("resources.root.items")}`
                        : translate("resources.root.oneContains")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {selectedProduct && payByMain ? (
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - $${
                              modifiedPriceInUSD > 0
                                ? roundToTwo(modifiedPriceInUSD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.mcSellPrice * orderQtt
                                  )
                            }`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                  ) : (
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - ${
                              modifiedPriceInIQD > 0
                                ? roundToTwo(modifiedPriceInIQD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.scSellPrice * orderQtt
                                  )
                            }  IQD`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                  )}
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.currency")} - ${
                            payByMain
                              ? translate("resources.root.usd")
                              : " " + translate("resources.root.iqd")
                          }`
                        : translate("resources.root.currency")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {previousSellPrice && (
                    <Chip
                      label={
                        selectedProduct && payByMain
                          ? `${previousSellPrice.mcSellPrice?.toLocaleString()} ${translate(
                              "resources.root.usd"
                            )}`
                          : `${previousSellPrice.scSellPrice?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      style={{
                        backgroundColor: "lightgreen",
                        color: "black",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </div>

                <div className={classes.orderCols}>
                  <div style={{ width: "350px" }}>
                    <AsyncSelect
                      defaultOptions
                      menuPortalTarget={document.body}
                      loadOptions={loadOptions}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(e) => {
                        setSelectedProduct(e.product);
                        setOrderQtt(1);
                      }}
                    />
                  </div>
                  {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                    <MaterialTextField
                      label={translate("resources.root.serial")}
                      value={productSerial}
                      onChange={(e) => {
                        setProductSerial(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                  )}
                  {/*<Autocomplete*/}
                  {/*  id="combo-box-demo"*/}
                  {/*  options={products}*/}
                  {/*  // inputValue={selectedProduct ? selectedProduct.name : ''}*/}
                  {/*  getOptionLabel={(option) =>*/}
                  {/*    `${option.name} - ${option.barcode}`*/}
                  {/*  }*/}
                  {/*  style={{ width: 300 }}*/}
                  {/*  onChange={(el, val) => {*/}
                  {/*    setSelectedProduct(val);*/}
                  {/*    setOrderQtt(0);*/}
                  {/*    setModifiedPriceInUSD(0);*/}
                  {/*    setModifiedPriceInIQD(0);*/}
                  {/*  }}*/}
                  {/*  renderInput={(params) => (*/}
                  {/*    <MaterialTextField*/}
                  {/*      {...params}*/}
                  {/*      label={translate("resources.root.chooseProduct")}*/}
                  {/*      variant="outlined"*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*/>*/}
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.quantity")}
                    disabled={!selectedProduct}
                    value={orderQtt}
                    onChange={(e) => {
                      ((parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        gift <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                        e.target.value >= 0) ||
                        selectedProduct.canSellBellowZero) &&
                        setOrderQtt(e.target.value);
                    }}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    label={translate("resources.root.max")}
                    disabled={true}
                    value={selectedProduct ? selectedProduct.qteInStock : 0}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.priceInUSD")}
                    disabled={
                      !selectedProduct || (selectedProduct && !payByMain)
                    }
                    value={
                      modifiedPriceInUSD <= 0 || modifiedPriceInIQD > 0
                        ? selectedProduct
                          ? payByMain
                            ? selectedProduct.mcSellPrice
                            : modifiedPriceInIQD <= 0
                            ? roundToTwo(selectedProduct.scSellPrice / ratio)
                            : roundToTwo(modifiedPriceInIQD / ratio)
                          : 0
                        : modifiedPriceInUSD
                    }
                    onChange={(e) =>
                      setModifiedPriceInUSD(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.priceInIQD")}
                    disabled={
                      !selectedProduct || (selectedProduct && payByMain)
                    }
                    value={
                      modifiedPriceInIQD <= 0 || modifiedPriceInUSD > 0
                        ? selectedProduct
                          ? !payByMain
                            ? selectedProduct.scSellPrice
                            : modifiedPriceInUSD <= 0
                            ? roundToTwo(selectedProduct.mcSellPrice * ratio)
                            : roundToTwo(modifiedPriceInUSD * ratio)
                          : 0
                        : modifiedPriceInIQD
                    }
                    onChange={(e) =>
                      setModifiedPriceInIQD(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.gifts")}
                    disabled={!selectedProduct}
                    value={gift}
                    onChange={(e) =>
                      parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        parseFloat(orderQtt) <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                      e.target.value >= 0 &&
                      setGift(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.purchasePrice")}
                    disabled={true}
                    value={
                      selectedProduct
                        ? payByMain
                          ? selectedProduct.mcPurchasePrice
                          : selectedProduct.scPurchasePrice
                        : 0
                    }
                    className={classes.orderNumberInput}
                  />
                  <Button
                    variant="contained"
                    className={classes.botton}
                    disabled={orderQtt <= 0 || !selectedProduct}
                    onClick={() => {
                      swal({
                        title: `${translate("resources.root.areYouSure")}`,
                        text: `${translate("resources.root.toAddNewOrder")}`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          const sc = localStorage.getItem("dollarPrice");
                          HTTP.post(`${url}/SellOrders`, {
                            sellInvoiceId: sellInvoices.id,
                            productId: selectedProduct.id,
                            mcPurchasePrice: selectedProduct.mcPurchasePrice,
                            scPurchasePrice: selectedProduct.scPurchasePrice,
                            productMeasure: selectedProduct.productMeasure,
                            oneContains: selectedProduct.oneContains,
                            oneMeasure: selectedProduct.oneMeasure,
                            mcDiscount: discount,
                            scDiscount: discount * sc,
                            giftQte: gift,
                            mcLastPrice: selectedProduct.mcSellPrice - discount,
                            scLastPrice:
                              selectedProduct.scSellPrice - discount * sc,
                            payByMain: sellInvoices.payByMain,
                            barcode: selectedProduct.barcode,
                            name: selectedProduct.name,
                            productSerial: {
                              serialNumber: productSerial,
                            },
                            qte: orderQtt,
                            mcSellPrice:
                              modifiedPriceInUSD > 0
                                ? modifiedPriceInUSD
                                : selectedProduct.mcSellPrice,
                            scSellPrice:
                              modifiedPriceInIQD > 0
                                ? modifiedPriceInIQD
                                : selectedProduct.scSellPrice,
                            mcTotalPrice:
                              selectedProduct.mcSellPrice * orderQtt,
                            scTotalPrice:
                              selectedProduct.scSellPrice * orderQtt,
                          })
                            .then((res) => {
                              if (id !== 0) {
                                HTTP.get(`${url}/SellInvoices/${id}`).then(
                                  (res) => {
                                    setSellInvoices(res.data);
                                    setSellOrders(res.data.sellOrders);
                                  }
                                );
                              }
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                          setOrderQtt(0);
                          setOrderToEdit(-1);
                          setGift(0);
                        }
                      });
                    }}
                  >
                    {translate("resources.root.addOrder")}
                  </Button>
                </div>

                <div className={classes.orderCols}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      stickyHeader
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {translate("resources.root.barcode")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.name")}
                          </TableCell>
                          {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                            <TableCell align="right">
                              {translate("resources.root.serial")}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {translate("resources.root.quantity")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.gifts")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.price")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.finalPrice")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.actions")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.scrollable}>
                        {sellOrders &&
                          sellOrders.map((order) => (
                            <TableRow key={order.product.barcode}>
                              <TableCell component="th" scope="row">
                                {order.product.barcode}
                              </TableCell>
                              <TableCell align="right">
                                {order.product.name}
                              </TableCell>
                              {process.env.REACT_APP_PRODUCT_SERIAL ===
                                "true" && (
                                <TableCell align="right">
                                  {order.productSerial?.serialNumber}
                                </TableCell>
                              )}
                              <TableCell align="right">{order.qte}</TableCell>
                              <TableCell align="right">
                                {order.giftQte}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcSellPrice
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scSellPrice
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcDiscount
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scDiscount
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {sellInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcLastPrice
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scLastPrice
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {sellOrders.length > 1 && (
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: `${translate(
                                          "resource.root.areYouSure"
                                        )}`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `${url}/SellOrders/${order.id}`
                                          )
                                            .then((res) => {
                                              setSellOrders((prev) => {
                                                return prev.filter(
                                                  (el) => el.id != res.data.id
                                                );
                                              });
                                            })
                                            .catch((err) =>
                                              notify(
                                                err.response.data.message,
                                                "error"
                                              )
                                            );
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {sellInvoices.payByMain ? (
                    <div>
                      <Chip
                        label={`${translate(
                          "resources.root.totalPrice"
                        )} - ${"$"}${sellOrders
                          .map(
                            (el) =>
                              parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0)
                          ?.toLocaleString()} 
                      `}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalPrice"
                        )} - ${"$"}${(
                          sellOrders
                            .map(
                              (el) =>
                                parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                            )
                            .reduce((a, b) => roundToTwo(a + b), 0) - discount
                        )?.toLocaleString()} 
                      `}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalQuantity"
                        )} - ${sellOrders
                          .map((el) => parseFloat(el.qte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalGifts"
                        )} - ${sellOrders
                          .map((el) => parseFloat(el.giftQte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalQuantity"
                        )} - ${sellOrders
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                    </div>
                  ) : (
                    <div>
                      <Chip
                        label={`${translate(
                          "resources.root.totalPrice"
                        )} - ${sellOrders
                          .map(
                            (el) =>
                              parseFloat(el.scSellPrice) * parseFloat(el.qte)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0)
                          ?.toLocaleString()} 
                      IQD`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate("resources.root.finalPrice")} - ${(
                          sellOrders
                            .map(
                              (el) =>
                                parseFloat(el.scSellPrice) * parseFloat(el.qte)
                            )
                            .reduce((a, b) => roundToTwo(a + b), 0) - discount
                        )?.toLocaleString()} 
                      IQD`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalQuantity"
                        )} - ${sellOrders
                          .map((el) => parseFloat(el.qte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalGifts"
                        )} - ${sellOrders
                          .map((el) => parseFloat(el.giftQte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalQuantity"
                        )} - ${sellOrders
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                    </div>
                  )}
                  <MaterialTextField
                    type="number"
                    className={classes.inPercentage}
                    label={translate("resources.root.discount")}
                    disabled={true}
                    value={
                      sellInvoices.payByMain
                        ? sellInvoices.mcDiscount
                        : sellInvoices.scDiscount
                    }
                    variant="filled"
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <=
                          orders
                            .map(
                              (el) =>
                                parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0) &&
                        setDiscount(e.target.value);
                      setPercentageDiscount(
                        parseFloat(
                          (discount * 100) /
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => roundToTwo(a + b), 0)
                        )
                      );
                    }}
                  />
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                </div>
                <div className={classes.orderCols}>
                  <MaterialTextField
                    label={translate("resources.root.note")}
                    value={sellInvoices.note}
                    className={classes.noteInput}
                  />
                  <MaterialTextField
                    label={
                      process.env.REACT_APP_APP_NAME === "Techeye"
                        ? translate("resources.root.customer")
                        : translate("resources.root.driver")
                    }
                    value={sellInvoices.driver}
                    className={classes.orderTextInput}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export const SellInvoicesShow = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = useParams();
  const props = useResourceDefinition();

  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  const [isShowWarning, setIsShowingWarning] = React.useState(
    JSON.parse(localStorage.getItem("invoiceWarning")) || true
  );

  localStorage.setItem("invoiceWarning", isShowWarning);

  console.log(isShowWarning, typeof isShowWarning);
  React.useEffect(() => {
    if (id !== 0) {
      HTTP.get(`${url}/SellInvoices/${id}`).then((res) => {
        setOrders(res.data.sellOrders);
        setInvoice(res.data);
      });
    }
  }, [id]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              order={invoice.sellOrders}
              title={translate("resources.root.sellInvoice")}
              human={invoice.customer}
            />
            {/*<NewInvoice*/}
            {/*  ref={ref}*/}
            {/*  orders={orders}*/}
            {/*  invoice={invoice}*/}
            {/*  title={translate('resources.root.sellInvoice')}*/}
            {/*  human={invoice.customer}*/}
            {/*/>*/}
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label="resources.root.invoice">
                <>
                  <FunctionField
                    label="resources.root.invoiceNumber"
                    render={(record) =>
                      `${record?.invoiceNumber?.toUpperCase()}`
                    }
                  />
                  <FunctionField
                    label="resources.root.name"
                    render={(record) =>
                      record.customer &&
                      `${record.customer.firstName} ${record.customer.middleName} ${record.customer.lastName}`
                    }
                  />
                  <FunctionField
                    label="resources.root.quantity"
                    render={(record) =>
                      `${record.totalQte - record.totalGiftQte}`
                    }
                  />
                  <TextField
                    source="totalGiftQte"
                    label="resources.root.giftQuantity"
                  />
                  <TextField
                    source="totalQte"
                    label="resources.root.finalQuantity"
                  />
                  <DateField
                    source="date"
                    label="resources.root.date"
                    locales={"en-GB"}
                    showTime={true}
                  />
                  <TextField source="address" label="resources.root.address" />
                  <TextField source="note" label="resources.r oot.note" />
                  <TextField source="driver" label="resources.root.driver" />
                  {invoice.payByMain ? (
                    <FunctionField
                      source="mcTotalPrice"
                      label="resources.root.totalPrice"
                      render={(record) =>
                        `${record.mcTotalPrice?.toLocaleString()}`
                      }
                    />
                  ) : (
                    <FunctionField
                      label="resources.root.totalPrice"
                      render={(record) =>
                        `${record.scTotalPrice?.toLocaleString()}`
                      }
                    />
                  )}
                  {invoice.payByMain ? (
                    <FunctionField
                      label="resources.root.finalDiscount"
                      render={(record) =>
                        record.mcFinalDiscount &&
                        `${record.mcFinalDiscount?.toLocaleString()}`
                      }
                    />
                  ) : (
                    <FunctionField
                      label="resources.root.finalDiscount"
                      render={(record) =>
                        record.scFinalDiscount &&
                        `${record.scFinalDiscount?.toLocaleString()}`
                      }
                    />
                  )}
                  {invoice.payByMain ? (
                    <FunctionField
                      label="resources.root.finalPrice"
                      render={(record) =>
                        `${record.mcFinalPrice?.toLocaleString()}`
                      }
                    />
                  ) : (
                    <FunctionField
                      label="resources.root.finalPrice"
                      render={(record) =>
                        `${record.scFinalPrice?.toLocaleString()}`
                      }
                    />
                  )}
                </>
              </Tab>
              <Tab label="resources.root.orders">
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.note")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.productMeasure")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              {order.product.name}
                            </TableCell>
                            <TableCell align="right">{order.qte}</TableCell>
                            <TableCell align="right">{order.giftQte}</TableCell>
                            <TableCell align="right">{order.note}</TableCell>
                            <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSellPrice?.toLocaleString()
                                : order.scSellPrice?.toLocaleString()}
                            </TableCell>
                            {process.env.REACT_APP_ORDER_DISCOUNT ===
                              "true" && (
                              <TableCell align="right">
                                {order.payByMain
                                  ? order.mcDiscount?.toLocaleString()
                                  : order.scDiscount?.toLocaleString()}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice?.toLocaleString()
                                : order.scLastPrice?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <hr />
          <Button
            variant="contained"
            className={`mx-2`}
            onClick={() => {
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              // let root = document.getElementById("root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
              let sellPrinted = JSON.parse(
                localStorage.getItem("sellInvoicePrinted") ?? "[]"
              );
              if (!sellPrinted.includes(invoice.id)) {
                sellPrinted.push(invoice.id);
                localStorage.setItem(
                  "sellInvoicePrinted",
                  JSON.stringify(sellPrinted)
                );
              }
              // if (isShowWarning || true) {
              //   Swal.fire({
              //     icon: "info",
              //     title: translate("resources.root.dearUser"),
              //     text: translate(
              //       "resources.root.youCanChangeTheInvoiceDesign"
              //     ),
              //     footer:
              //       '<a href="#/SellReceipt" target="_blank">Invoice Design -- دیزاینی پسووڵە</a>',
              //   }).then(setIsShowingWarning(false));
              // }
            }}
          >
            {translate("resources.root.print")}
          </Button>
          <Button
            variant="contained"
            className={`mx-2`}
            onClick={() => {
              let ords = orders.map((el) => ({
                productId: el.productId,
                giftQte: el.giftQte,
                scLastPrice: el.scLastPrice,
                mcLastPrice: el.mcLastPrice,
                payByMain: el.payByMain,
                barcode: el.product.barcode,
                name: el.product.name,
                productSerial: el.productSerial,
                qte: el.qte,
                scPurchasePrice: el.scPurchasePrice,
                mcPurchasePrice: el.mcPurchasePrice,
                scDiscount: el.scDiscount,
                mcDiscount: el.mcDiscount,
                scSellPrice: el.scSellPrice,
                mcSellPrice: el.mcSellPrice,
                scTotalPrice: el.scLastPrice,
                mcTotalPrice: el.mcLastPrice,
                note: el.note,
              }));
              // console.log(o  rds, " ---- new ords");

              if (invoice.payByMain) {
                if (
                  localStorage.getItem("mcInvoiceOrders") &&
                  JSON.parse(localStorage.getItem("mcInvoiceOrders")).length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "mcInvoiceOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/SellInvoices/create/1");
                    }
                  });
                } else {
                  localStorage.setItem("mcInvoiceOrders", JSON.stringify(ords));
                  window.open("#/SellInvoices/create/1");
                }
              } else {
                if (
                  localStorage.getItem("scInvoiceOrders") &&
                  JSON.parse(localStorage.getItem("scInvoiceOrders")).length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "scInvoiceOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/SellInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem("scInvoiceOrders", JSON.stringify(ords));
                  window.open("#/SellInvoices/create");
                }
              }
            }}
          >
            {translate("resources.root.copySell")}
          </Button>
          <Button
            variant="contained"
            className={`${classes.botton} ${classes.bottomLeftMargin}`}
            onClick={() => {
              let ords = orders.map((el) => ({
                productId: el.productId,
                giftQte: el.giftQte,
                scLastPrice: el.scLastPrice,
                mcLastPrice: el.mcLastPrice,
                payByMain: el.payByMain,
                barcode: el.product.barcode,
                name: el.product.name,
                productSerial: el.productSerial,
                qte: el.qte,
                scPurchasePrice: el.scPurchasePrice,
                mcPurchasePrice: el.mcPurchasePrice,
                scInitPrice: el.scPurchasePrice,
                mcInitPrice: el.mcPurchasePrice,
                scDiscount: el.scDiscount,
                mcDiscount: el.mcDiscount,
                scSellPrice: el.scSellPrice,
                mcSellPrice: el.mcSellPrice,

                scTotalPrice: el.scPurchasePrice * el.qte,
                mcTotalPrice: el.mcPurchasePrice * el.qte,
                note: el.note,
              }));

              if (invoice.payByMain) {
                if (
                  localStorage.getItem("mcPurchaseOrders") &&
                  JSON.parse(localStorage.getItem("mcPurchaseOrders")).length >
                    0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "mcPurchaseOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/PurchaseInvoices/create/1");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "mcPurchaseOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/PurchaseInvoices/create/1");
                }
              } else {
                if (
                  localStorage.getItem("scPurchaseOrders") &&
                  JSON.parse(localStorage.getItem("scPurchaseOrders")).length >
                    0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "scPurchaseOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/PurchaseInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "scPurchaseOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/PurchaseInvoices/create");
                }
              }
            }}
          >
            {translate("resources.root.copyPurchase")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    marginLeft: 15,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
