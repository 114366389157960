import * as React from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  Show,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  minValue,
  SimpleShowLayout,
  BooleanField,
  DateField,
  ReferenceInput,
  TopToolbar,
  useRefresh,
  useTranslate,
  useEditController,
  useNotify,
  ShowButton,
} from "react-admin";

import swal from "sweetalert";

import Box from "@mui/material/Box";
import { TextField as RATextField } from "react-admin";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { path, url, handleRequestResponse } from "../../request";
import Grid from "@mui/material/Grid";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { toInteger } from "lodash";
import { v4 as uuidv4 } from "uuid";
export const MedItemList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="meditems.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="meditems.list" />}
      >
        <RATextField source="id" label="resources.root.id" />
        <RATextField
          source="departmentName"
          label="resources.root.department"
        />
        <RATextField source="name" label="resources.root.name" />
        <RATextField source="code" label="resources.root.code" />

        {currency === "default" && (
          <FunctionField
            label="resources.root.costPrice"
            sortBy={"mcCostPrice"}
            render={(record) =>
              record.payByMain
                ? `$${record.mcCostPrice?.toLocaleString()}`
                : `${record.scCostPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.costPrice"
            sortBy={"mcCostPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCostPrice?.toLocaleString()}`
                : `${record.scCostPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.sellPrice"
            sortBy={"mcSellPrice"}
            render={(record) =>
              record.payByMain
                ? `$${record.mcSellPrice?.toLocaleString()}`
                : `${record.scSellPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.sellPrice"
            sortBy={"mcSellPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcSellPrice?.toLocaleString()}`
                : `${record.scSellPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DateFilter = () => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <Grid item className="col-8 col-lg-6 my-3">
      <Row>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="startDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.startDate")} :
              </Form.Label>
              <Form.Control
                className=""
                label="startDate"
                type="date"
                value={
                  localStorage.getItem("startDate") &&
                  localStorage.getItem("startDate")
                }
                onChange={(e) => {
                  localStorage.setItem("startDate", e.target.value);
                  refresh();
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="endDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.endDate")} :
              </Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  localStorage.setItem("endDate", e.target.value);
                  refresh();
                }}
                value={
                  localStorage.getItem("endDate") &&
                  localStorage.getItem("endDate")
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Grid>
  );
};

const PostShowActions = ({ data, resource }) => <DateFilter />;

export const MedItemShow = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const params = useParams();
  const refresh = useRefresh();

  const [labReferences, setLabReferences] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`/MedItemReferences/${params.id}`).then((response) => {
      let CreateData = {
        MedItemId: toInteger(params.id),
        isAgeInMonth: false,
        minAge: 0,
        maxAge: 0,
        criticalLow: 0,
        criticalHigh: 0,
        normalLow: 0,
        normalHigh: 0,
        note: "",
        id: 0,
      };
      setLabReferences([CreateData, ...response.data]);
      // console.log(response.data);
    });
  }, []);

  return (
    <Show {...props} actions={<PostShowActions />}>
      <TabbedShowLayout>
        <Tab label="resources.root.generalInfo">
          <SimpleShowLayout className="row">
            <RATextField
              className={classes.form}
              source="MedItemCategoryId"
              label="resources.root.category"
            />
            <RATextField
              className={classes.form}
              source="name"
              label="resources.root.name"
            />
            <RATextField
              className={classes.form}
              source="description"
              label="resources.root.description"
            />

            <FunctionField
              className={classes.form}
              label="resources.root.costPrice"
              render={(record) =>
                `${record.payByMain ? record.mcCostPrice : record.scCostPrice}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />
            <FunctionField
              className={classes.form}
              label="resources.root.sellPrice"
              render={(record) =>
                `${record.payByMain ? record.mcSellPrice : record.scSellPrice}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />

            <FunctionField
              className={classes.form}
              label="resources.root.attachment"
              render={(record) => (
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              )}
            />

            <RATextField
              className={classes.form}
              source="note"
              label="resources.root.note"
            />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const MedItemCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [profit, setProfit] = React.useState(0);
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        mcCostPrice: data.costPrice,
        scCostPrice: data.costPrice,
        mcSellPrice: data.sellPrice,
        scSellPrice: data.sellPrice,
        payByMain: false,
      })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <ReferenceInput
              label={translate("resources.root.category")}
              source="departmentId"
              reference="Departments"
              className={classes.input}
            >
              <SelectInput
                className={classes.input}
                optionText="name"
                validate={[required()]}
              />
            </ReferenceInput>
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="code"
              label="resources.root.code"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="description"
              label="resources.root.description"
              className={classes.input}
            />
            <NumberInput
              source="sort"
              defaultValue={0}
              min={0}
              label="resources.root.sort"
              className={classes.input}
              validate={[required()]}
            />
            {/* <BooleanInput
              source="payByMain"
              label="resources.root.usd"
              defaultValue={true}
              className={classes.input}
            /> */}
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={profit}
              min={-1}
              max={1}
              source="profitRatio"
              label={"resources.root.profitRatio"}
              className={classes.input}
              onChange={(e) => {
                setProfit(e.target.value);
              }}
            />
            {profit == 0 && (
              <NumberInput
                validate={[required(), minValue(0)]}
                defaultValue={0}
                min={0}
                source="costPrice"
                label={"resources.root.costPrice"}
                className={classes.input}
                // onChange={(e) => {
                //   setSalary({ scSalary: 0, mcSalary: e.target.value });
                // }}
              />
            )}
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="sellPrice"
              label={"resources.root.sellPrice"}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />{" "}
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                // style={{ width: "100px" }}
                source="src"
                title="title"
              />
            </ImageInput>
          </div>
          <div className={classes.formCol}></div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const MedItemEdit = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [salaryCurrency, setSalaryCurrency] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  const { record } = useEditController();
  if (!record) return null;
  record.costPrice = record.payByMain ? record.mcCostPrice : record.scCostPrice;
  record.sellPrice = record.payByMain ? record.mcSellPrice : record.scSellPrice;

  return (
    <Edit
      {...props}
      transform={(data) => ({
        ...data,
        mcCostPrice: data.costPrice,
        scCostPrice: data.costPrice,
        mcSellPrice: data.sellPrice,
        scSellPrice: data.sellPrice,
      })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="description"
              label="resources.root.description"
              className={classes.input}
            />

            <TextInput
              source="code"
              label="resources.root.code"
              className={classes.input}
              validate={[required()]}
            />

            {/* <BooleanInput
              source="payByMain"
              label="resources.root.usd"
              defaultValue={true}
              className={classes.input}
            /> */}
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              source="profitRatio"
              label={"resources.root.profitRatio"}
              min={0}
              max={1}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="costPrice"
              label={
                currency
                  ? "resources.root.mcCostPrice"
                  : "resources.root.scCostPrice"
              }
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="sellPrice"
              label={
                currency
                  ? "resources.root.mcSellPrice"
                  : "resources.root.scSellPrice"
              }
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />

            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                source="src"
                title="title"
              />
            </ImageInput>

            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
