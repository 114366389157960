import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../App";
import BulkAction from "./CustomComponents/BulkActions";
import { ListActions } from "./templates/ListActions";

export const StoresList = (props) => (
  <div>
    <List
      actions={<ListActions createNoPermission={"true"} />}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={"edit"}>
        <TextField source="id" label={"resources.root.id"} />
        <TextField source="title" label="resources.root.name" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="address" label="resources.root.address" />
        <TextField source="description" label="resources.root.description" />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
      </Datagrid>
    </List>
  </div>
);

export const StoresCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <h1>You don't have permission to do this</h1>
      {/*  <TextInput source="title" label="resources.root.name" />
      <TextInput
        source="description"
        label="resources.root.description"
        options={{ multiLine: true }}
      />
      <TextInput
        source="note"
        label="resources.root.note"
        options={{ multiLine: true }}
      />
      <TextInput source="address" label="resources.root.address" />
      <TextInput source="phone" label="resources.root.phone" />
      <TextInput source="country" label="resources.root.country" />
      <TextInput source="city" label="resources.root.city" />
      <ReferenceInput
        source="branchId"
        label="resources.root.branch"
        reference="Branches"
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        source="bankAccountId"
        label="resources.root.bankAccount"
        reference="BankAccounts"
      >
        <SelectInput optionText="name" />
      </ReferenceInput> */}
    </SimpleForm>
  </Create>
);

export const StoresEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" label="resources.root.title" />
      <TextInput
        source="description"
        label="resources.root.description"
        options={{ multiLine: true }}
      />
      <TextInput
        source="note"
        label="resources.root.note"
        options={{ multiLine: true }}
      />
      <TextInput source="address" label="resources.root.address" />
      <TextInput source="phone" label="resources.root.phone" />
      <TextInput source="country" label="resources.root.country" />
      <TextInput source="city" label="resources.root.city" />
      <ReferenceInput
        source="branchId"
        label="resources.root.branch"
        reference="Branches"
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        source="bankAccountId"
        label="resources.root.bankAccount"
        reference="BankAccounts"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
