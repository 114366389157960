import * as React from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Edit,
  EditButton,
  ShowButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  Show,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  minValue,
  SimpleShowLayout,
  BooleanField,
  DateField,
  ReferenceInput,
  TopToolbar,
  useRefresh,
  useTranslate,
  useEditController,
  useNotify,
  AutocompleteInput,
} from "react-admin";

import swal from "sweetalert";

import { TextField as RATextField } from "react-admin";
import { makeStyles } from "@mui/styles";

import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { path, url, handleRequestResponse } from "../../request";
import Grid from "@mui/material/Grid";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Link, useParams } from "react-router-dom";
import _, { toInteger } from "lodash";
import { v4 as uuidv4 } from "uuid";
export const LabTestList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="labtest.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="labtest.list" />}
      >
        <RATextField source="id" label="resources.root.id" />
        <RATextField source="name" label="resources.root.name" />
        <RATextField
          source="labTestCategoryName"
          label="resources.root.category"
        />

        <RATextField source="code" label="resources.root.code" />
        <RATextField source="unit" label="resources.root.unit" />
        <FunctionField
          label="resources.root.external"
          sortBy={"isExternal"}
          render={(record) =>
            record.isExternal
              ? `${translate("resources.root.external")}`
              : `${translate("resources.root.internal")}`
          }
        />
        {currency === "default" && (
          <FunctionField
            label="resources.root.costPrice"
            sortBy={"mcCostPrice"}
            render={(record) =>
              record.payByMain
                ? `$${record.mcCostPrice?.toLocaleString()}`
                : `${record.scCostPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.costPrice"
            sortBy={"mcCostPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCostPrice?.toLocaleString()}`
                : `${record.scCostPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.sellPrice"
            sortBy={"mcSellPrice"}
            render={(record) =>
              record.payByMain
                ? `$${record.mcSellPrice?.toLocaleString()}`
                : `${record.scSellPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.sellPrice"
            sortBy={"mcSellPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcSellPrice?.toLocaleString()}`
                : `${record.scSellPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <RATextField source="sort" label="resources.root.sort" />

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DateFilter = () => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <Grid item className="col-8 col-lg-6 my-3">
      <Row>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="startDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.startDate")} :
              </Form.Label>
              <Form.Control
                className=""
                label="startDate"
                type="date"
                value={
                  localStorage.getItem("startDate") &&
                  localStorage.getItem("startDate")
                }
                onChange={(e) => {
                  localStorage.setItem("startDate", e.target.value);
                  refresh();
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="endDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.endDate")} :
              </Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  localStorage.setItem("endDate", e.target.value);
                  refresh();
                }}
                value={
                  localStorage.getItem("endDate") &&
                  localStorage.getItem("endDate")
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Grid>
  );
};

const PostShowActions = ({ data, resource }) => <DateFilter />;

export const LabTestShow = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const params = useParams();
  const refresh = useRefresh();
  const [partialLabTest, setPartialLabTest] = React.useState([]);

  const DeletePartialLabtest = async (id) => {
    await HTTP.delete(`${url}/LabTests/${id}`)
      .then(() => {
        notify(`Deleted Successfully!`, {
          type: "success",
        });
      })
      .then(() => {
        HTTP.get(`${url}/LabTests?parentId=${params.id}&_end=1000`)
          .then((res) => {
            const labPartial = _.sortBy(res.data, ["sort"]);
            setPartialLabTest(labPartial);
          })
          .catch((err) => {
            handleRequestResponse(notify, err);
          });
      })
      .catch((err) => {
        handleRequestResponse(notify, err);
      });
  };
  // const [labReferences, setLabReferences] = React.useState([]);
  React.useEffect(() => {
    //   HTTP.get(`${url}/LabTestReferences/${params.id}`).then((response) => {
    //     let CreateData = {
    //       labTestId: toInteger(params.id),
    //       isAgeInMonth: false,
    //       minAge: 0,
    //       maxAge: 0,
    //       criticalLow: 0,
    //       criticalHigh: 0,
    //       normalLow: 0,
    //       normalHigh: 0,
    //       note: "",
    //       isMale: true,
    //       id: 0,
    //     };
    //     setLabReferences([CreateData, ...response.data]);
    //   });
    HTTP.get(`${url}/LabTests?parentId=${params.id}&_end=1000`).then((res) => {
      const labPartial = _.orderBy(res.data, ["sort"], ["asc"]);
      setPartialLabTest(labPartial);
    });
  }, []);
  return (
    <Show {...props} actions={<PostShowActions />}>
      <TabbedShowLayout>
        <Tab label="resources.root.generalInfo">
          <SimpleShowLayout className="row">
            <RATextField
              className={classes.form}
              source="labTestCategoryId"
              label="resources.root.category"
            />
            <RATextField
              className={classes.form}
              source="name"
              label="resources.root.name"
            />
            <RATextField
              className={classes.form}
              source="description"
              label="resources.root.description"
            />

            <RATextField
              className={classes.form}
              source="code"
              label="resources.root.code"
            />
            <RATextField
              className={classes.form}
              source="unit"
              label="resources.root.unit"
            />
            <RATextField
              className={classes.form}
              source="sort"
              label="resources.root.sort"
            />
            <BooleanField
              className={classes.form}
              source="isExternal"
              label="resources.root.external"
            />

            <FunctionField
              className={classes.form}
              label="resources.root.costPrice"
              render={(record) =>
                `${record.payByMain ? record.mcCostPrice : record.scCostPrice}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />
            <FunctionField
              className={classes.form}
              label="resources.root.sellPrice"
              render={(record) =>
                `${record.payByMain ? record.mcSellPrice : record.scSellPrice}
                ${
                  record.payByMain
                    ? `${translate("resources.root.usd")}`
                    : ` ${translate("resources.root.iqd")}`
                }`
              }
            />

            <FunctionField
              className={classes.form}
              label="resources.root.attachment"
              render={(record) => (
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              )}
            />
            <FunctionField
              className={classes.form}
              label="resources.root.normalRange"
              render={(record) => (
                <textarea cols={30} rows={10} className="border-0" disabled>
                  {record.note}
                </textarea>
              )}
            />

            {/* <TextInput
              className={classes.form}
              source="note"
              label="resources.root.note"
            /> */}
          </SimpleShowLayout>
        </Tab>

        {/* <Tab label="resources.root.reference">
          <SimpleShowLayout
            className="border-bottom my-2"
            key={uuidv4()}
          ></SimpleShowLayout>
        </Tab> */}

        {partialLabTest[0]?.parentId && (
          <Tab label="resources.root.partialLabTest">
            <table class="table table-hover text-center">
              <thead>
                <tr>
                  <th>{translate("resources.root.sort")}</th>
                  <th>{translate("resources.root.code")}</th>
                  <th>{translate("resources.root.category")}</th>
                  <th>{translate("resources.root.name")}</th>
                  <th>{translate("resources.root.normalRange")}</th>
                  <th>{translate("resources.root.action")}</th>
                </tr>
              </thead>
              <tbody>
                {partialLabTest.map((labtest) => {
                  return (
                    <tr className="border-bottom p-2">
                      <td className="p-3">{labtest.sort}</td>
                      <td className="p-3">{labtest.code}</td>
                      <td className="p-3">{labtest.labTestCategoryName}</td>
                      <td className="p-3">{labtest.name}</td>
                      <td className="p-3">{labtest.note}</td>
                      <td className="p-3 d-flex gap-3 justify-content-center align-items-center">
                        <a
                          className="d-flex gap-1"
                          href={`#/LabTests/${labtest.id}`}
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                          <p className="text-primary">
                            {translate("ra.action.edit")}
                          </p>
                        </a>
                        <a
                          className="d-flex gap-1"
                          href={`#/LabTests/${labtest.id}/show`}
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                          </svg>
                          <p className="text-primary ">
                            {translate("ra.action.show")}
                          </p>
                        </a>
                        <div
                          onClick={() => DeletePartialLabtest(labtest.id)}
                          className="d-flex gap-1 text-danger pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                          <p>{translate("ra.action.delete")}</p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        )}
        {/* <Tab label="resources.root.reference">
          {labReferences.map((labReference) => {
            return (
              <SimpleShowLayout className="border-bottom my-2" key={uuidv4()}>
                <Row className="justify-content-between align-items-center">
                  <Col className="m-0 col-12 row">
                    <div className="col-4 d-flex flex-column align-items-center justify-content-center p-0">
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.minAge")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.minAge}
                        // name="minAge"
                        onChange={(e) =>
                          (labReference.minAge = toInteger(e.target.value))
                        }
                      />
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.maxAge")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.maxAge}
                        onChange={(e) =>
                          (labReference.maxAge = toInteger(e.target.value))
                        }
                      />
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center justify-content-center p-0">
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.criticalLow")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.criticalLow}
                        onChange={(e) =>
                          (labReference.criticalLow = e.target.value)
                        }
                      />
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.criticalHigh")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.criticalHigh}
                        onChange={(e) =>
                          (labReference.criticalHigh = e.target.value)
                        }
                      />
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center justify-content-center p-0">
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.normalLow")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.normalLow}
                        onChange={(e) =>
                          (labReference.normalLow = e.target.value)
                        }
                      />
                      <TextField
                        fullWidth
                        className="px-2"
                        label={translate("resources.root.normalHigh")}
                        type="number"
                        variant="filled"
                        defaultValue={labReference.normalHigh}
                        onChange={(e) =>
                          (labReference.normalHigh = e.target.value)
                        }
                      />
                    </div>
                  </Col>

                  <Col className="m-0 col-12 row my-3">
                    <Col className="m-0 col-12 col-md-2 px-1">
                      <FormControl fullWidth>
                        <InputLabel>
                          {translate("resources.root.isAgeInMonth")}
                        </InputLabel>
                        <Select
                          label={translate("resources.root.isAgeInMonth")}
                          onChange={(e) =>
                            (labReference.isAgeInMonth = e.target.value)
                          }
                          defaultValue={labReference.isAgeInMonth}
                        >
                          <MenuItem value={true}>
                            {translate("resources.root.month")}
                          </MenuItem>
                          <MenuItem value={false}>
                            {translate("resources.root.year")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="m-0 col-12 col-md-2 px-1">
                      <FormControl fullWidth>
                        <InputLabel>
                          {translate("resources.root.male")}
                        </InputLabel>
                        <Select
                          label={translate("resources.root.male")}
                          onChange={(e) =>
                            (labReference.isMale = e.target.value)
                          }
                          defaultValue={labReference.isMale}
                        >
                          <MenuItem value={null}>
                            {translate("resources.root.both")}
                          </MenuItem>
                          <MenuItem value={true}>
                            {translate("resources.root.male")}
                          </MenuItem>
                          <MenuItem value={false}>
                            {translate("resources.root.female")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="col-12 col-md-7 px-1">
                      <TextField
                        fullWidth
                        label={translate("resources.root.note")}
                        type="text"
                        variant="filled"
                        defaultValue={labReference.note}
                        onChange={(e) => (labReference.note = e.target.value)}
                      />
                    </Col>

                    <Col className="col-12 col-md-1 d-flex align-items-center justify-content-center m-0 p-0">
                      {labReference.id === 0 ? (
                        <IconButton
                          id={`saveButton`}
                          aria-label={translate("resources.root.saveButton")}
                          onClick={async (e) => {
                            HTTP.post(`/LabTestReferences`, labReference)
                              .then((res) => {
                                const remainItems = labReferences.filter(
                                  (el) => el.id !== 0
                                );
                                let CreateData = {
                                  labTestId: toInteger(params.id),
                                  isAgeInMonth: false,
                                  minAge: 0,
                                  maxAge: 0,
                                  criticalLow: 0.0,
                                  criticalHigh: 0.0,
                                  normalLow: 0.0,
                                  normalHigh: 0,
                                  note: "",
                                  isMale: true,
                                  id: 0,
                                };

                                setLabReferences([
                                  CreateData,
                                  res.data,
                                  ...remainItems,
                                ]);
                              })
                              .then(() => {
                                notify("Added Successfully", {
                                  type: "success",
                                });
                              })
                              .catch((err) =>
                                handleRequestResponse(notify, err)
                              );
                          }}
                        >
                          <SaveIcon style={{ fontSize: "30px" }} />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton
                            id={`saveButton`}
                            aria-label={"resources.root.saveButton"}
                            onClick={async (e) => {
                              HTTP.put(
                                `/LabTestReferences/${labReference.id}`,
                                labReference
                              )

                                .then(() => {
                                  refresh();
                                })
                                .then(() => {
                                  notify("Updated Successfully", {
                                    type: "success",
                                  });
                                })
                                .catch((err) =>
                                  handleRequestResponse(notify, err)
                                );
                            }}
                          >
                            <UpdateIcon style={{ fontSize: "30px" }} />
                          </IconButton>
                          <IconButton
                            aria-label="resources.root.delete"
                            onClick={() => {
                              swal({
                                title: translate("resources.root.makeSure"),
                                text: translate(
                                  "resources.root.confirmPerminantDelete"
                                ),
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  HTTP.delete(
                                    `/LabTestReferences/${labReference.id}`
                                  ).then((res) => {
                                    const remainItems = labReferences.filter(
                                      (el) => el.id !== labReference.id
                                    );
                                    setLabReferences(remainItems);
                                  });
                                } else {
                                  swal(
                                    translate("resources.root.deleteCancel")
                                  );
                                }
                              });
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "30px" }} />
                          </IconButton>
                        </>
                      )}
                    </Col>
                  </Col>
                </Row>
              </SimpleShowLayout>
            );
          })}
        </Tab> */}
      </TabbedShowLayout>
    </Show>
  );
};

export const LabTestCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        mcCostPrice: data.costPrice,
        scCostPrice: data.costPrice,
        mcSellPrice: data.sellPrice,
        scSellPrice: data.sellPrice,
      })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <ReferenceInput
              label={translate("resources.root.category")}
              source="labTestCategoryId"
              reference="LabTestCategories"
              className={classes.input}
              perPage={1000}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                className={classes.input}
                optionText="name"
                validate={[required()]}
              />
            </ReferenceInput>
            <ReferenceInput
              label={translate("resources.root.labtest")}
              source="parentId"
              reference="labTests"
              className={classes.input}
              perPage={1000}
            >
              <SelectInput className={classes.input} optionText="name" />
            </ReferenceInput>
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="description"
              label="resources.root.description"
              className={classes.input}
            />
            <TextInput
              source="code"
              label="resources.root.code"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="unit"
              label="resources.root.unit"
              className={classes.input}
            />{" "}
            <NumberInput
              validate={[minValue(0)]}
              defaultValue={0}
              source="sort"
              label={"resources.root.sort"}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />
            <BooleanInput
              source="isExternal"
              label="resources.root.external"
              defaultValue={false}
              className={classes.input}
            />
            <BooleanInput
              source="payByMain"
              label="resources.root.usd"
              defaultValue={false}
              className={classes.input}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="costPrice"
              label={"resources.root.costPrice"}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="sellPrice"
              label={"resources.root.sellPrice"}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />{" "}
            <TextInput
              label="resources.root.normalRange"
              source="note"
              multiline
            />
            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                // style={{ width: "100px" }}
                source="src"
                title="title"
              />
            </ImageInput>
          </div>
          <div className={classes.formCol}></div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const LabTestEdit = (props) => {
  const translate = useTranslate();

  const classes = useStyles();
  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  const { record } = useEditController();
  if (!record) return null;
  record.costPrice = record.payByMain ? record.mcCostPrice : record.scCostPrice;
  record.sellPrice = record.payByMain ? record.mcSellPrice : record.scSellPrice;

  return (
    <Edit
      {...props}
      transform={(data) => ({
        ...data,
        mcCostPrice: data.costPrice,
        scCostPrice: data.costPrice,
        mcSellPrice: data.sellPrice,
        scSellPrice: data.sellPrice,
      })}
      redirect="list"
      id={props.id}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <ReferenceInput
              label={translate("resources.root.category")}
              source="labTestCategoryId"
              reference="LabTestCategories"
              className={classes.input}
              perPage={1000}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                className={classes.input}
                optionText="name"
                validate={[required()]}
              />
            </ReferenceInput>
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />{" "}
            <ReferenceInput
              label={translate("resources.root.labtest")}
              source="parentId"
              reference="labTests"
              className={classes.input}
              perPage={1000}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput className={classes.input} optionText="name" />
            </ReferenceInput>
            <TextInput
              source="description"
              label="resources.root.description"
              className={classes.input}
            />
            <TextInput
              source="code"
              label="resources.root.code"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="unit"
              label="resources.root.unit"
              className={classes.input}
            />{" "}
            <NumberInput
              validate={[minValue(0)]}
              defaultValue={0}
              source="sort"
              label={"resources.root.sort"}
              className={classes.input}
              // onChange={(e) => {
              //   setSalary({ scSalary: 0, mcSalary: e.target.value });
              // }}
            />
            <BooleanInput
              source="isExternal"
              label="resources.root.external"
              defaultValue={true}
              className={classes.input}
            />
            {/* <BooleanInput
              source="payByMain"
              label="resources.root.usd"
              defaultValue={true}
              className={classes.input}
            /> */}
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="costPrice"
              label={"resources.root.costPrice"}
              className={classes.input}
            />
            <NumberInput
              validate={[required(), minValue(0)]}
              defaultValue={0}
              min={0}
              source="sellPrice"
              label={"resources.root.sellPrice"}
              className={classes.input}
            />
            <ImageInput
              className={classes.input}
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField
                className={classes.input}
                source="src"
                title="title"
              />
            </ImageInput>
            {/* <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            /> */}
            <TextInput
              label="resources.root.normalRange"
              source="note"
              defaultValue="Lorem Ipsum"
              multiline
              fullWidth
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
