import * as React from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  useTranslate,
  ShowButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import { ListActions } from "../templates/ListActions";

export const DepositsTypesList = (props) => {
  const translate = useTranslate();
  return (
    <List
      actions={<ListActions hasCreate="setting" />}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="setting" />}
      >
        <TextField source="id" label={translate("resources.root.id")} />
        <TextField source="label" label={translate("resources.root.label")} />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}

        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("YYYY/MM/DD HH:MM")
                : "-"
            }
          />
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const DepositsTypesCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="label" label={translate("resources.root.label")} />
        <TextInput
          source="note"
          label={translate("resources.root.note")}
          options={{ multiLine: true }}
        />
      </SimpleForm>
    </Create>
  );
};

export const DepositsTypesEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="label"
          label={translate("resources.root.label")}
          margin="normal"
          variant="outlined"
        />
        <TextInput
          source="note"
          label={translate("resources.root.note")}
          margin="normal"
          variant="outlined"
          options={{ multiLine: true }}
        />
      </SimpleForm>
    </Edit>
  );
};
