import React, { Component } from "react";
import HalfPage from "./HalfPage";
import "./Style.css";

class Page extends Component {
  render() {
    const { data } = this.props;
    const halfPage = <HalfPage data={data} />;
    return (
      <div
        id={"hidePage"}
        className="page d-none"
        style={{
          width: `${localStorage.getItem("page1height") || 25.9}cm`,
          height: `${localStorage.getItem("page1width") || 37}cm`,
          direction: "ltr",
        }}
      >
        {halfPage}
        <hr />
        {halfPage}
      </div>
    );
  }
}

export default Page;
