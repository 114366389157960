import "./AnimationButtons.css";

const LoadingScreen = ({ text, link }) => {
  return (
    <a
      href={link}
      id={`animationButton`}
      className={`btn btn-sm btn-block m-0`}
    >
      {text}
    </a>
  );
};

export default LoadingScreen;
