import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  DateInput,
  FunctionField,
  Edit,
  AutocompleteInput,
  Show,
  DateField,
  SimpleShowLayout,
  useDataProvider,
  NumberInput,
  number,
  minValue,
  BooleanInput,
  ImageField,
  ImageInput,
  useTranslate,
  useRecordContext,
  EditButton,
  downloadCSV,
  useResourceDefinition,
  ShowButton,
} from "react-admin";
import { url } from "../../request";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { ListActions } from "../templates/ListActions";
import { Button } from "react-bootstrap";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import moment from "moment";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { path } from "../../request";
import { Col, Form } from "react-bootstrap";

import Datetime from "react-datetime";

export const DoctorsPaymentsList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        doctorName,
        mcPrice,
        scPrice,
        mcForgivePrice,
        scForgivePrice,
        byMain,
        accountant,
        invoiceNumber,
      } = data;

      let price = byMain ? mcPrice : scPrice;
      let forgivePrice = byMain ? mcForgivePrice : scForgivePrice;
      let isMain = byMain
        ? translate("resources.root.usd")
        : translate("resources.root.iqd");
      return {
        id,
        doctorName,
        invoiceNumber,
        price,
        forgivePrice,
        isMain,
        accountant,
      };
    });
  };
  return (
    <List
      exporter={exporter}
      pagination={<MyCustomPagination />}
      filters={<SearchFilter date={true} />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <TextField source="id" label="resources.root.id" />
        <TextField source="doctorName" label="resources.root.name" />

        {currency === "default" && (
          <FunctionField
            label="resources.root.price"
            sortBy={"mcPrice"}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}

        {currency !== "default" && (
          <FunctionField
            label="resources.root.price"
            sortBy={"mcPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"mcForgivePrice"}
            label="resources.root.forgivePrice"
            render={(record) =>
              record.byMain
                ? `$${record.mcForgivePrice?.toLocaleString()}`
                : `${record.scForgivePrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcForgivePrice"}
            label="resources.root.forgivePrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcForgivePrice?.toLocaleString()}`
                : `${record.scForgivePrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField source="accountant" label="resources.root.accountant" />
        <TextField
          source="invoiceNumber"
          label="resources.root.invoiceNumber"
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        {localStorage.getItem("showCreatedAt") === "true" && (
          <DateField
            source="createdAt"
            label="resources.root.createdAt"
            locales={"en-GB"}
          />
        )}
        <ShowButton />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export const DoctorsPaymentsShow = () => {
  const props = useResourceDefinition();
  const { id } = useParams();
  const translate = useTranslate();
  const [payment, setPayment] = React.useState([]);

  // const ShowingAttachment = () => {
  //   const recordy = useRecordContext();
  //   if (recordy.attachment === null) return null;
  //   return (
  //     <img
  //       style={{
  //         width: 75,
  //         height: 75,
  //         objectFit: "scale-down",
  //         cursor: "pointer",
  //       }}
  //       src={
  //         recordy.attachment !== null
  //           ? `${path}${recordy.attachment}`
  //           : `https://via.placeholder.com/75`
  //       }
  //       alt={recordy.name}
  //       onClick={() =>
  //         window.open(
  //           recordy.attachment !== null
  //             ? `${path}${recordy.attachment}`
  //             : `https://via.placeholder.com/75`,
  //           "_blank"
  //         )
  //       }
  //     />
  //   );
  // };
  React.useEffect(() => {
    HTTP.get(`${url}/DoctorPayments/${id}`).then((res) => {
      setPayment(res.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: payment.invoiceNumber && payment.invoiceNumber,
          serialNo: payment.id && payment.id,
          dollar: payment.byMain && payment.byMain,
          isDeposit: true,
          date: payment.date && payment.date,
          payedTo: payment.doctorName && payment.doctorName,
          amount:
            payment.byMain && payment.byMain
              ? payment.mcPrice && payment.mcPrice?.toLocaleString()
              : payment.scPrice && payment.scPrice?.toLocaleString(),
          forgivePrice:
            payment.byMain && payment.byMain
              ? payment.mcForgivePrice &&
                payment.mcForgivePrice?.toLocaleString()
              : payment.scForgivePrice &&
                payment.scForgivePrice?.toLocaleString(),

          type: "DoctorPayments",
          note: payment.note && payment.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <TextField source="doctorName" label="resources.root.name" />
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain ? `$${record.mcPrice}` : `${record.scPrice} IQD`
            }
          />
          <FunctionField
            label="resources.root.forgivePrice"
            render={(record) =>
              record.byMain
                ? `$${record.mcForgivePrice}`
                : `${record.scForgivePrice} IQD`
            }
          />
          <TextField source="accountant" label="resources.root.accountant" />
          <DateField
            source="date"
            showTime
            label="resources.root.date"
            locales={"en-GB"}
          />
          <TextField source="note" label="resources.root.note" />
          {payment.attachment && (
            <FunctionField
              label="resources.root.attachment"
              render={(record) => (
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              )}
            />
          )}

          <Button
            className={`btn btn-primary`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};
