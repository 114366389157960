import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Show,
  TabbedShowLayout,
  Tab,
  useNotify,
  DateField,
  BooleanField,
  useRefresh,
  FormTab,
  ShowButton,
  Create,
  TabbedForm,
  useTranslate,
  useResourceDefinition,
} from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Grid from "@mui/material/Grid";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import rtl from "jss-rtl";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import PrintIcon from "@mui/icons-material/Print";
import { ScSellInvoiceNew } from "./ScSellInvoiceNew";
import { McSellInvoiceNew } from "./McSellInvoiceNew";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            let ids = "";
            for (let i = 0; i < props.selectedIds.length; i++) {
              if (i === 0) {
                ids += "?ids[]=" + props.selectedIds[i];
              } else {
                ids += "&ids[]=" + props.selectedIds[i];
              }
            }
            window
              .open(`#/PrintInvoiceOrders/SellReturnInvoice${ids}`, "_blank")
              .focus();
          }}
        >
          <PrintIcon /> &nbsp; Print Orders
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: "New Driver:",
              content: "input",
              button: {
                text: "Update!",
                closeModal: false,
              },
            }).then((name) => {
              if (name) {
                HTTP.post(
                  `${url}/SellReturnInvoices/BatchUpdateDriver?driver=${name}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal("Empty driver name is not accepted!", "", "error");
              }
            });
          }}
        >
          <AssignmentTurnedInRoundedIcon /> &nbsp; Update Driver
        </Button>
        <BulkAction {...props} />
      </div>
    </React.Fragment>
  );
};

export const SellReturnInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const [orders, setOrders] = React.useState([]);

  return (
    <PrintProvider>
      <Print name={"noName"}>
        <List
          debounce={1500}
          pagination={<MyCustomPagination />}
          filters={<SearchFilter date={true} driver={true} />}
          {...props}
          sort={{ field: "id", order: "DESC" }}
          actions={
            <ListActions
              setCurrency={setCurrency}
              currency={currency}
              hasCreate={"returnsellinvoice.create"}
            />
          }
        >
          <Datagrid
            bulkActionButtons={<BulkActionButtons setOrders={setOrders} />}
          >
            <TextField source="id" label="resources.root.id" />
            <FunctionField
              label="resources.root.name"
              render={(record) =>
                `${record.customer.firstName} ${record.customer.middleName}`
              }
            />
            {localStorage.getItem("showInvoiceNumber") === "true" && (
              <TextField
                source="invoiceNumber"
                label="resources.root.invoiceNumber"
              />
            )}
            {currency === "default" && (
              <FunctionField
                label="resources.root.price"
                sortBy={"mcFinalPrice"}
                render={(record) =>
                  record.payByMain
                    ? `$${record.mcFinalPrice?.toLocaleString()}`
                    : `${record.scFinalPrice?.toLocaleString()} IQD`
                }
              />
            )}
            {currency !== "default" && (
              <FunctionField
                label="resources.root.price"
                sortBy={"mcFinalPrice"}
                render={(record) =>
                  currency === "dollar"
                    ? `$${record.mcFinalPrice?.toLocaleString()}`
                    : `${record.scFinalPrice?.toLocaleString()} IQD`
                }
              />
            )}
            {currency === "default" && (
              <FunctionField
                label="resources.root.discount"
                sortBy={"mcFinalDiscount"}
                render={(record) =>
                  record.payByMain
                    ? `$${record.mcFinalDiscount?.toLocaleString()}`
                    : `${record.scFinalDiscount?.toLocaleString()} IQD`
                }
              />
            )}
            {currency !== "default" && (
              <FunctionField
                label="resources.root.discount"
                sortBy={"mcFinalDiscount"}
                render={(record) =>
                  currency === "dollar"
                    ? `$${record.mcFinalDiscount?.toLocaleString()}`
                    : `${record.scFinalDiscount?.toLocaleString()} IQD`
                }
              />
            )}
            <TextField label="resources.root.totalQte" source="totalQte" />
            {/* <FunctionField
              label="resources.root.totalQte"
              sortBy={"totalQte"}
              render={(record) =>
                (record.totalQte + record.totalGiftQte)?.toLocaleString()
              }
            /> */}
            <DateField
              source="date"
              label="resources.root.date"
              locales={"en-GB"}
            />
            {/* <BooleanField source="isCash" /> */}
            {/* <TextField source="driver" /> */}
            {localStorage.getItem("showNote") === "true" && (
              <TextField source="note" label="resources.root.note" />
            )}
            {localStorage.getItem("showDriver") === "true" && (
              <TextField source="driver" label="resources.root.driver" />
            )}
            {localStorage.getItem("showLastUser") === "true" && (
              <TextField source="lastUser" label="resources.root.lastUser" />
            )}
            {localStorage.getItem("showCreatedAt") === "true" && (
              <FunctionField
                sortBy={"createdAt"}
                label="resources.root.createdAt"
                render={(record) =>
                  record.createdAt
                    ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                    : "-"
                }
              />
            )}
            <ShowButton />
          </Datagrid>
        </List>
      </Print>
    </PrintProvider>
  );
};

export const SellReturnInvoicesCreate = (props) => (
  <Create {...props}>
    {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
      process.env.REACT_APP_SHOW_MC === "true" ? (
        <TabbedForm>
          <FormTab label="resources.root.dollarReturn">
            <McSellInvoiceNew invoice="return" />
          </FormTab>
        </TabbedForm>
      ) : (
        <TabbedForm>
          <FormTab label="resources.root.dinnarReturn">
            <ScSellInvoiceNew invoice="return" />
          </FormTab>
        </TabbedForm>
      )
    ) : (
      <TabbedForm>
        <FormTab label="resources.root.dinnarReturn">
          <ScSellInvoiceNew invoice="return" />
        </FormTab>
        <FormTab label="resources.root.dollarReturn">
          <McSellInvoiceNew invoice="return" />
        </FormTab>
      </TabbedForm>
    )}
  </Create>
);

export const SellReturnInvoicesEdit = () => {
  const notify = useNotify();
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const [sellReturnInvoices, setSellReturnInvoices] = React.useState([]);
  const [sellReturnOrders, setSellReturnOrders] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [payByMain, setPayByMain] = React.useState(true);
  const [invoiceNumber, setInvoiceNumber] = React.useState();
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("orders")
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [productDiscount, setProductDiscount] = React.useState(0);
  const [gift, setGift] = React.useState(0);
  const [productNote, setProductNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [previousSellPrice, setPreviousSellPrice] = React.useState(null);
  const translate = useTranslate();

  React.useEffect(() => {
    HTTP.get(`${url}/SellReturnInvoices/${id}`).then((res) => {
      setSellReturnInvoices(res.data);
      setSellReturnOrders(res.data.sellReturnOrders);
    });
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      sellReturnInvoices &&
      HTTP.get(
        `${url}/SellReturnInvoices/GetLastPriceOfProduct?customerId=${sellReturnInvoices.customerId}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousSellPrice(res.data);
      });
  }, [sellReturnInvoices, selectedProduct]);

  React.useEffect(() => {
    localStorage.removeItem("orders");
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPrice);
  }, [modifiedPrice]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.invoice}>
          <div className={classes.invoiceCols}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container justify="space-around">
                <DateTimePicker
                  className={classes.invoiceInputs}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  value={selectedDate}
                  onChange={(data) => setSelectedDate(data)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
            <MaterialTextField
              style={{ display: "none" }}
              label="Invoice No."
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              className={classes.invoiceNumberInput}
            />
          </div>

          <div className={classes.invoiceCols}>
            <Chip
              icon={<AssignmentIndIcon />}
              label={
                sellReturnInvoices.customer
                  ? `${sellReturnInvoices.customer.firstName} ${sellReturnInvoices.customer.middleName}`
                  : "Name"
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              icon={<PhoneIphoneIcon />}
              label={
                sellReturnInvoices.customer
                  ? sellReturnInvoices.customer.phone
                  : "Phone"
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              // icon={<LocalAtmIcon />}
              label={
                sellReturnInvoices.customer
                  ? `Current Loan ${
                      sellReturnInvoices.customer.mcCurrentLoan
                    } ${
                      sellReturnInvoices.customer.payLoanByMain ? "USD" : " IQD"
                    }`
                  : "Current Loan"
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              // icon={<LocalAtmIcon />}
              label={
                sellReturnInvoices.customer
                  ? `Max Loan ${sellReturnInvoices.customer.mcRoofLoan} ${
                      sellReturnInvoices.customer.payLoanByMain ? "USD" : " IQD"
                    }`
                  : "Max Loan"
              }
              className={classes.bottomLeftMargin}
            />
          </div>
        </div>
        <div className={classes.invoice}>
          <div className={classes.toggles}>
            {sellReturnInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={sellReturnInvoices.payByMain}
                    // onChange={(e) => setPayByMain(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={sellReturnInvoices.payByMain ? "Dollar" : "Dinar"}
              />
            )}
            {sellReturnInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={true}
                    // onChange={(e) => setCashPayment(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Cash"
              />
            )}
            {!sellReturnInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={false}
                    // onChange={(e) => setCashPayment(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Cash"
              />
            )}
          </div>
        </div>
        <div className={classes.orders}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.orderCols}>
                  {/* <Chip
                                    label={selectedProduct ? `${selectedProduct.qteInStock} In Stock` : 'qte in Stock'}
                                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                                /> */}
                  <Chip
                    label={
                      selectedProduct
                        ? `Product Measure - ${selectedProduct.productMeasure}`
                        : "Product Measure"
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `One Measure - ${selectedProduct.oneMeasure}`
                        : "One Measure"
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `One Contains ${selectedProduct.oneContains} Items`
                        : "One Contains"
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `Total Price - $${
                            selectedProduct.mcSellPrice * orderQtt
                          }`
                        : "Total Price"
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {previousSellPrice && (
                    <Chip
                      label={
                        selectedProduct && selectedProduct.sellByMain
                          ? `${previousSellPrice.mcSellPrice?.toLocaleString()} USD`
                          : `${previousSellPrice.scSellPrice?.toLocaleString()} IQD`
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      style={{
                        backgroundColor: "lightgreen",
                        color: "black",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </div>

                <div className={classes.orderCols}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={products}
                    // inputValue={selectedProduct ? selectedProduct.name : ''}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.barcode}`
                    }
                    style={{ width: 300 }}
                    onChange={(el, val) => {
                      setSelectedProduct(val);
                      setOrderQtt(0);
                    }}
                    renderInput={(params) => (
                      <MaterialTextField
                        {...params}
                        label="Choose Product"
                        variant="outlined"
                      />
                    )}
                  />
                  <MaterialTextField
                    type="number"
                    label="Quantity"
                    disabled={!selectedProduct}
                    value={orderQtt}
                    onChange={(e) => {
                      ((parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        gift <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                        e.target.value >= 0) ||
                        selectedProduct.canSellBellowZero) &&
                        setOrderQtt(e.target.value);
                    }}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    label="Max"
                    disabled={true}
                    value={selectedProduct ? selectedProduct.qteInStock : 0}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label="Price"
                    disabled={!selectedProduct}
                    value={
                      modifiedPrice <= 0
                        ? selectedProduct
                          ? selectedProduct.mcSellPrice
                          : 0
                        : modifiedPrice
                    }
                    onChange={(e) =>
                      setModifiedPrice(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  {/* <MaterialTextField
                                    type="number"
                                    label="Discount"
                                    disabled={!selectedProduct}
                                    value={productDiscount}
                                    onChange={(e) => (parseFloat(e.target.value) >= 0 && (parseFloat(e.target.value) <= (modifiedPrice <= 0 ? (selectedProduct ? parseFloat(selectedProduct.mcSellPrice) : 0) : modifiedPrice))) && setProductDiscount(parseFloat(e.target.value))}
                                    className={classes.orderNumberInput}
                                /> */}
                  <MaterialTextField
                    type="number"
                    label="Gifts"
                    disabled={!selectedProduct}
                    value={gift}
                    onChange={(e) =>
                      parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        parseFloat(orderQtt) <=
                        (selectedProduct &&
                          parseFloat(selectedProduct.qteInStock)) &&
                      e.target.value >= 0 &&
                      setGift(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <Button
                    variant="contained"
                    className={classes.botton}
                    disabled={orderQtt <= 0 || !selectedProduct}
                    onClick={() => {
                      const sc = localStorage.getItem("dollarPrice");
                      HTTP.post(`${url}/SellReturnOrders`, {
                        sellReturnInvoiceId: sellReturnInvoices.id,
                        productId: selectedProduct.id,
                        mcPurchasePrice: selectedProduct.mcPurchasePrice,
                        scPurchasePrice: selectedProduct.scPurchasePrice,
                        productMeasure: selectedProduct.productMeasure,
                        oneContains: selectedProduct.oneContains,
                        oneMeasure: selectedProduct.oneMeasure,
                        mcDiscount: discount,
                        scDiscount: discount * sc,
                        giftQte: gift,
                        mcLastPrice: selectedProduct.mcSellPrice - discount,
                        scLastPrice:
                          selectedProduct.scSellPrice - discount * sc,
                        payByMain: payByMain,
                        barcode: selectedProduct.barcode,
                        name: selectedProduct.name,
                        qte: orderQtt,
                        mcSellPrice: selectedProduct.mcSellPrice,
                        scSellPrice: selectedProduct.scSellPrice,
                        mcTotalPrice: selectedProduct.mcSellPrice * orderQtt,
                        scTotalPrice: selectedProduct.scSellPrice * orderQtt,
                      })
                        .then((res) => {
                          HTTP.get(`${url}/SellReturnInvoices/${id}`).then(
                            (res) => {
                              setSellReturnInvoices(res.data);
                              setSellReturnOrders(res.data.sellReturnOrders);
                            }
                          );
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                      setOrderQtt(0);
                      setOrderToEdit(-1);
                      setGift(0);
                    }}
                  >
                    Add Order
                  </Button>
                </div>

                <div className={classes.orderCols}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      stickyHeader
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Barcode</TableCell>
                          <TableCell align="right">Name</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Gifts</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Discount</TableCell>
                          <TableCell align="right">Final Price</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.scrollable}>
                        {sellReturnOrders &&
                          sellReturnOrders.map((order) => (
                            <TableRow key={order.product.barcode}>
                              <TableCell component="th" scope="row">
                                {order.product.barcode}
                              </TableCell>
                              <TableCell align="right">
                                {order.product.name}
                              </TableCell>
                              <TableCell align="right">{order.qte}</TableCell>
                              <TableCell align="right">
                                {order.giftQte}
                              </TableCell>
                              <TableCell align="right">
                                {sellReturnInvoices.payByMain
                                  ? roundToTwo(order.mcSellPrice)
                                  : roundToTwo(order.scSellPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {sellReturnInvoices.payByMain
                                  ? roundToTwo(order.mcDiscount)
                                  : roundToTwo(order.scDiscount)}
                              </TableCell>
                              <TableCell align="right">
                                {sellReturnInvoices.payByMain
                                  ? roundToTwo(order.mcLastPrice)
                                  : roundToTwo(order.scLastPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {sellReturnOrders.length > 1 && (
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: "Are you sure?",
                                        text: "Deleted records cannot be recovered!",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `${url}/SellReturnOrders/${order.id}`
                                          ).then((res) => {
                                            sellReturnOrders((prev) => {
                                              return prev.filter(
                                                (el) =>
                                                  el.id !=
                                                  order.sellReturnOrders.id
                                              );
                                            });
                                          });
                                        } else {
                                          swal("Deletion Dismissed!");
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Chip
                    label={`Total Price - $${sellReturnOrders
                      .map((el) => {
                        return (
                          ((
                            cashPayment
                              ? cashPayment
                              : sellReturnInvoices.payByMain
                          )
                            ? parseFloat(el.mcSellPrice)
                            : parseFloat(el.scSellPrice)) * parseFloat(el.qte)
                        );
                      })
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`Final Price - $${
                      sellReturnOrders
                        .map(
                          (el) =>
                            ((
                              cashPayment
                                ? cashPayment
                                : sellReturnInvoices.payByMain
                            )
                              ? parseFloat(el.mcSellPrice)
                              : parseFloat(el.scSellPrice)) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0) - discount
                    }`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`Total Quantity - $${sellReturnOrders
                      .map((el) => parseFloat(el.qte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`Total Gifts - $${sellReturnOrders
                      .map((el) => parseFloat(el.giftQte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={`Final Quantity - $${sellReturnOrders
                      .map((el) => parseFloat(el.qte) + parseFloat(el.giftQte))
                      .reduce((a, b) => a + b, 0)}`}
                    className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                  />
                  <MaterialTextField
                    type="number"
                    className={classes.inPercentage}
                    label="Discount"
                    disabled={true}
                    value={
                      sellReturnInvoices.payByMain
                        ? sellReturnInvoices.mcDiscount
                        : sellReturnInvoices.scDiscount
                    }
                    variant="filled"
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <=
                          orders
                            .map(
                              (el) =>
                                parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0) &&
                        setDiscount(e.target.value);
                      setPercentageDiscount(
                        parseFloat(
                          (discount * 100) /
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => roundToTwo(a + b), 0)
                        )
                      );
                    }}
                    // className={classes.discount}
                  />
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                </div>
                <div className={classes.orderCols}>
                  <MaterialTextField
                    label="Note"
                    value={sellReturnInvoices.note}
                    disabled={true}
                    // onChange={(e) => setProductNote(e.target.value)}
                    className={classes.noteInput}
                  />
                  <MaterialTextField
                    label="Driver"
                    value={sellReturnInvoices.driver}
                    disabled={true}
                    // onChange={(e) => setDriver(e.target.value)}
                    className={classes.orderTextInput}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export const SellReturnInvoicesShow = () => {
  const classes = useStyles();
  const { id } = useParams();
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/SellReturnInvoices/${id}`).then((res) => {
      setOrders(res.data.sellReturnOrders);
      setInvoice(res.data);
    });
  }, [props]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.sellReturnInvoice")}
              order={invoice.sellReturnOrders}
              human={invoice.customer}
            />
          </div>
        </Print>
        <NoPrint force>
          {/* <LocaleSwitcher alterDir={props.options.alterDir}/> */}
          <Show>
            <TabbedShowLayout>
              <Tab label="Invoice">
                <FunctionField
                  label="Name"
                  render={(record) =>
                    `${record.customer.firstName} ${record.customer.middleName} ${record.customer.lastName}`
                  }
                />
                <TextField source="totalQte" label="Total Quantity" />
                <TextField source="totalGiftQte" label="Gift Quantity" />
                <FunctionField
                  label="Final Quantity"
                  render={(record) =>
                    `${record.totalQte + record.totalGiftQte}`
                  }
                />
                <TextField source="note" />
                <TextField source="driver" />
                {invoice.payByMain ? (
                  <TextField source="mcTotalPrice" label="Total Price" />
                ) : (
                  <TextField source="scTotalPrice" label="Total Price" />
                )}
                {invoice.payByMain ? (
                  <TextField source="mcFinalPrice" label="Final Price" />
                ) : (
                  <TextField source="scFinalPrice" label="Final Price" />
                )}
                {invoice.payByMain ? (
                  <TextField source="mcFinalDiscount" label="Final Discount" />
                ) : (
                  <TextField source="scFinalDiscount" label="Final Discount" />
                )}
              </Tab>
              <Tab label="Orders">
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.serial")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              {order.product.name}
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {order.productSerial?.serialNumber}
                              </TableCell>
                            )}
                            <TableCell align="right">{order.qte}</TableCell>
                            <TableCell align="right">{order.giftQte}</TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSellPrice
                                : order.scSellPrice}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice
                                : order.scLastPrice}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>

          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              // var pri = document.getElementById(`ifmcontentstoprint`)
              // .contentWindow;
              // pri.document.open();
              // pri.document.write(
              // document.getElementById(`cachFrame`).innerHTML
              // );
              // pri.document.write(
              // "<hr />"
              // );
              // pri.document.write(
              // document.getElementById(`cachFrame`).innerHTML
              // );
              // pri.document.close();
              // pri.focus();
              // pri.print();
              window.print();
            }}
          >
            Print
          </Button>
          <Button
            variant="contained"
            className={`${classes.botton} ${classes.bottomLeftMargin} mx-3`}
            onClick={() => {
              let ords = orders.map((el) => ({
                productId: el.productId,
                giftQte: el.giftQte,
                scLastPrice: el.scLastPrice,
                mcLastPrice: el.mcLastPrice,
                payByMain: el.payByMain,
                barcode: el.product.barcode,
                name: el.product.name,
                productSerial: el.productSerial,
                qte: el.qte,
                scPurchasePrice: el.scPurchasePrice,
                mcPurchasePrice: el.mcPurchasePrice,
                scDiscount: el.scDiscount,
                mcDiscount: el.mcDiscount,
                scSellPrice: el.scSellPrice,
                mcSellPrice: el.mcSellPrice,
                scTotalPrice: el.scLastPrice,
                mcTotalPrice: el.mcLastPrice,
                note: el.note,
              }));

              if (invoice.payByMain) {
                if (
                  localStorage.getItem("mcInvoiceReturnOrders") &&
                  JSON.parse(localStorage.getItem("mcInvoiceReturnOrders"))
                    .length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "mcInvoiceReturnOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/SellReturnInvoices/create/1");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "mcInvoiceReturnOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/SellReturnInvoices/create/1");
                }
              } else {
                if (
                  localStorage.getItem("scInvoiceReturnOrders") &&
                  JSON.parse(localStorage.getItem("scInvoiceReturnOrders"))
                    .length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "scInvoiceReturnOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/SellReturnInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem(
                    "scInvoiceReturnOrders",
                    JSON.stringify(ords)
                  );
                  window.open("#/SellReturnInvoices/create");
                }
              }
            }}
          >
            {translate("resources.root.copy")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
