import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import Datetime from "react-datetime";
import { HTTP } from "../../axios";

export default (CashierSells) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [cashier, setCashier] = React.useState(0);
  const [cashierObject, setCashierObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD") + " 00:00:00"
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD") + " 23:59:59"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isReturn, setIsReturn] = React.useState(false);
  const [enableSubLevel, setEnableSubLevel] = React.useState(false);
  const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    else sendDates += `&startDate=${moment().format("DD/MM/YYYY")}`;

    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;
    else sendDates += `&endDate=${moment().format("DD/MM/YYYY")}`;

    let returnQuickSellInvoice = "";
    if (isReturn) {
      returnQuickSellInvoice = "Return";
    }
    let subLevel = "";
    if (cashier !== 0) {
      subLevel = `&enableSubLevel=${enableSubLevel}&subLevelStringId=${cashier}`;
    }
    await HTTP.get(
      `${url}/QuickSell${returnQuickSellInvoice}Invoices?_start=${start}&_end=${end}${subLevel}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
        setMcTotalPrice(parseFloat(response.headers["mctotalprice"]));
        setScTotalPrice(parseFloat(response.headers["sctotalprice"]));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [cashier, isReturn, startDate, endDate, start, end, enableSubLevel]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/UserStoreBankAccounts?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((cashier) => {
        newArray.push({
          value: cashier.id,
          label: `${cashier.firstName} ${
            cashier.middleName
          } (${cashier.phone.replace("+964", "0")})`,
          cashier: cashier,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`cashierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.cashierSells")}{" "}
              {isReturn ? " - Return" : ""}
              {cashier === 0
                ? ""
                : "(" +
                  cashierObject.firstName +
                  " " +
                  cashierObject.middleName +
                  ")"}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }}>
              <Col xs={1}>
                <label>{translate("resources.root.cashiers")}</label>
              </Col>
              <Col xs={2}>
                <AsyncSelect
                  isClearable
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    if (e === null) {
                      setEnableSubLevel(false);
                    } else {
                      setEnableSubLevel(true);
                      setCashier(e ? e.value : 0);
                      setCashierObject(e ? e.cashier : 0);
                    }
                  }}
                />
              </Col>

              <Col xs={3}>
                <Form.Group as={Row} controlId="startDate">
                  <Form.Label column sm={3}>
                    {translate("resources.root.startDate")}
                  </Form.Label>
                  <Col sm={9}>
                    <Datetime
                      initialValue={startDate}
                      defaultShow={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      onChange={(e) => {
                        setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group as={Row} controlId="endDate">
                  <Form.Label column sm={3}>
                    {translate("resources.root.endDate")}
                  </Form.Label>
                  <Col sm={9}>
                    <Datetime
                      initialValue={endDate}
                      defaultShow={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      onChange={(e) => {
                        setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} controlId="showInUSD">
                  <Form.Check
                    type="switch"
                    label={translate("resources.root.showCashierReturns")}
                    onChange={(e) => setIsReturn(e.target.checked)}
                    defaultChecked={isReturn}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>
                  {translate("resources.root.totalPrice")}: $
                  {mcTotalPrice && mcTotalPrice?.toLocaleString()}
                </h5>
              </Col>
              <Col>
                <h5>
                  {translate("resources.root.totalPrice")}:{" "}
                  {scTotalPrice && scTotalPrice?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`change`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                    <th>{translate("resources.root.discount")}</th>
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.smallMeasureQty")}</th>
                    <th>{translate("resources.root.cashPrice")}</th>
                    <th>{translate("resources.root.returnPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a
                              href={`#/Cashier/SellInfo/${one.id}/show${
                                isReturn ? "/return" : ""
                              }`}
                            >
                              {one.invoiceNumber ?? "-"}
                            </a>
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.scTotalPrice?.toLocaleString()}</td>
                          <td>{one.scInvoiceDiscount?.toLocaleString()}</td>
                          <td>{one.totalQte?.toLocaleString()}</td>
                          <td>{one.totalSmallMeasureQte?.toLocaleString()}</td>
                          <td>
                            {one.mcCashPrice > 0
                              ? "$" + one.mcCashPrice?.toLocaleString()
                              : one.scCashPrice?.toLocaleString() + " IQD"}
                          </td>
                          <td>{one.scReturnPrice?.toLocaleString()}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
