import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import Select from "react-select";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";
import { toCsv } from "../CustomComponents/Helpers";

export default () => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState({});

  const [employee, setEmployee] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    let employeeId = "";
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;
    if (employee !== 0)
      employeeId = `&enableSubLevel=true&subLevelId=${employee}`;

    await HTTP.get(
      `${url}/EmployeeSalaryPays?_start=${start}&_end=${end}${sendDates}${employeeId}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }
  React.useEffect(function () {
    setIsLoading(true);
    getEmployees("");
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );
  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  function getEmployees(value) {
    HTTP.get(`${url}/Employees?_end=10000&search=${value}`)
      .then((response) => {
        let newArray = [];
        newArray.push({
          value: 0,
          label: translate("resources.root.all"),
          employee: {},
        });
        response.data.map((employee) => {
          newArray.push({
            value: employee.id,
            label: `${employee.firstName} ${employee.middleName} ${employee.lastName}`,
            employee: employee,
          });
        });
        setEmployees(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }

  return (
    <div className="m-2">
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.salaryPayReport")}
            </h1>
          </div>
          <Row className="px-4 px-lg-0">
            {isLoading && <LoadingScreen />}

            <Col className="col-12 col-lg-4 my-3 d-flex justify-content-start align-items-center">
              <p className="p-0 m-0">
                {translate("resources.root.employee")}: &nbsp;
              </p>
              <Select
                name="colors"
                isClearable
                isSearchable
                className="basic-multi-select w-75 "
                classNamePrefix="select"
                options={employees}
                onChange={(e) => setEmployee(e ? e.value : 0)}
              />
            </Col>
            <Col className="row col-12 col-lg-8 align-items-center">
              <Col className="col-12 col-md-6 my-3">
                <Form.Group controlId="startDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2 text-nowrap">
                      {translate("resources.root.startDate")} :
                    </Form.Label>
                    <Form.Control
                      className=""
                      label="startDate"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6 my-3">
                <Form.Group controlId="endDate">
                  <Col className="d-flex align-items-center m-0 p-0">
                    <Form.Label className="m-0 mr-2  text-nowrap">
                      {translate("resources.root.endDate")} :
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Col>
            {process.env.APP_SINGLE_CURRENCY === "true" ? (
              process.env.APP_SHOW_MC ? (
                <Col className="col-12 col-md-6 col-lg-3  d-flex my-3 justify-content-around align-items-center">
                  <Col className="">
                    <h5>
                      {translate("resources.root.total")} :
                      {totalPrice.mc?.toLocaleString()}$
                    </h5>
                  </Col>
                </Col>
              ) : (
                <Col className="col-12 col-md-6 col-lg-3  d-flex my-3 justify-content-around align-items-center">
                  <Col className="">
                    <h5>
                      {translate("resources.root.total")} :
                      {totalPrice.sc?.toLocaleString()}IQD
                    </h5>
                  </Col>
                </Col>
              )
            ) : (
              <Col className="col-12 col-md-6 col-lg-3  d-flex my-3 justify-content-around align-items-center">
                <Col className="">
                  <h5>
                    {translate("resources.root.total")} :
                    {totalPrice.mc?.toLocaleString()}$
                  </h5>
                </Col>
                <Col className="">
                  <h5>
                    {translate("resources.root.total")} :
                    {totalPrice.sc?.toLocaleString()} IQD
                  </h5>
                </Col>
              </Col>
            )}
            <Col className="col-12 col-md-6 col-lg-3  my-3 ">
              <Button
                className="btn-block"
                disabled={employee === 0}
                id="export"
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col className="col-12 col-md-6 col-lg-3  my-3 ">
              <Button
                className="btn-block"
                disabled={employee === 0}
                variant={"outline-primary"}
                onClick={(e) => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";
                  window.print();
                  sidebar[0].style.display = "block";
                  appMenu[0].style.display = "block";
                  appBar[0].style.marginTop = "40px";
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}
              </Button>
            </Col>
            <Col className="col-12 col-md-6 col-lg-3  my-3 ">
              <Button
                className="btn-block"
                id="export"
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate("resources.root.search")}
              </Button>
            </Col>
          </Row>
          <Row className={`mt-2`}>
            <Col>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.employee")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.note")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.accountant")}</th>
                    <th>{translate("resources.root.tookBy")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a href={`#/Employees/${one.id}/show`}>
                              {`${one.employee.firstName} ${one.employee.middleName} ${one.employee.lastName}`}
                            </a>
                          </td>
                          <td>{one.invoiceNumber ?? "-"}</td>
                          <td>{one.byMain ? one.mcPrice : one.scPrice}</td>
                          <td>{one.note ?? "-"}</td>
                          <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                          <td>{one.accountant}</td>
                          <td>{one.tookBy ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
