import React, { Component } from "react";
import { Box, Typography, Button, ListItem, withStyles } from "@mui/material";

// export default function Child({childToParent}) {
//   return (
//       <div>
//           <Button primary onClick={() => childToParent()}>Click Child</Button>
//       </div>
//   )
// }

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    // this.upload = this.upload.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.state = {
      currentFile: undefined,
      previewImage: undefined,
    };
  }

  onUpload(file) {
    console.log("uploadImage file", file);
    return file;
  }

  toBase64(file) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      return fileReader.result;
    };
  }

  selectFile(event) {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
    const file = event.target.files[0];
    const base64 = this.toBase64(file);
    this.onUpload(base64);
  }

  render() {
    const { currentFile, previewImage } = this.state;

    return (
      <div className="mg20">
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={this.selectFile}
          />
          <Button className="btn-choose" variant="outlined" component="span">
            Choose Image
          </Button>
        </label>
        <div className="file-name">{currentFile ? currentFile.name : null}</div>

        {previewImage && (
          <div>
            <img className="preview my20" src={previewImage} alt="" />
          </div>
        )}
      </div>
    );
  }
}
