import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  useTranslate,
  EditButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { ListActions } from "../templates/ListActions";

export const DepartmentsList = (props) => {
  const translate = useTranslate();
  return (
    <List
      actions={<ListActions hasCreate="department.list" />}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <TextField source="id" label={translate("resources.root.id")} />
        <TextField source="name" label={translate("resources.root.name")} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const DepartmentsCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" label={translate("resources.root.name")} />
      </SimpleForm>
    </Create>
  );
};

export const DepartmentsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate("resources.root.name")}
          margin="normal"
          variant="outlined"
        />
      </SimpleForm>
    </Edit>
  );
};
