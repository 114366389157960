import * as React from "react";
import {
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { NoPrint } from "react-easy-print";
import { BiDollar } from "react-icons/bi";
import { FaHistory, FaPrint } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";
import { GoListOrdered } from "react-icons/go";

import { hasPermission } from "../../App";

export const ListActions = (props) => {
  const classes = useStyles();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } =
    useListContext();

  return (
    <NoPrint>
      <TopToolbar
        {...sanitizeListRestProps(rest)}
        className="my-3 d-flex align-items-center justify-content-center gap-1"
      >
        {/*<ButtonGroup className={classes.btnGroup} size="small" variant="contained">*/}
        <p className="B-O-P d-flex justify-content-center align-items-center gap-1">
          <GoListOrdered /> {total ?? 0}
        </p>
        {process.env.REACT_APP_SINGLE_CURRENCY === "false" &&
          props.currency && (
            <>
              <button
                className={`d-flex justify-content-center align-items-center gap-1 ${
                  props.currency === "default" ? "AB-O-P" : "B-O-P"
                }`}
                onClick={() => props.setCurrency("default")}
              >
                <FaHistory />
              </button>
              <button
                className={`d-flex justify-content-center align-items-center gap-1 ${
                  props.currency === "dollar" ? "AB-O-P" : "B-O-P"
                }`}
                onClick={() => props.setCurrency("dollar")}
              >
                <BiDollar />
              </button>
              <button
                className={`d-flex justify-content-center align-items-center gap-1 ${
                  props.currency === "dinar" ? "AB-O-P" : "B-O-P"
                }`}
                onClick={() => props.setCurrency("dinar")}
              >
                {" "}
                <GiTwoCoins />
              </button>
            </>
          )}
        <PrintButton />
        {/* <button className="btn B-O-P d-flex justify-content-center align-items-center gap-1 ">
          {filters &&
            React.cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: "button",
            })}
        </button> */}
        <ExportButton
          maxResults={3000}
          className="B-O-P d-flex justify-content-center align-items-center gap-1 "
        />

        {!props?.createNoPermission &&
          hasPermission(`permissions.${props.hasCreate}`) && (
            <CreateButton className="btn B-O-P d-flex justify-content-center align-items-center gap-1 " />
          )}
        {/*</ButtonGroup>*/}
      </TopToolbar>
    </NoPrint>
  );
};

export const PrintButton = () => {
  const classes = useStyles();
  return (
    <NoPrint>
      <button
        className="B-O-P d-flex justify-content-center align-items-center gap-1 "
        onClick={() => {
          let sidebar = document.getElementsByClassName("MuiDrawer-root");
          let appMenu = document.getElementsByClassName("MuiPaper-root");
          let appBar = document.getElementsByClassName("theRoot");
          sidebar[0].style.display = "none";
          appMenu[0].style.display = "none";
          appBar[0].style.marginTop = "0px";
          window.print();
          sidebar[0].style.display = "block";
          appMenu[0].style.display = "block";
          appBar[0].style.marginTop = "40px";
        }}
      >
        <FaPrint />
      </button>
    </NoPrint>
  );
};

const useStyles = makeStyles({
  primary: {
    margin: "0",
    backgroundColor: "#1976d2",
    borderRadius: 0,
    borderRight: "1px solid #bdbdbd",
    color: "#fff",
    height: "50px !important",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
