import { useRedirect, useTranslate, FunctionField } from "react-admin";
import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import { NoPrint, Print, PrintProvider } from "react-easy-print";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { toCsv } from "../CustomComponents/Helpers";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { HTTP } from "../../axios";
import { path } from "../../request";

const ReportStock = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [brand, setBrand] = React.useState(0);
  const [category, setCategory] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let $category = "";
    if (category !== 0) {
      $category = `&subLevelId=${category}`;
    }
    let $brand = "";
    if (brand !== 0) {
      $brand = `&brandId=${brand}`;
    }

    await HTTP.get(
      `${url}/Products?_start=${start}&_end=${end}${$category}${$brand}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [brand, category, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadCategories = async (inputValue, callback) => {
    let response = await HTTP.get(
      `${url}/ProductCategories?_end=5000&search=${inputValue}`
    );
    let newArray = [];
    response.data.map((category) => {
      newArray.push({
        value: category.id,
        label: category.name,
        category: category,
      });
    });
    callback(newArray);
  };

  const loadBrands = async (inputValue, callback) => {
    let response = await HTTP.get(
      `${url}/Brands?_end=5000&search=${inputValue}`
    );
    let newArray = [];
    response.data.map((brand) => {
      newArray.push({
        value: brand.id,
        label: brand.name,
        brand: brand,
      });
    });
    callback(newArray);
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.reportStock")}
            </h1>
          </div>
          <NoPrint force>
            <Row>
              <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.category")} :
                </p>
                <AsyncSelect
                  isClearable
                  defaultOptions
                  className="w-100"
                  loadOptions={loadCategories}
                  onChange={(e) => setCategory(e ? e.value : 0)}
                />
              </Col>
              {/* <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.brand")} :
                </p>
                <AsyncSelect
                  isClearable
                  defaultOptions
                  className="w-100"
                  loadOptions={loadBrands}
                  onChange={(e) => setBrand(e ? e.value : 0)}
                />
              </Col> */}
              <Col className="col-4 col-lg-2 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 col-lg-2 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.barcode")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.category")}</th>
                    {/* <th>{translate("resources.root.brand")}</th> */}
                    <th>{translate("resources.root.preferedName")}</th>
                    <th>{translate("resources.root.qteInStock")}</th>
                    <th>{translate("resources.root.purchasePrice")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.smallMeasureQty")}</th>
                    <th>{translate("resources.root.image")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.barcode}
                            </a>
                          </td>
                          <td>{one.name}</td>
                          <td>{one.productCategoryLabel}</td>
                          {/* <td>{one.brandName}</td> */}
                          <td>{one.preferName}</td>
                          <td>{one.qteInStock}</td>
                          <td>
                            {one.purchaseByMain
                              ? `$${one.mcPurchasePrice?.toLocaleString()}`
                              : `${one.scPurchasePrice?.toLocaleString()} ${translate(
                                  "resources.root.iqd"
                                )}`}
                          </td>
                          <td>
                            {one.sellByMain
                              ? `$${one.mcSellPrice?.toLocaleString()}`
                              : `${one.scSellPrice?.toLocaleString()} ${translate(
                                  "resources.root.iqd"
                                )}`}
                          </td>
                          <td>{one.smallMeasureQte}</td>
                          <td>
                            <img
                              style={{
                                width: 125,
                                height: 125,
                                objectFit: "scale-down",
                                cursor: "pointer",
                              }}
                              src={
                                one.attachment !== null
                                  ? `${path}${one.attachment}`
                                  : `https://via.placeholder.com/125`
                              }
                              alt={one.name}
                              onClick={() =>
                                window.open(
                                  one.attachment !== null
                                    ? `${path}${one.attachment}`
                                    : `https://via.placeholder.com/125`,
                                  "_blank"
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                      <option value={10000}>10000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};

export default ReportStock;
