import React from "react";
import { url, handleRequestResponse } from "../../request";
import { useRedirect, useTranslate } from "react-admin";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TwitterPicker } from "react-color";
import Select from "react-select";
import logos from "../../assets/logos";
import { HTTP } from "../../axios";

export const ProductOffers = () => {
  const [product, setProduct] = React.useState(null);
  const [selectProducts, setSelectProducts] = React.useState([]);
  const [newPrice, setNewPrice] = React.useState(1000);
  const redirect = useRedirect();
  const translate = useTranslate();

  const [offerText, setOfferText] = React.useState("Offer");
  const [offerFontSize, setOfferFontSize] = React.useState(32);
  const [nameFontSize, setNameFontSize] = React.useState(32);
  const [priceFontSize, setPriceFontSize] = React.useState(32);
  const [widthSize, setWidthSize] = React.useState(32);
  const [heightSize, setHeightSize] = React.useState(32);
  const [nameColor, setNameColor] = React.useState(
    localStorage.getItem("nameColor") ?? "#000000"
  );
  const [priceColor, setPriceColor] = React.useState(
    localStorage.getItem("priceColor") ?? "#000000"
  );
  const [offerColor, setOfferColor] = React.useState(
    localStorage.getItem("barcodeColor") ?? "#000000"
  );

  function PrintElem() {
    let mywindow = window.open("", "PRINT", "height=1000,width=1800");

    mywindow.document.write(
      '<html lang="ku"><head><title>' + document.title + "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(document.getElementById("printableDiv").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    mywindow.close();

    return true;
  }

  React.useEffect(function () {
    HTTP.get(`${url}/Products?_start=0&_end=150000`)
      .then((response) => {
        let newArray = [];
        response.data.map((product) => {
          newArray.push({
            value: product.id,
            label: `${product.name} (${product.barcode})`,
            product: product,
          });
        });
        setSelectProducts(newArray);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
  }, []);
  return (
    <div style={{ textAlign: "start" }}>
      <Button variant={"primary"} onClick={PrintElem}>
        {translate("resources.root.print")}
      </Button>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row} controlId="offerText">
            <Form.Label column sm={6}>
              {translate("resources.root.offerText")}
            </Form.Label>
            <Col>
              <Form.Control
                type="text"
                placeholder={translate("resources.root.offerText")}
                onChange={(e) => {
                  localStorage.setItem("offerText", e.target.value);
                  setOfferText(e.target.value);
                }}
                defaultValue={localStorage.getItem("offerText") ?? "Offer"}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="offerFontSize">
            <Form.Label column sm={6}>
              {translate("resources.root.offerFontSize")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.offerFontSize")}
                onChange={(e) => {
                  localStorage.setItem("offerFontSize", e.target.value);
                  setOfferFontSize(e.target.value);
                }}
                defaultValue={localStorage.getItem("offerFontSize") ?? 10}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="nameFontSize">
            <Form.Label column sm={6}>
              {translate("resources.root.nameFontSize")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.nameFontSize")}
                onChange={(e) => {
                  localStorage.setItem("nameFontSize", e.target.value);
                  setNameFontSize(e.target.value);
                }}
                defaultValue={localStorage.getItem("nameFontSize") ?? 10}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="widthSize">
            <Form.Label column sm={6}>
              {translate("resources.root.widthSize")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.widthSize")}
                onChange={(e) => {
                  localStorage.setItem("widthSize", e.target.value);
                  setWidthSize(e.target.value);
                }}
                defaultValue={localStorage.getItem("widthSize") ?? widthSize}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="heightSize">
            <Form.Label column sm={6}>
              {translate("resources.root.heightSize")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.heightSize")}
                onChange={(e) => {
                  localStorage.setItem("heightSize", e.target.value);
                  setHeightSize(e.target.value);
                }}
                defaultValue={localStorage.getItem("heightSize") ?? heightSize}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="priceFontSize">
            <Form.Label column sm={6}>
              {translate("resources.root.priceFontSize")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.priceFontSize")}
                onChange={(e) => {
                  localStorage.setItem("priceFontSize", e.target.value);
                  setPriceFontSize(e.target.value);
                }}
                defaultValue={localStorage.getItem("priceFontSize") ?? 10}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group as={Row} controlId="nameColor">
            <Form.Label column sm={6}>
              {translate("resources.root.nameColor")}
            </Form.Label>
            <TwitterPicker
              color={localStorage.getItem("nameColor") ?? nameColor}
              onChange={(e) => {
                setNameColor(e.hex);
                localStorage.setItem("nameColor", e.hex);
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group as={Row} controlId="priceColor">
            <Form.Label column sm={6}>
              {translate("resources.root.priceColor")}
            </Form.Label>
            <TwitterPicker
              color={localStorage.getItem("priceColor") ?? priceColor}
              onChange={(e) => {
                setPriceColor(e.hex);
                localStorage.setItem("priceColor", e.hex);
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group as={Row} controlId="offerColor">
            <Form.Label column sm={6}>
              {translate("resources.root.offerColor")}
            </Form.Label>
            <TwitterPicker
              color={localStorage.getItem("offerColor") ?? offerColor}
              onChange={(e) => {
                setOfferColor(e.hex);
                localStorage.setItem("offerColor", e.hex);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row}>
            <Form.Label column sm={6}>
              {translate("resources.root.newPrice")}
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                placeholder={translate("resources.root.newPrice")}
                onChange={(e) => {
                  setNewPrice(e.target.value);
                }}
                defaultValue={newPrice}
                onFocus={(e) => e.target.select()}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {translate("resources.root.products")}
            </Form.Label>
            <Col>
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                name="colors"
                isClearable
                isSearchable
                classNamePrefix="select"
                options={selectProducts}
                onChange={(e) => {
                  setProduct(e.product);
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <div id={`printableDiv`}>
        {product && (
          <div
            style={{
              width: "28cm",
              height: "19cm",
              display: "inline-block",
              padding: "5px",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                overflow: "hidden",
                marginBottom: 0,
                color: offerColor,
                fontSize: offerFontSize + "px" ?? 32,
              }}
            >
              {offerText}
            </h1>
            {process.env.REACT_APP_SHOW_LOGO === "true" && (
              <img
                alt=""
                src={localStorage.getItem("logoUrl") ?? logos.logo}
                style={{
                  width: widthSize + "%",
                  height: heightSize + "%",
                }}
              />
            )}
            <p
              style={{
                overflow: "hidden",
                marginBottom: 0,
                color: nameColor,
                fontSize: nameFontSize + "px" ?? 32,
              }}
            >
              {product.preferName.slice(0, 30)}
            </p>
            <p
              style={{
                overflow: "hidden",
                marginBottom: 0,
                color: priceColor,
                fontSize: priceFontSize + "px" ?? 32,
              }}
            >
              {newPrice?.toLocaleString() + " IQD"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
