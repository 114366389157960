export const url = process.env.REACT_APP_URL;
export const path = process.env.REACT_APP_PATH;

export const handleRequestResponse = (notify, res) => {
  notify(
    res?.response?.data?.message ??
      res?.response?.data?.title ??
      JSON.stringify(res?.response?.data),
    {
      type: "error",
      undoable: true,
    }
  );
};
