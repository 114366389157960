import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  BooleanField,
  useRefresh,
  useTranslate,
  useResourceDefinition,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import swal from "sweetalert";
import rtl from "jss-rtl";
import { ListActions } from "../templates/ListActions";
import { KurdishSellReceite } from "../vouchers/KurdishSellReceipte";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import PrintIcon from "@mui/icons-material/Print";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            HTTP.post(`${url}/SellInvoices/GetBatchOrders`, props.selectedIds)
              .then((res) => {
                props.setOrders(res.data);
                window.print();
              })
              .catch((err) => {
                console.log(err);
                if (err) {
                  swal("Oh noes!", "The AJAX request failed!", "error");
                } else {
                  swal.stopLoading();
                  swal.close();
                }
              });
          }}
        >
          <PrintIcon /> &nbsp; {translate("resources.root.printOrders")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: `${translate("resources.root.newDriver")}`,
              content: "input",
              button: {
                text: `${translate("resources.root.update")}`,
                closeModal: false,
              },
            }).then((name) => {
              if (name) {
                HTTP.post(
                  `${url}/SellInvoices/BatchUpdateDriver?driver=${name}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal("Empty driver name is not accepted!", "", "error");
              }
            });
          }}
        >
          <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
          {translate("resources.root.updateDriver")}
        </Button>
        <BulkAction {...props} />
      </div>
    </React.Fragment>
  );
};

export const RepresentativePreInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const [orders, setOrders] = React.useState([]);

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <NoPrint force>
          <List
            filters={<SearchFilter date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions setCurrency={setCurrency} currency={currency} />
            }
          >
            <Datagrid
              bulkActionButtons={
                <BulkActionButtons
                  setOrders={setOrders}
                  hasDelete="representative.delete"
                />
              }
              rowClick={"show"}
            >
              <TextField source="id" />
              <FunctionField
                label="resources.root.name"
                render={(record) =>
                  `${record.representative.firstName} ${record.representative.middleName}`
                }
              />
              {localStorage.getItem("showInvoiceNumber") === "true" && (
                <TextField
                  source="invoiceNumber"
                  label="resources.root.invoiceNumber"
                />
              )}
              {currency === "default" && (
                <FunctionField
                  label="resources.root.price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    record.payByMain
                      ? `$${record.mcFinalPrice}`
                      : `${record.scFinalPrice} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              {currency !== "default" && (
                <FunctionField
                  label="Price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    currency === "dollar"
                      ? `$${record.mcFinalPrice}`
                      : `${record.scFinalPrice} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              <TextField source="totalQte" />
              <FunctionField
                label="resources.root.finalQuantity"
                render={(record) => record.totalQte + record.totalGiftQte}
              />
              <DateField
                source="preOrderDate"
                label="resources.root.preOrderDate"
                locales={"en-GB"}
              />
              <BooleanField
                source="delivered"
                label="resources.root.delivered"
              />
              <BooleanField source="isCash" label="resources.root.isCash" />

              {localStorage.getItem("showNote") === "true" && (
                <TextField source="note" label="resources.root.note" />
              )}

              {localStorage.getItem("showLastUser") === "true" && (
                <TextField source="lastUser" label="resources.root.lastUser" />
              )}
              {localStorage.getItem("showCreatedAt") === "true" && (
                <FunctionField
                  sortBy={"createdAt"}
                  label="resources.root.createdAt"
                  render={(record) =>
                    record.createdAt
                      ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                      : "-"
                  }
                />
              )}
            </Datagrid>
          </List>
        </NoPrint>
        <div>
          <Print single={true} name="foo">
            <div className={classes.ordersPrintWrapper}>
              <div style={{ marginBottom: 20 }}>
                {translate("resources.root.date")}:{" "}
                {moment().format("DD-MM-YYYY")}
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">
                        {translate("resources.root.name")}Name
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.barcode")}Barcode
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.quantity")}Quantity
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.giftQuantity")}Gift Quantity
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.driver")}Driver
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order, key) => (
                      <TableRow key={order.name}>
                        <TableCell component="th" scope="row">
                          {key + 1}
                        </TableCell>
                        <TableCell align="right">{order.name}</TableCell>
                        <TableCell align="right">{order.barcode}</TableCell>
                        <TableCell align="right">{order.qty}</TableCell>
                        <TableCell align="right">{order.giftQty}</TableCell>
                        <TableCell align="right">{order.driver}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Print>
        </div>
      </PrintProvider>
    </div>
  );
};

export const RepresentativePreInvoicesShow = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = useParams();
  const props = useResourceDefinition();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/RepresentativePreInvoices/${id}`).then((res) => {
      setOrders(res.data.representativePreOrders);
      setInvoice(res.data);
    });
  }, [props]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <KurdishSellReceite
              invoice={invoice}
              title={translate("resources.root.representativePreInvoice")}
              orders={invoice.representativePreOrders}
              human={invoice.representative}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label="resources.root.invoice">
                <FunctionField
                  label="resources.root.name"
                  render={(record) =>
                    `${record.representative.firstName} ${record.representative.middleName} ${record.representative.lastName}`
                  }
                />
                <TextField
                  source="totalQte"
                  label="resources.root.totalQuantity"
                />
                <TextField
                  source="totalGiftQte"
                  label="resources.root.giftQuantity"
                />
                <FunctionField
                  label="resources.root.finalQuantity"
                  render={(record) =>
                    `${record.totalQte + record.totalGiftQte}`
                  }
                />
                <TextField source="note" label="resources.root.note" />
                <TextField source="driver" label="resources.root.driver" />
                {invoice.payByMain ? (
                  <TextField
                    source="mcTotalPrice"
                    label="resources.root.totalPrice"
                  />
                ) : (
                  <TextField
                    source="scTotalPrice"
                    label="resources.root.totalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalPrice"
                    label="resources.root.finalPrice"
                  />
                ) : (
                  <TextField
                    source="scFinalPrice"
                    label="resources.root.finalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                ) : (
                  <TextField
                    source="scFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                )}
              </Tab>
              <Tab label="resources.root.orders">
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              {order.product.name}
                            </TableCell>
                            <TableCell align="right">{order.qte}</TableCell>
                            <TableCell align="right">{order.giftQte}</TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSellPrice
                                : order.scSellPrice}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice
                                : order.scLastPrice}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              window.print();
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
