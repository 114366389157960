import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { NoPrint, Print } from "react-easy-print";
import PrintProvider from "react-easy-print/lib/es";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import { path } from "../../request";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [ignoreZero, setIgnoreZero] = React.useState(false);
  const [ignoreBellowZeroProducts, setIgnoreBellowZeroProducts] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    await HTTP.get(
      `${url}/Reports/StockAlertReport?ignoreZero=${ignoreZero}&ignoreBellowZeroProducts=${ignoreBellowZeroProducts}&_start=${start}&_end=${end}`
    )
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [ignoreZero, ignoreBellowZeroProducts, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print name={"stockAlertReport"}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.stockAlertReport")}
            </h1>
          </div>
          <NoPrint force>
            <Row className={`mt-2`}>
              {isLoading && <LoadingScreen />}
              <Col className="col-3 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setIgnoreZero(e.target.checked)}
                        defaultChecked={ignoreZero}
                        name="gilad"
                      />
                    }
                    label={translate("resources.root.ignoreZero")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-3 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) =>
                          setIgnoreBellowZeroProducts(e.target.checked)
                        }
                        name="gilad"
                        defaultChecked={ignoreBellowZeroProducts}
                      />
                    }
                    label={translate("resources.root.ignoreBellowZeroProducts")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row className="m-2 mt-4">
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.barcode")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.purchasePrice")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.qtyInStock")}</th>
                    <th>{translate("resources.root.smallMeasureQty")}</th>
                    <th>{translate("resources.root.alertQty")}</th>
                    <th>{translate("resources.root.image")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr key={one.id}>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.barcode}
                            </a>
                          </td>
                          <td>{one.name}</td>
                          <td>
                            {one.purchaseByMain
                              ? `$${one.mcPurchasePrice?.toLocaleString()}`
                              : `${one.scPurchasePrice?.toLocaleString()} IQD`}
                          </td>
                          <td>
                            {one.sellByMain
                              ? `$${one.mcSellPrice?.toLocaleString()}`
                              : `${one.scSellPrice?.toLocaleString()} IQD`}
                          </td>
                          <td>{one.qteInStock}</td>
                          <td>{one.smallMeasureQte}</td>
                          <td>{one.alertQte}</td>{" "}
                          <td>
                            <img
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "scale-down",
                                cursor: "pointer",
                              }}
                              src={
                                one.attachment !== null
                                  ? `${path}${one.attachment}`
                                  : `https://via.placeholder.com/50`
                              }
                              alt={one.name}
                              onClick={() =>
                                window.open(
                                  one.attachment !== null
                                    ? `${path}${one.attachment}`
                                    : `https://via.placeholder.com/50`,
                                  "_blank"
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <NoPrint force>
            <div style={{ position: "relative" }}>
              <InputGroup style={{ width: "200px", float: "right" }}>
                <InputGroup.Text>Per Page</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setPerPage(e.target.value)}
                  as="select"
                  custom
                >
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>{" "}
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                  <option value={10000}>10000</option>
                </Form.Control>
              </InputGroup>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText={translate("resources.root.previous")}
                nextPageText={translate("resources.root.next")}
                itemClass="page-item"
                linkClass="page-link"
              />
              <div className={`clearfix`}> </div>
            </div>
          </NoPrint>
        </Print>
      </PrintProvider>
    </div>
  );
};
