import React from "react";
import { useTranslate } from "react-admin";
import moment from "moment";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles, withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import logos from "./../../assets/logos";
import { StylesProvider, jssPreset } from "@mui/styles";
import { create } from "jss";
import rtl from "jss-rtl";

export const KurdishSellReceite = (props) => {
  const [invoice, setInvoice] = useState({});
  const locale =
    localStorage.getItem("locale") ?? process.env.REACT_APP_MEDICAL_MODULE
      ? "enHana"
      : "en";

  useEffect(() => {
    setInvoice(props.invoice);
  }, [props]);

  const translate = useTranslate();
  const classes = useStyles();
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  return (
    <StylesProvider jss={jss}>
      <Card
        className={classes.root}
        variant="outlined"
        style={{ fontSize: 20 }}
      >
        <CardContent style={{ padding: 0 }}>
          <div className={classes.gridContainer}>
            <Grid container spacing={0} style={{ textAlign: "start" }}>
              <Grid
                item
                xs={12}
                style={{
                  height: parseInt(
                    localStorage.getItem("invoiceHeaderHeight") ?? 175
                  ),
                }}
              >
                <Paper elevation={0} className={classes.paper}>
                  <div className={classes.logoWrapper}>
                    <div
                      style={{
                        width: parseInt(
                          localStorage.getItem("logoWidth") ?? 100
                        ),
                        height: parseInt(
                          localStorage.getItem("logoHeight") ?? 100
                        ),
                        borderRadius:
                          parseInt(localStorage.getItem("logoRadius") ?? 50) +
                          "%",
                        overflow: "hidden",
                        position: "absolute",
                        marginTop:
                          parseInt(
                            localStorage.getItem("logoMarginTop") ?? 275
                          ) + "px",
                        marginRight:
                          parseInt(
                            localStorage.getItem("logoMarginXAxis") ?? 20
                          ) + "px",
                        marginBottom:
                          parseInt(
                            localStorage.getItem("logoMarginBottom") ?? 160
                          ) + "px",
                        marginLeft:
                          parseInt(
                            localStorage.getItem("logoMarginXAxis") ?? 20
                          ) + "px",
                      }}
                    >
                      {process.env.REACT_APP_SHOW_LOGO === "true" && (
                        <img
                          alt=""
                          src={localStorage.getItem("logoUrl") ?? logos.logo}
                          className={classes.img}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        fontSize:
                          parseInt(
                            localStorage.getItem("phonesFontSize") ?? 18
                          ) + "px",
                        marginTop:
                          parseInt(
                            localStorage.getItem("phonesMarginTop") ?? 275
                          ) + "px",
                        marginRight:
                          parseInt(
                            localStorage.getItem("phonesMarginXAxis") ?? 145
                          ) + "px",
                        marginBottom:
                          parseInt(
                            localStorage.getItem("phonesMarginBottom") ?? 160
                          ) + "px",
                        marginLeft:
                          parseInt(
                            localStorage.getItem("phonesMarginXAxis") ?? 145
                          ) + "px",
                      }}
                    >
                      <div>
                        {locale === "en"
                          ? localStorage.getItem("addressInEnglish")
                          : locale === "kr"
                          ? localStorage.getItem("addressInKurdish")
                          : localStorage.getItem("addressInArabic")}
                      </div>
                      <div>
                        {localStorage.getItem("useCustomPhoneNumbers") ===
                        "true"
                          ? localStorage.getItem("phoneNumber1")
                          : process.env.REACT_APP_PHONE}
                      </div>
                      <div>
                        {localStorage.getItem("useCustomPhoneNumbers") ===
                        "true"
                          ? localStorage.getItem("phoneNumber2")
                          : process.env.REACT_APP_PHONE2}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Paper
                  elevation={0}
                  style={{
                    position: "absolute",
                    width: "35%",
                    fontSize:
                      parseInt(localStorage.getItem("infoFontSize") ?? 18) +
                      "px",
                    marginTop:
                      parseInt(localStorage.getItem("infoMarginTop") ?? -7) +
                      "px",
                    marginRight:
                      parseInt(localStorage.getItem("infoMarginXAxis") ?? 620) +
                      "px",
                    marginBottom:
                      parseInt(localStorage.getItem("infoMarginBottom") ?? 0) +
                      "px",
                    marginLeft:
                      parseInt(localStorage.getItem("infoMarginXAxis") ?? 620) +
                      "px",
                  }}
                >
                  <h3>{props.title || "Sell Invoice"}</h3>
                  <div>
                    {translate("resources.root.date")}:{" "}
                    {invoice && (
                      <b>{moment(invoice.date).format("YYYY-MM-DD")}</b>
                    )}
                  </div>
                  <div>
                    {translate("resources.root.invoiceNumber")}:{" "}
                    {invoice.invoiceNumber && (
                      <b>{invoice.invoiceNumber.toUpperCase()}</b>
                    )}
                  </div>
                  <div>
                    {translate("resources.root.no")}:{" "}
                    {invoice && <b>{invoice.id}</b>}
                  </div>
                </Paper>
                <Paper
                  elevation={0}
                  style={{
                    position: "absolute",
                    width: "40%",
                    fontSize:
                      parseInt(
                        localStorage.getItem("invoiceDescriptionFontSize") ?? 18
                      ) + "px",
                    marginTop:
                      parseInt(
                        localStorage.getItem("invoiceDescriptionMarginTop") ??
                          110
                      ) + "px",
                    marginRight:
                      parseInt(
                        localStorage.getItem("invoiceDescriptionMarginXAxis") ??
                          30
                      ) + "px",
                    marginBottom:
                      parseInt(
                        localStorage.getItem(
                          "invoiceDescriptionMarginBottom"
                        ) ?? 0
                      ) + "px",
                    marginLeft:
                      parseInt(
                        localStorage.getItem("invoiceDescriptionMarginXAxis") ??
                          30
                      ) + "px",
                  }}
                >
                  <p>
                    {locale === "en"
                      ? localStorage.getItem("invoiceDescriptionInEnglish")
                      : locale === "kr"
                      ? localStorage.getItem("invoiceDescriptionInKurdish")
                      : localStorage.getItem("invoiceDescriptionInArabic")}
                  </p>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <div className={classes.flexContainer}>
                    <div className={classes.flexCol}>
                      <div>
                        {translate("resources.root.name")}:{" "}
                        {props.human && (
                          <b>
                            {props.name ||
                              (props.human.firstName
                                ? `${props.human.firstName} ${props.human.middleName}`
                                : props.human.supplierName)}
                          </b>
                        )}
                      </div>
                      <div>
                        {translate("resources.root.address")}:{" "}
                        {(invoice && <b>{invoice.address}</b>) ||
                          (props.human && <b>{props.human.address}</b>)}
                      </div>
                    </div>
                    <div className={classes.flexCol}>
                      <div>
                        {translate("resources.root.phone")}:{" "}
                        {props.human && <b>{props.human.phone}</b>}
                      </div>
                      <div>
                        {translate("resources.root.driver")}:{" "}
                        {invoice && <b>{invoice.driver}</b>}
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="customized table"
                    >
                      <TableRow
                        style={{
                          backgroundColor: "#ff5252",
                          fontSize: "18px",
                          color: "black",
                        }}
                      >
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="left">
                          <b>{translate("resources.root.barcode")}</b>
                        </StyledTableCell>
                        <StyledTableCell align="left" colSpan="3">
                          <b>{translate("resources.root.name")}</b>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <b>{translate("resources.root.quantity")}</b>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <b>{translate("resources.root.price")}</b>
                        </StyledTableCell>
                        {props.smallPrice &&
                          localStorage.getItem("showSmallMeasure") ===
                            "true" && (
                            <StyledTableCell align="right">
                              <b>{translate("resources.root.smallMeasure")}</b>
                            </StyledTableCell>
                          )}
                        {localStorage.getItem("showSmallMeasure") ===
                          "true" && (
                          <StyledTableCell align="right">
                            <b>{translate("resources.root.gift")}</b>
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="right">
                          <b>{translate("resources.root.totalPrice")}</b>
                        </StyledTableCell>
                      </TableRow>
                      <TableBody>
                        {invoice &&
                          props.orders &&
                          props.orders.map((order, key) => (
                            <StyledTableRow key={key}>
                              <StyledTableCell align="left">
                                {key + 1}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {order.product.barcode}
                              </StyledTableCell>
                              <StyledTableCell align="left" colSpan="3">
                                {order.product.preferName || order.product.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {order.qte}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {invoice.purchaseOrders &&
                                invoice.purchaseOrders.length > 0
                                  ? invoice.payByMain
                                    ? "$" +
                                      order.mcPurchasePrice?.toLocaleString()
                                    : order.scPurchasePrice?.toLocaleString() +
                                      " IQD"
                                  : ""}
                                {invoice.sellOrders &&
                                invoice.sellOrders.length > 0
                                  ? invoice.payByMain
                                    ? "$" + order.mcSellPrice?.toLocaleString()
                                    : order.scSellPrice?.toLocaleString() +
                                      " IQD"
                                  : ""}
                              </StyledTableCell>
                              {props.smallPrice &&
                                localStorage.getItem("showSmallMeasure") ===
                                  "true" && (
                                  <StyledTableCell align="right">
                                    {invoice.payByMain
                                      ? "$" +
                                        order.mcSmallMeasureSellPrice?.toLocaleString()
                                      : order.scSmallMeasureSellPrice?.toLocaleString() +
                                        " IQD"}
                                  </StyledTableCell>
                                )}
                              {localStorage.getItem("showSmallMeasure") ===
                                "true" && (
                                <StyledTableCell align="right">
                                  {order.giftQte}
                                </StyledTableCell>
                              )}
                              <StyledTableCell align="right">
                                {invoice.payByMain
                                  ? "$" + order.mcLastPrice?.toLocaleString()
                                  : order.scLastPrice?.toLocaleString() +
                                    " IQD"}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.rPaper}>
                  <div>
                    {translate("resources.root.totalPrice")}:{" "}
                    <b>
                      {invoice.payByMain
                        ? invoice.mcTotalPrice &&
                          "$" + invoice.mcTotalPrice?.toLocaleString()
                        : invoice.scTotalPrice &&
                          invoice.scTotalPrice?.toLocaleString() + " IQD"}
                    </b>
                  </div>
                  {invoice.mcInvoiceDiscount &&
                  invoice.mcInvoiceDiscount > 0 &&
                  localStorage.getItem("showDiscountInvoice") === "true" ? (
                    <div>
                      {translate("resources.root.discount")}:{" "}
                      <b>
                        {invoice.payByMain
                          ? invoice.mcFinalDiscount &&
                            invoice.mcFinalDiscount?.toLocaleString()
                          : invoice.scFinalDiscount &&
                            invoice.scFinalDiscount?.toLocaleString()}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    {translate("resources.root.previousPrice")}:{" "}
                    <b>
                      {" "}
                      {invoice.payByMain
                        ? invoice.mcLoanBefore &&
                          invoice.mcLoanBefore?.toLocaleString()
                        : invoice.scLoanBefore &&
                          invoice.scLoanBefore?.toLocaleString()}
                    </b>
                  </div>
                  {invoice.mcInvoiceDiscount &&
                  invoice.mcInvoiceDiscount > 0 &&
                  localStorage.getItem("showDiscountInvoice") === "true" ? (
                    <div>
                      {translate("resources.root.purePrice")}:{" "}
                      <b>
                        {" "}
                        {invoice.payByMain
                          ? invoice.mcFinalPrice &&
                            invoice.mcFinalPrice?.toLocaleString()
                          : invoice.scFinalPrice &&
                            invoice.scFinalPrice?.toLocaleString()}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.centerPaper}>
                  <div>
                    {translate("resources.root.totalNumber")}:{" "}
                    <b>{invoice && invoice.totalQte}</b>
                  </div>
                  <div>
                    {translate("resources.root.currentLoan")}:{" "}
                    <b>
                      {" "}
                      {props.human &&
                        (invoice.payByMain
                          ? props.human.mcCurrentLoan?.toLocaleString()
                          : props.human.scCurrentLoan?.toLocaleString())}
                    </b>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <div>
                    {translate("resources.root.note")}:{" "}
                    <b>{invoice && invoice.note}</b>
                  </div>
                </Paper>
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Grid item xs={6}>
                <Paper elevation={0} className={classes.centerPaper}>
                  {translate("resources.root.accountant")}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0} className={classes.centerPaper}>
                  {props.human && props.human.firstName
                    ? translate("resources.root.customer")
                    : translate("resources.root.supplier")}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: "rgba(0, 0, 0, 1) !important",
    opacity: 1,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: theme.direction === "rtl" ? "right" : "left",
  },
  centerPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  header: {
    width: "100%",
    backgroundColor: "red",
    color: theme.palette.common.white,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    borderRadius: 4,
    marginBottom: 7,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderColor: "gray",
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
  },
  flexCol: {
    flex: 1,
    textAlign: theme.direction === "rtl" && "right",
  },
  table: {
    width: "100%",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: "18px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
