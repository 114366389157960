import React from "react";

import { HTTP } from "../../axios";
import { url, path, handleRequestResponse } from "../../request";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Datagrid,
  FunctionField,
  List,
  useNotify,
  useRefresh,
  useTranslate,
  TextField,
} from "react-admin";
import MaterialTextField from "@mui/material/TextField";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

const AttachmentTab = (props) => {
  const [titleAttachment, setTitleAttachment] = React.useState(null);
  const [attachmentNote, setAttachmentNote] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const AddNewAttachment = () => {
    HTTP.post(`${url}/${props.endPoint}`, {
      title: titleAttachment,
      note: attachmentNote,
      attachment: attachment,
      referenceId: props?.id,
    })
      .then((res) => {
        notify("Successfully Added", { type: "success" });
        refresh();
        setTitleAttachment(null);
        setAttachmentNote(null);
        setAttachment(null);
      })
      .catch((err) => {
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  };

  let custoemrAttachments = {
    basePath: `/${props?.endPoint}`,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    hasDelete: true,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: `${props.endPoint}`,
    exporter: true,
  };

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {translate("resources.root.addNewAttachment")}
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid-attachment-customer-show">
            <div className="">
              <MaterialTextField
                id="text-key"
                label={translate("resources.root.title")}
                fullWidth
                variant="filled"
                value={titleAttachment}
                onChange={(e) => setTitleAttachment(e.target.value)}
              />
            </div>
            <div className="">
              <MaterialTextField
                id="text-value"
                label={translate("resources.root.note")}
                fullWidth
                variant="filled"
                value={attachmentNote}
                onChange={(e) => setAttachmentNote(e.target.value)}
              />
            </div>
            <div className="">
              <Button
                className="imageButton btn-block d-flex justify-content-center align-items-center gap-2"
                color="primary"
                variant="contained"
                component="span"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-upload mx-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                </svg>
                {translate("resources.root.uploadImg")}
                <input
                  className=""
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    const base64 = await getBase64(file);

                    setAttachment(base64);
                  }}
                />
              </Button>
            </div>{" "}
            <div className="">
              <Button variant="contained" fullWidth onClick={AddNewAttachment}>
                {translate("resources.root.add")}
              </Button>
            </div>
          </div>
          {attachment && (
            <div className="my-4 w-50 mx-auto">
              <img
                className="w-100"
                src={attachment}
                style={{
                  objectFit: "scale-down",
                  cursor: "pointer",
                  borderRadius: "1rem",
                }}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <List
        {...custoemrAttachments}
        filter={{ subLevelId: props?.id }}
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="id" label="resources.root.id" />
          <TextField source="title" label="resources.root.title" />
          <TextField source="note" label="resources.root.note" />
          {/* <TextField source="value" label="resources.root.value" /> */}
          <FunctionField
            label={`resources.root.image`}
            render={(record) => (
              <img
                style={{
                  width: 75,
                  height: 75,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`
                }
                alt={record.name}
                onClick={() =>
                  window.open(
                    record.attachment !== null
                      ? `${path}${record.attachment}`
                      : `https://via.placeholder.com/75`,
                    "_blank"
                  )
                }
              />
            )}
          />
        </Datagrid>
      </List>
    </div>
  );
};
export default AttachmentTab;
