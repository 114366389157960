import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";

export default (CustomerPaymentReport) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [customer, setCustomer] = React.useState("");

  const [prices, setPrices] = React.useState({
    mcTotal: 0,
    scTotal: 0,
    mcForgiveTotal: 0,
    scForgiveTotal: 0,
  });

  const [currency, setCurrency] = React.useState("default");

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    let customerO = ``;

    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    } else {
      sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
    }

    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    } else {
      sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
    }

    await HTTP.get(
      `${url}/CustomerPayments?_start=${start}&_end=${end}${sendDates}${customer}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
        setPrices({
          mcTotal: response.headers["mctotalprice"],
          scTotal: response.headers["sctotalprice"],
          mcForgiveTotal: response.headers["mctotalforgiveprice"],
          scForgiveTotal: response.headers["sctotalforgiveprice"],
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );
  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Customers?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      newArray.push({ value: 0, label: translate("resources.root.none") });
      response.data.map((customer) => {
        newArray.push({
          value: customer.id,
          label: `${customer.firstName} ${
            customer.middleName
          } (${customer.phone.replace("+964", "0")})`,
          customer: customer,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`productStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.customerPaymentReport")}
            </h1>
          </div>

          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row
              style={{ fontSize: "15px" }}
              className="justify-content-center align-items-center"
            >
              <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.customer")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1"
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setCustomer(e.value > 0 ? `&subLevelId=${e?.value}` : "");
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-8 mt-4">
                <Row className="justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="col-4 col-lg-6 mt-4 d-flex justify-content-between align-items-center gap-3">
                <Button
                  className={`btn-block m-0`}
                  onClick={() => setCurrency("default")}
                >
                  {translate("resources.root.default")}
                </Button>
                <Button
                  className={`btn-block m-0`}
                  onClick={() => setCurrency("usd")}
                >
                  {translate("resources.root.usd")}
                </Button>
                <Button
                  className={`btn-block m-0`}
                  onClick={() => setCurrency("iqd")}
                >
                  {translate("resources.root.iqd")}
                </Button>
              </Col>
              <Col className="col-12 col-lg-6 mt-4 row row-cols-2 justify-content-start">
                <h5>
                  {translate("resources.root.totalPrice")}: $
                  {parseFloat(prices.mcTotal)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalPrice")}:{" "}
                  {parseFloat(prices.scTotal)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
                <h5>
                  {translate("resources.root.forgiveTotalPrice")}: $
                  {parseFloat(prices.mcForgiveTotal)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.forgiveTotalPrice")}:{" "}
                  {parseFloat(prices.scForgiveTotal)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
              </Col>

              <Col className="col-4  mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4  mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4  mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.customerName")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.forgivePrice")}</th>
                    <th>{translate("resources.root.reciever")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.accountant")}</th>
                    <th>{translate("resources.root.note")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>{one.id ?? "-"}</td>
                          <td>
                            <a
                              href={`#/CustomerPayments/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.invoiceNumber ?? "-"}
                            </a>
                          </td>
                          <td>{`${one.customer.firstName} ${one.customer.middleName} ${one.customer.lastName}`}</td>
                          <td>{one.invoiceNumber ?? "-"}</td>
                          <td>
                            {currency !== "default"
                              ? currency === "usd"
                                ? one.mcPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? ""
                                : one.scPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? ""
                              : one.byMain
                              ? one.mcPrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.usd") ?? ""
                              : one.scPrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.iqd") ?? ""}
                          </td>
                          <td>
                            {currency !== "default"
                              ? currency === "usd"
                                ? one.mcForgivePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? ""
                                : one.scForgivePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? ""
                              : one.byMain
                              ? one.mcForgivePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.usd") ?? ""
                              : one.scForgivePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.iqd") ?? ""}
                          </td>
                          <td>{one.reciever ?? "-"}</td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD") ?? "-"}
                          </td>
                          <td>{one.accountant ?? "-"}</td>
                          <td>{one.note ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
