import * as React from "react";
import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceField,
  FormDataConsumer,
  Filter,
  FunctionField,
  NumberInput,
  DateInput,
  required,
  BooleanInput,
  AutocompleteInput,
  NumberField,
  useTranslate,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeePermissionsList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          // setCurrency={setCurrency}
          // currency={currency}
          hasCreate="hr.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="hr.list" />}
      >
        <ReferenceField
          label="resources.root.employee"
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label="resources.root.name"
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        <NumberField source="days" label="resources.root.days" />
        <NumberField source="hours" label="resources.root.hours" />
        <FunctionField
          source="date"
          label="resources.root.date"
          render={(record) => moment(record.date).format("DD/MM/YYYY")}
        />
        <FunctionField
          label="resources.root.returnDate"
          render={(record) =>
            moment(record.date).add(record.days, "days").format("DD/MM/YYYY")
          }
        />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
      </Datagrid>
    </List>
  );
};

export const EmployeePermissionsCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState({});
  const [permissions, setPermissions] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [cash, setCash] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (employeeId === 0) return;
    HTTP.get(`${url}/Employees/${employeeId}`).then((res) => {
      setSelectedEmployee(res.data);
      setPermissions(res.data.employeeAllows);
      setLoading(false);
    });
  }, [employeeId]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  const translate = useTranslate();

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        allowedBy: localStorage.getItem("name"),
        allowed: true,
        requestedAt: moment().format("YYYY-MM-DD"),
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setEmployeeId(val);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              label="resources.root.chooseEmployee"
            />
            {/* <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedEmployee &&
                  (selectedEmployee.salaryByMain ? (
                    <BooleanInput
                      source="byMain"
                      defaultValue={true}
                      label="resources.root.dollar"
                    />
                  ) : (
                    <BooleanInput
                      source="byMain"
                      defaultValue={false}
                      label="resources.root.dinar"
                    />
                  ))
                );
              }}
            </FormDataConsumer> */}
            <NumberInput
              source="days"
              label="resources.root.days"
              defaultValue={0}
            />
            <NumberInput
              source="hours"
              label="resources.root.hours"
              defaultValue={0}
            />
            <DateInput
              source="date"
              label="resources.root.date"
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            <TextInput
              source="reason"
              label="resources.root.reason"
              multiline
            />
            <TextInput source="note" label="resources.root.note" multiline />
          </div>
          <div className={classes.formCol}>
            {
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.permissions")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {`${permissions
                      .map((el) => el.days)
                      .reduce((a, b) => a + b, 0)
                      ?.toLocaleString()} ${translate(
                      "resources.root.days"
                    )} + ` +
                      `${permissions
                        .map((el) => el.hours)
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()} ${translate(
                        "resources.root.hours"
                      )}`}
                  </Typography>

                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.subtractedSalary")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {selectedEmployee.salaryByMain
                      ? `$${permissions
                          .map((el) => el.mcSubtractedSalary)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`
                      : `${permissions
                          .map((el) => el.scSubtractedSalary)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  {!cash && (
                    <Typography
                      className={classes.title}
                      style={{
                        color: selectedEmployee.salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.futureSubtractions")}
                    </Typography>
                  )}
                  {!cash && (
                    <Typography
                      className={classes.marginBottom}
                      style={{
                        color: selectedEmployee.salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      variant="h5"
                      component="h2"
                    >
                      {selectedEmployee.salaryByMain
                        ? `$${(
                            permissions
                              .map((el) => el.mcSubtractedSalary)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()}`
                        : `${(
                            permissions
                              .map((el) => el.scSubtractedSalary)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedEmployee.id &&
                      translate("resources.root.employee")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedEmployee.phone}
                    <br />
                    {selectedEmployee.email}
                    <br />
                    {translate("resources.root.address")} :{" "}
                    {selectedEmployee.address}
                    <br />
                    {translate("resources.root.salaryType")} :{" "}
                    {selectedEmployee.salaryType}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            }
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
