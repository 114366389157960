import { Button } from "./LoadingStyles";
import "./Styles.css";
import { FaSpinner } from "react-icons/fa";

const LoadingScreen = () => {
  return (
    <Button>
      <FaSpinner icon="spinner" className="spinner" fontSize={100} />
    </Button>
  );
};

export default LoadingScreen;
